import React, { createContext, useContext, useMemo, useState } from 'react';
import { AccommodationType, ObjectTypeRef, Period } from '@shared/models/article';
import { Moment } from 'moment';
import { Periodable } from '@ct-react/core';
import NbrGuests from '../models/guests';

export interface SearchCriteria {
    articleType: AccommodationType;
    objectTypes: [ObjectTypeRef] | [];
    priceRange: number[] | null;
    priceLimits: number[];
    roomsRange: number[] | null;
    guests: NbrGuests;
    period: Periodable<Moment>;
    surface: number;
    features: string[];
};

// Interface pour les setters
interface SearchContextProps {
  searchCriteria: SearchCriteria;
  setArticleType: (type: AccommodationType) => void;
  setObjectTypes: (types : [ObjectTypeRef] | []) => void;
  setPriceRange: (range: number[] | null) => void;
  setRoomsRange: (range: number[] | null) => void;
  setGuests: (guests: NbrGuests) => void;
  setPeriod: (period: Periodable<Moment>) => void;
  setSurface: (surface: number) => void;
  setFeatures: (features: string[]) => void;
}

const SearchContext = createContext<SearchContextProps | undefined>(undefined);

export const useSearchContext = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearchContext must be used within a SearchProvider');
  }
  return context;
};

export const SearchProvider = ({ children }) => {
    const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>({
        articleType: AccommodationType.RENTAL,
        objectTypes: [],
        priceRange: null,
        priceLimits: [0, 10000000],
        roomsRange: null,
        period: {
            start: undefined,
            end: undefined
        },
        surface: 0,
        guests: new NbrGuests(),
        features: []
    });

    const setArticleType = (articleType: AccommodationType) => {
        let limits = [0,0];
        if(articleType === AccommodationType.SALE) limits = [0, 3000000];
        if(articleType === AccommodationType.ANNUAL) limits = [0, 20000];
        if(articleType === AccommodationType.SEASONAL) limits = [0, 20000];
        setSearchCriteria((prev) => ({...prev,
            articleType: articleType,
            objectTypes: [],
            priceRange: null,
            priceLimits: limits,
            roomsRange: null,
            period: {
                start: undefined,
                end: undefined
            },
            surface: 0,
            guests: new NbrGuests(),
        }));
    };

    const setObjectTypes = (types: [ObjectTypeRef] | []) => {
        setSearchCriteria((prev) => ({ ...prev, objectTypes: types }));
    };

    const setPriceRange = (range: number[] | null) => {
        setSearchCriteria((prev) => ({ ...prev, priceRange: range }));
    };

    const setRoomsRange = (range: number[] | null) => {
        setSearchCriteria((prev) => ({ ...prev, roomsRange: range }));
    };

    const setGuests = (guests: NbrGuests) => {
        setSearchCriteria((prev) => ({ ...prev, guests: guests}));
    };

    const setPeriod = (period: Periodable<Moment>) => {
        setSearchCriteria((prev) => ({ ...prev, period: period }));
    };
    
    const setSurface = (surface: number) => {
      setSearchCriteria((prev) => ({ ...prev, surface: surface }));
    };

    const setFeatures = (features: string[]) => {    
      setSearchCriteria((prev) => ({ ...prev, features: features }));
    };

    const value = useMemo(()=>{
        return {
            searchCriteria,
            setArticleType,
            setObjectTypes,
            setSurface,
            setPriceRange,
            setRoomsRange,
            setPeriod,
            setGuests,
            setFeatures,
        }
    },[searchCriteria]);
  
    return (
      <SearchContext.Provider
        value={value}
      >
        {children}
      </SearchContext.Provider>
    );
};
  