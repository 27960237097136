import { AnyPrice, Price } from "@ct-react/core";

//TYPE

export enum ArticleType {
    RENTAL = "RentalAccommodationArticle",
    SEASONAL = "SeasonalAccommodationArticle",
    ANNUAL = "AnnualAccommodationArticle",
    SALE = "SaleAccommodationArticle"
};

export enum AccommodationType {
    RENTAL = "RENTAL",
    SEASONAL ="SEASONAL",
    ANNUAL = "ANNUAL",
    SALE = "SALE"
};

//STRING

export type TranslatedString = {
    value: string
    matchLang: boolean
};

// IMAGE

export type Image = {
    title?: string
    alternativeText: string | undefined
    main: boolean
    assets: [ImageAsset]
    aspectRatio: string
};

export type ImageAsset = {
    url: string //URL
    width: number
    height: number
    breakpoint: ImageBreakpoint
};

export enum ImageBreakpoint {
    SM = "SM",
    MD = "MD",
    LG = "LG"
};

// FEATURES

export enum Orientation {
    NONE = "NONE",
    PANORAMIC = "PANORAMIC",
    NORTH = "NORTH",
    SOUTH = "SOUTH",
    EAST = "EAST",
    WEST = "WEST",
    NORTH_EAST = "NORTH_EAST",
    NORTH_WEST = "NORTH_WEST",
    SOUTH_EAST = "SOUTH_EAST",
    SOUTH_WEST = "SOUTH_WEST"
};

//PRICE

export type TouristTaxCartOption = {
    grid: [TouristTaxCartOptionGridItem]
    optionId: string
};

export type TouristTaxCartOptionGridItem = {
    ageCategory: AgeCategory
    ageRange: [number]
    price: AnyPrice
};

enum AgeCategory {
    ADULT = "ADULT",
    CHILD = "CHILD",
    EXEMPT = "EXEMPT",
    BABY = "BABY",
    SENIOR = "SENIOR"
};

export type DiscountDefinition = {
    logic: DiscountLogic
    period: Period<Date>
    type: DiscountType
    fixedValue: Price
    percentageValue: number
};

enum DiscountLogic {
    FIXED = "FIXED",
    PERIODED = "PERIODED"
};

enum DiscountType {
    FIXED = "FIXED",
    PERIODED = "PERIODED"
};

//ADDRESS

export type Address = {
    resume: string
    street: string
    streetNumber: string
    streetComplement: string
    zip: string
    city: string
    state: string
    country: string
};

//

export type ObjectTypeRef = {
    id: Scalars["ID"]
    slug: string
    name: string
};

//MARKER

export type AccommodationMarker = {
    coordinates:  [number]
    id: string
    slug: string
    publishedAt: Scalars["DateTime"]
};

//SEO

export type SEOData = {
    title: TranslatedString
    description: TranslatedString
    image: string //URL
    keywords: [string]
    translatableKeywords: [KeyValuePairOfTranslatableSEOKeywordAndString]
};

export type KeyValuePairOfTranslatableSEOKeywordAndString = {
    key: TranslatableSEOKeyword
    value: string
};

enum TranslatableSEOKeyword {
    ARTICLE_TYPE = "ARTICLE_TYPE",
    OBJECT_TYPE = "OBJECT_TYPE"
};

// DATE

export type Period<TDate> = {
    start: TDate | undefined;
    end: TDate | undefined;
};

//SCALARS

export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** No value return representation */
    Void: void;
    /** Representation of a non-typed data structure as JSON */
    JSON: any;
    /** Representation of an amount with is associated currency `{ value: number, currency: string }`, e.g. `{ value: 199.95,  currency: "CHF" }` */
    Amount: { value: number, currency: string };
    /** Representation of date without time in an ISO 8601 string `YYYY-MM-DD`, e.g. `2024-01-01` */
    Date: string;
    /** Representation of date time at UTC in an ISO 8601 string `YYYY-MM-DDTHH:mm:ssZ`, e.g. `2024-01-01T12:00:00Z` */
    DateTime: string;
    /** Representation of a date range without time range [ `Date` , `Date` ], e.g. `[ "2024-01-01", "2024-02-29" ]` */
    DateRange: [ string, string ];
    /** Representation of a date time range [ `DateTime` , `DateTime` ], e.g. `[ "2024-01-01T12:00:00Z", "2024-02-29T12:00:00Z" ]` */
    DateTimeRange: [ string, string ];
    DatePeriod: [ string, string ];
};
