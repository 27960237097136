import { gql } from "@apollo/client";
import { ObjectTypeRef } from "@shared/models/article";
import { SearchCriteria } from "app/contexts/search-module";

export type ResultObjectRef = {
    searchableAccommodationObjectTypes: [ObjectTypeRef]
};

export const OBJECT_QUERY = gql`
    query objectTypes($accommodationType: AccommodationType){
        searchableAccommodationObjectTypes(accommodationType: $accommodationType){
            name
            id
        }
    }
`;

export const generateSearchArticleParamData = (data: SearchCriteria) => {
    let params:any[]=[];

    let guests = data.guests.adult + data.guests.child + data.guests.exempt;
    guests != 0 && params.push(["minGuests", guests]);

    !!data.priceRange && params.push(["price", data.priceRange.join("-")]);

    !!data.roomsRange&& params.push(["roomsRange", data.roomsRange.join("-")]);

    !!data.surface  && params.push(["minSurface",data.surface]);

    data.period.start != undefined && params.push(["start", data.period.start.format("YYYY-MM-DD")]);
    data.period.end != undefined && params.push(["end", data.period.end.format("YYYY-MM-DD")]);

    data.objectTypes.length > 0 && params.push(["objectTypes", data.objectTypes.map((obj: ObjectTypeRef) => obj.id).join("-")]);

    data.features.map((e: any) => params.push([e, "true"]));

    return params.map((a: any)=>a.join("=")).join("&");
};
    