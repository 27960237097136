import loadable from "@loadable/component";

export const ProtectedRoute = loadable(
  () => import(/* webpackChunkName: "client-only-ui" */ "./protected-route"),
  { ssr: false }
);

loadable.lib(
  () => import(/* webpackChunkName: "client-only-ui" */"@use-cookie-consent/react"),
  { ssr: false });

export const RGPDConsentProvider = loadable(
  () => import(/* webpackChunkName: "client-only-ui" */"@use-cookie-consent/react"),
  { ssr: false, resolveComponent: module => module.CookieConsentProvider });

export const RGPDConsentProcessor = loadable(
  () => import(/* webpackChunkName: "client-only-ui" */"./cookie-consent-provider"),
  { ssr: false });

export const RGPDBanner = loadable(
  () => import(/* webpackChunkName: "client-only-ui" */"./cookie-banner"),
  { ssr: false });
