import React, { ReactNode, useEffect } from "react";
import { useIntl } from "react-intl";

import "./pop-up.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { classNames } from "@ct-react/core";

type PopUpProps = {
    succeeded?: boolean | null,
    children: ReactNode
    setState:() => void
}

const PopUp = ({
    succeeded = null,
    children,
    setState
}: PopUpProps) => {
    const intl = useIntl();

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "initial";
        }
    },[]);

    const closeWindow = () => {
        document.body.style.overflow = "initial";
        setState();
    };
    
    return(
        <div className="veil">
            <div className="pop-up">
                <FontAwesomeIcon icon={faXmark} onClick={closeWindow}/>
                {succeeded != null &&
                <div className="result-icone">
                    <FontAwesomeIcon className={classNames({succeeded: succeeded})} icon={succeeded? faCheck : faXmark}/>
                </div>
                }
                {children}
            </div>
        </div>
    );
};

export default PopUp;