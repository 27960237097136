import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { buttonsTranslations } from "../../i18n/sharable-defs";
import { LocaleLink } from "@ct-react/locale";

import { not_found } from "./translations";
import "./notFound.scss";
import Image from "../../../assets/img/crans-montana.jpg";

const NotFound:FunctionComponent = ()=>{
    const intl = useIntl();
    const img = `url(${Image})`;

    return (
        <section className="notFound" style={{backgroundImage:img}}>
            <div className="blackVeil"></div>
            <span className="error">
                404
                <span className="errorText">{intl.formatMessage(not_found.text_1)}</span>
            </span>
            <span className="errorText2">
                {intl.formatMessage(not_found.text_2)}
            </span>
            <LocaleLink to={"/"} className="btn-red">
                {intl.formatMessage(buttonsTranslations.back_home)}
            </LocaleLink>
        </section>
    );
}

export default NotFound;
