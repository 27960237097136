import {defineMessages} from "react-intl";

export const cart_translations = defineMessages({
    summary: {id: "summary", defaultMessage: "Récapitulatif"},
    total_amount: {id:"total-amount", defaultMessage : "Montant total"},
    to_pay_now: {id:"to-pay-now", defaultMessage : "À payer maintenant"},
    confirm_deletion_item: { id: "confirm-deletion-item", defaultMessage: "Êtes-vous certain de vouloir retirer ce bien de votre panier ?" },
    identify_myself: {id:"identify-myself",defaultMessage : "S'identifier"},
    already_subscribe: {id:"already-subscribe",defaultMessage : "Je suis déja inscrit"},
    first_time_booking: {id:"first-time-booking",defaultMessage : "C'est la première fois que je réserve sur Montan’Agence :"},
    continue_without_signin: {id:"continue-without-signin", defaultMessage : "Continuer sans compte"},
    connexion_visitor: {id:"connexion-visitor", defaultMessage : "Je souhaite faire une réservation en tant que visiteur et renonce aux avantages réservés aux titulaires d'un compte en ligne."},
    change_reservation: {id:"change-your-reservation", defaultMessage : "Modifier votre réservation"},
    define_model_payment: {id:"define-model-payment",defaultMessage : "Définir le moyen de paiement"},
    change_informations: {id:"change-informations", defaultMessage : "Modifier vos informations"},
    payment_amount_title: { id: "payment-detail-title", defaultMessage: "Paiement immédiat" },
    pay_now: {id:"pay-now",defaultMessage : "Payer et finaliser la réservation"},
    payment_callback_succeeded_1: {id:"payment-callback-succeeded-1", defaultMessage : "Transaction effectué avec succès !"},
    payment_callback_succeeded_2: {id:"payment-callback-succeeded-2", defaultMessage : "Merci pour votre achat"},
    payment_callback_failed_1: {id:"payment-callback-failed-1",defaultMessage : "Échec de la transaction"},
    payment_callback_failed_2: {id:"payment-callback-failed-2",defaultMessage : "Un problème est survenue"},
    payment_model_payment: {id:"payment-model-payment", defaultMessage : "Modèle de paiement"},
    payment_pay_total: {id:"payment-pay-total", defaultMessage : "Payer le montant total"},
    payment_pay_now: {id:"payment-pay-now",defaultMessage : "Payer le total maintenant et vous aurez terminé."},
    payment_pay_account_now: {id:"payment-pay-account-now", defaultMessage : "Payer une partie maintenant, puis l'autre partie plus tard"},
    payment_pay_account_now_2: {id:"payment-pay-account-now-2", defaultMessage : "Payer {account} maintenant et le solde ({downPayment}) vous sera prélevé 1 mois avant votre check-in. Aucun frais supplémentaires."},
});
