import React, { useContext, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMapPin, faPhone } from "@fortawesome/free-solid-svg-icons";
import { LocaleLink, LocaleNavLink, useLocaleContext } from "@ct-react/locale";
import { CmsContext } from "../../contexts/cms";
import { MenuContext } from "../../contexts/menu";
import { genericTermTranslations } from "../../i18n/sharable-defs";
import { footerTranslations } from "./translations";
import { FacebookIcone, InstagramIcone, LinkedinIcone, WhattsappIcone } from "../../components/icones/icones";
import { RGPDBanner } from "../../bundles/client-only-ui";
import Logo from "../../../assets/svg/logo-blanc-footer.svg";
import Uspi from "../../../assets/svg/uspi.svg";
import Intranet from "../../../assets/svg/icone-intranet.svg";
import Civ from "../../../assets/img/civ.png";
import "./footer.scss";

const Footer = () => {

    const intl = useIntl();
    const { locale } = useLocaleContext();
    const { navigation: cmsNavigation } = useContext(CmsContext);
    const { setIsDown } = useContext(MenuContext);

    const year = useRef<number>(new Date().getFullYear());
    const [ cmsRentOut, setCmsRentOut ] = useState<{ path: string } | undefined>(undefined);
    const [ cmsSellOut, setCmsSellOut ] = useState<{ path: string } | undefined>(undefined);

    useEffect(() => {
      const searchRentOut = cmsNavigation.services?.find(np => np.id === "55add8e6-4242-4c69-8874-c52c22db1bed");
      const searchSellOut = cmsNavigation.services?.find(np => np.id === "e2d81526-cdb2-46b1-b18b-f8e7812f2230");
      !!searchRentOut && setCmsRentOut(searchRentOut);
      !!searchSellOut && setCmsSellOut(searchSellOut);
    }, [ cmsNavigation ]);

    return(
        <footer className="main-footer">
            <address>
                <Logo/>
                <a href="tel:+41 27 481 43 43">
                    <FontAwesomeIcon className="footer-icone" width={16} height={16} icon={faPhone}/>
                    +41 27 481 43 43
                </a>
                <a href="mail:location@montanagence.ch">
                    <FontAwesomeIcon className="footer-icone" width={16} height={16} icon={faEnvelope}/>
                    location@montanagence.ch
                </a>
                <a href="https://maps.app.goo.gl/FWpuuSnaGZ6ZF94Z9" target="_blank">
                    <FontAwesomeIcon className="footer-icone" width={16} height={16} icon={faMapPin}/>
                    Avenue de la gare 19<br/>
                    Crans-Montana 3963
                </a>
            </address>

            <div className="links">
                <ul>
                    <h4>{intl.formatMessage(genericTermTranslations.buySale)}</h4>
                    <li>
                        <LocaleNavLink to={"/vente"} onClick={()=>setIsDown(false)}>
                            {intl.formatMessage(genericTermTranslations.goodToSale)}
                        </LocaleNavLink>
                    </li>
                    {!!cmsSellOut &&
                      <li>
                        <LocaleNavLink to={cmsSellOut.path} onClick={() => setIsDown(false)}>
                          {intl.formatMessage(genericTermTranslations.saleItsGood)}
                        </LocaleNavLink>
                      </li>
                    }
                    <li>
                        <LocaleNavLink to={"situation-fiscale-en-valais"} onClick={()=>setIsDown(false)}>
                            {intl.formatMessage(genericTermTranslations.fiscalSituation)}
                        </LocaleNavLink>
                    </li>
                </ul>
                <ul>
                    <h4>{intl.formatMessage(genericTermTranslations.locationHolidays)}</h4>
                    <li>
                        <LocaleNavLink to={"/location/vacances"} onClick={()=>setIsDown(false)}>
                            {intl.formatMessage(genericTermTranslations.goodToRent)}
                        </LocaleNavLink>
                    </li>
                    {!!cmsRentOut &&
                      <li>
                        <LocaleNavLink to={cmsRentOut.path} onClick={() => setIsDown(false)}>
                          {intl.formatMessage(genericTermTranslations.entrustItsGood)}
                        </LocaleNavLink>
                      </li>
                    }
                </ul>
            </div>

            <div className="links">
                <ul>
                    <h4>{intl.formatMessage(genericTermTranslations.longLocation)}</h4>
                    <li>
                        <LocaleNavLink to={"/location/annee"} onClick={()=>setIsDown(false)}>
                            {intl.formatMessage(genericTermTranslations.locationYearly)}
                        </LocaleNavLink>
                    </li>
                    <li>
                        <LocaleNavLink to={"/location/saison"} onClick={()=>setIsDown(false)}>
                            {intl.formatMessage(genericTermTranslations.locationSeasonal)}
                        </LocaleNavLink>
                    </li>
                    {!!cmsRentOut &&
                      <li>
                        <LocaleNavLink to={cmsRentOut.path} onClick={() => setIsDown(false)}>
                          {intl.formatMessage(genericTermTranslations.entrustItsGood)}
                        </LocaleNavLink>
                      </li>
                    }
                </ul>
                <ul>
                    <h4>{intl.formatMessage(genericTermTranslations.agence)}</h4>
                    {!!cmsNavigation.agency && cmsNavigation.agency.map((item, i) => (
                      <li key={i}>
                        <LocaleNavLink to={item.path} onClick={() => setIsDown(false)}>
                          {item.label}
                        </LocaleNavLink>
                      </li>
                    ))}
                    <li>
                        <LocaleNavLink to={"contact"} onClick={()=>setIsDown(false)}>
                            {intl.formatMessage(genericTermTranslations.contact)}
                        </LocaleNavLink>
                    </li>
                </ul>
            </div>

            <div className="links">
                <ul>
                    <h4>{intl.formatMessage(genericTermTranslations.services)}</h4>
                    {!!cmsNavigation.services && cmsNavigation.services.map((item, i) => (
                      <li key={i}>
                        <LocaleNavLink to={item.path} onClick={() => setIsDown(false)}>
                          {item.label}
                        </LocaleNavLink>
                      </li>
                    ))}
                </ul>
                <div className="social-network">
                    <FacebookIcone/>
                    <LinkedinIcone/>
                    <InstagramIcone/>
                    <WhattsappIcone/>
                </div>
            </div>

            <div>
                <a target="_blank" href="https://extranet.myciti.ch">
                    <Intranet/>
                    {intl.formatMessage(footerTranslations.intranet)}
                </a>
                <div>
                    <img src={Civ} alt="Logo civ"/>
                    <Uspi/>
                </div>
            </div>

            <div>
              <span className="terms">
                {intl.formatMessage(footerTranslations.legal, {
                  terms: <a href={`/assets/conditions/terms-${locale.basename}.pdf`} target="_blank">{intl.formatMessage(footerTranslations.terms)}</a>,
                  mentions: <LocaleLink to="mentions-legales">{intl.formatMessage(footerTranslations.mentions)}</LocaleLink>,
                  policy: <LocaleLink to="politique-de-confidentialite">{intl.formatMessage(footerTranslations.policy)}</LocaleLink>
                })}
              </span>
              <RGPDBanner />
              <span className="copyright">
                {intl.formatMessage(footerTranslations.copyright, {
                  year: year.current,
                  ct: <a href="https://www.creative-technologies.ch" target="_blank">C.T. Creative Technologies SA</a>,
                  ux: <a href="https://www.gladys-ancay.com" target="_blank">Gladys Ançay</a>
                })}
              </span>
            </div>
        </footer>
    );
};

export default Footer;
