import React, { useRef, useState } from "react";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { CardArticle, CardArticleSkeleton } from "../cards/card-articles";
import { AccommodationType } from "@shared/models/article";
import { SearchAnyArticleEdge, SearchAnyArticleResult } from "../../models/search-param";
import { useQuery } from "@apollo/client";
import { GET_ARTICLES } from "../../pages/search-articles/search-articles-config";
import { determineArticleCarouselsConfig } from "./article-carousels.config";
import { Image } from "@shared/models/article";
import ResponsiveImage from "../../utils/images";

import "./carousels.scss";
import { classNames } from "@ct-react/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

type ArticlesCarouselProps = {
    type: AccommodationType
};

type ArticleCarouselFullProps = {
    isActive: boolean,
    setIsActive: React.Dispatch<React.SetStateAction<boolean>>,
    images: Image[],
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void
};

export const ArticlesCarousel = ({
    type,
}:ArticlesCarouselProps) => {
    const request = determineArticleCarouselsConfig(type);

    const { data, error, loading, refetch } = useQuery<SearchAnyArticleResult>(GET_ARTICLES, {
        variables:{
            first:8,
            type: request,
        },
        ssr: true,
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
    });

    return(
        <Splide
            className="articlesCarousel"
            hasTrack={false}
            aria-label="Images"
            options={
                {
                    type:"loop",
                    breakpoints: {
                        1050:{
                            arrows: false,
                            pagination: false,
                            perPage:1,
                        },
                        1200:{
                            perPage:2,
                        },
                        1400:{
                            gap: "16px",
                        }
                    },
                    perPage:3,
                    perMove:1,
                    autoplay:true,
                    pauseOnHover:true,
                    speed:1500,
                    interval:2500,
                    start:0,
                    gap:"32px",
                    padding:"16px",
                    pagination:false,
                    rewind:true,
                    arrowPath:"M28.73,11.31c.43,3.57,1.77,6.1,4.1,7.69l.29,.2H.04s0,1.58,0,1.58H33.15s-.33,.22-.33,.22c-.68,.47-1.3,1.03-1.82,1.68-.92,1.21-1.58,2.6-1.93,4.08-.21,.77-.33,1.56-.35,2.36,.04,.01,1.45,.13,1.6,.13,.07,0,.12-.14,.15-.42h0c.33-2.96,1.43-5.13,3.26-6.46,.64-.42,1.32-.75,2.04-1,1.18-.36,2.41-.55,3.65-.54h.53s0-1.61,0-1.61l-.7-.03c-2.35-.09-4.02-.55-5.42-1.5-.68-.5-1.29-1.1-1.78-1.79-.92-1.47-1.47-3.14-1.61-4.87,0-.1-.02-.2-.05-.29-.02-.01-1.7,.12-1.72,.13,0,0,.02,.19,.05,.44Z",
                    arrows:true,
                    classes: {
                        arrows: 'splide__arrows carousel-arrowsBlock',
                        arrow : 'splide__arrow carousel-arrow',
                        prev  : 'splide__arrow--prev carousel-arrowPrev',
                        next  : 'splide__arrow--next carousel-arrowNext',
                        pagination:"splide__pagination carousel-pagination",
                    }
            }}
        >
            <SplideTrack>
                {loading && 
                    <SplideSlide>
                        <CardArticleSkeleton/>
                        <CardArticleSkeleton/>
                        <CardArticleSkeleton/>
                    </SplideSlide>
                }
                {!loading && !!data &&
                data.search.edges.map((article:SearchAnyArticleEdge, index:number)=>{
                    return(
                        <SplideSlide key={article.node.id}>
                            <CardArticle key={article.node.id} data={article.node} type={type} index={index+1}/>
                        </SplideSlide>
                    )
                })
                }
            </SplideTrack>
        </Splide>
    )
};

export const ArticlesCarouselFull = ({
    isActive,
    setIsActive,
    images,
    onClick
}: ArticleCarouselFullProps)=>{
    const [currentImgNumber, setCurrentImgNumber] = useState<number>(1);

    const carousel: React.MutableRefObject<Splide|null> = useRef(null);

    const handleImageChange = () => {
        if ( carousel.current ) {
            const nbrOfImg = carousel.current.splide?.index;
            nbrOfImg!=undefined&&setCurrentImgNumber(nbrOfImg+1);
        }
    };

    const handleShowCarousel = () => {
        setIsActive(false);
    };

    return(
        <div
            className={classNames("article-image-carousel-block", {show: isActive})}
            onClick={onClick}
        >
            <Splide
                className="article-image-carousel"
                onMove={handleImageChange}
                ref={carousel}
                options={{
                    arrows:false,
                    pagination:false,
                    mediaQuery: 'min',
                    start:1,
                    perPage:1,
                    perMove:1,
                    height:"250px",
                    cover:true,
                    breakpoints:{
                        992:{
                            cover:false,
                            height:"40vw",
                            arrows:true,
                            keyboard:true
                        }
                    }
                }}
            >
                {images.map((img:Image, index:number) => {
                    return(
                        <SplideSlide key={index}>
                            <ResponsiveImage 
                                title={img.title}
                                alternativeText={img.alternativeText}
                                main={img.main}
                                assets={img.assets}
                                aspectRatio={img.aspectRatio}
                                sizes="(max-width: 576px) 85.499vw, (max-width: 992px) 41.815vw, (max-width: 1200px) 40.346vw, 19.531vw"
                        />
                        </SplideSlide>
                    )
                })}
            </Splide>
            <FontAwesomeIcon className="article-image-carousel-close" size="2x" icon={faXmark} onClick={handleShowCarousel}/>
            <div className="article-image-carousel-current">
                {`${currentImgNumber} / ${images.length}`}
            </div>
        </div>
    );
};