import {defineMessages} from "react-intl";

export const main = defineMessages({
    title: {id: "contact-title", defaultMessage: "Contactez <s>Montan’Agence</s>"},
    form_title: {id: "contact-form-title", defaultMessage: "Formulaire <s>de contact</s>"},
    subtitle_1: {id: "contact-form-subtitle-1", defaultMessage: "Contactez<s>-nous</s>"},
    subtitle_2: {id: "contact-form-subtitle-2", defaultMessage: "Nos horaires <s>d’ouverture</s>"},
    schedule_1: {id: "contact-form-schedule-1", defaultMessage: "Du Lundi au Vendredi <br></br> de 8h30 à 12h00 et de 13h30 à 18h00"},
    schedule_2: {id: "contact-form-schedule-2", defaultMessage: "Le Samedi en haute saison <br></br> de 8h30 à 12h00 et de 13h30 à 17h00"},
    schedule_3: {id: "contact-form-schedule-3", defaultMessage: "Dimanche et jours fériés fermés"},
    form_newsletter: {id: "contact-form-newsletter", defaultMessage: "J’accepte de recevoir des informations sur l’immobilier, les nouvelles réglementations et les nouvelles offres de Montan’Agence."},
});