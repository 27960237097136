import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBagShopping,
  faCamera,
  faChevronLeft,
  faCircleDot,
  faCopy,
  faDog,
  faDownload,
  faEnvelope,
  faEye,
  faParking,
  faSmoking,
  faTv,
  faWaterLadder,
  faWifi
} from "@fortawesome/free-solid-svg-icons";
import { AccommodationType, Image } from "@shared/models/article";
import { useScrollLock } from "../../contexts/lockScroll";
import { ArticlesCarousel, ArticlesCarouselFull } from "../../components/carousels/article-carousels";
import ResponsiveImage from "../../utils/images";
import { useQuery } from "@apollo/client";
import { AnyArticle } from "../../models/search-param";
import { GET_ARTICLE } from "./article.config";
import { ArticlePrice, CardArticleDiscount } from "../../components/article/article";
import Skeleton from "react-loading-skeleton";
import { ContactForm } from "../../components/forms/forms";
import { MapContainer, MapMarker, MapPopup } from "../../bundles/map-implement";
import { SaleAccommodationArticle, SaleAccommodationFeatures } from "../../models/article-sale";
import { faFacebookF, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import NotFound from "../notFound/notFound";
import { useLocaleFormatter, useLocaleUrl } from "@ct-react/locale";
import BookingBox from "../../components/booking-box/booking-box";
import SeoHelmet from "../../components/seo-helmet/seo-helmet";
import { RentalAccommodationArticle, RentalAccommodationFeatures } from "../../models/article-rental";
import { classNames, useAppConfig } from "@ct-react/core";

import {
  bookingTranslations,
  buttonsTranslations,
  featureTranslations,
  genericTermTranslations,
  globalTranslations
} from "../../i18n/sharable-defs";
import { article_seo, article_translations } from "./translations";
import "./article.scss";

import ArticleMarker from "../../../assets/svg/location-dot-solid.svg";
import DishWasher from "../../../assets/svg/icone-dishwasher.svg";
import SkiProximity from "../../../assets/svg/icone-ski.svg";


type ArticleProps = {
    type: AccommodationType,
};

type ArticleTitleProps = {
    data: AnyArticle | undefined,
    loading: boolean
};

type ArticleImageGridProps = {
    images: Image[] | undefined,
    loading: boolean
};

type SaleArticleEquipmentProps = {
    data: SaleAccommodationArticle | undefined,
    loading: boolean
};

type RentalArticleEquipmentProps = {
    data: RentalAccommodationArticle | undefined,
    loading: boolean
};

const Article = ({
        type
    }: ArticleProps) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [changeForm, setChangeForm] = useState<boolean>(false);
    const { baseUrl } = useAppConfig();
    const { currentUrl: url } = useLocaleUrl(baseUrl);

    const { data, error, loading } = useQuery<{ articleDetail: AnyArticle }>(GET_ARTICLE, {
        variables:{
            articleId: searchParams.get("refId"),
        },
        ssr: true,
        fetchPolicy: "cache-first"
    });

    const renderTitle = useMemo(() => {
        return(
            <ArticleTitle data={data?.articleDetail} loading={loading}/>
        );
    },[loading]);

    const renderPrice = useMemo(() => {
        if(!loading && data){
            return(
                <ArticlePrice
                    data={data.articleDetail}
                    type={type}
                />
            );
        }else{
            return(
                <Skeleton width="30%"/>
            );
        }
    },[loading]);

    const renderPopup = useMemo(() => {
        if(!data || loading) return undefined ;
        return (
            <MapPopup>
                    {data.articleDetail.address.resume &&
                    <div className="article-popUp-title">
                        {data.articleDetail.address.resume}

                    </div>
                    }
            </MapPopup>
        );
    },[loading,data])

    const renderMap = useMemo(() => {
        if(!loading && data && !!data?.articleDetail.coordinates){
            return(
                <MapContainer center={data?.articleDetail.coordinates} zoom={17}>
                    <MapMarker position={data?.articleDetail.coordinates} icon={<ArticleMarker/>}>
                        {renderPopup}
                    </MapMarker>
                </MapContainer>
            );
        }else if(data?.articleDetail.coordinates === null){
            return(
                <div className="empty-map">
                    <p>{intl.formatMessage(article_translations.empty_map)}</p>
                </div>
            )
        }else{
            return(
                <Skeleton height="25rem"/>
            );
        }
    },[loading]);

    const returnForm = useMemo(() => {
        if( loading ){ //Loading data , Skeleton
            return(
                <div className="bookingBox-skeleton">
                    <Skeleton width="100%" height="100%"/>
                </div>
            );
        }else if(!loading && data){ //Finish loading
            const rentalDetail = data.articleDetail as RentalAccommodationArticle;
            if(changeForm || rentalDetail.lowestPrice?.onDemand || type != AccommodationType.RENTAL){ //Need contact for booking or not Rental article
                return (
                    <>
                        <h3>
                            {intl.formatMessage(article_translations.interest_in_the_accomodation, {s: (chunks: React.ReactNode) => <span>{chunks}</span>})}
                        </h3>
                        <ContactForm articleId={data?.articleDetail.id} type={type} />
                    </>
                );
            }else{ //Show Booking box
                return (
                    <BookingBox
                        articleId={rentalDetail.id}
                        changeForm={() => setChangeForm(true)}
                        touristTax={rentalDetail.touristTax}
                        lowestPrice={rentalDetail.lowestPrice.value}
                        maxGuests={rentalDetail.features.maxGuests}
                    />
                );
            }
        }else{ //Error return empty
            return(<></>);
        }
    },[changeForm,loading]);

    const generateFeatureString = (feature) => {
        const featureTexts = {
            withClearView: intl.formatMessage(article_seo.withClearView),
            nearSkiArea: intl.formatMessage(article_seo.nearSkiArea),
            onCityCenter: intl.formatMessage(article_seo.onCityCenter),
            onShoppingArea: intl.formatMessage(article_seo.onShoppingArea)
        };

        return Object.entries(featureTexts)
            .filter(([key]) => feature[key])
            .map(([, text]) => text)
            .join(", ");
    };

    const renderSeo = useMemo(() => {
        if(!loading && data){
            if(type != AccommodationType.SALE){ // Rental
                const features = data.articleDetail.features as RentalAccommodationFeatures;
                const url = (() => {
                    switch (type) {
                        case AccommodationType.RENTAL:
                            return "/location/vacances/";
                        case AccommodationType.SEASONAL:
                            return "/location/saison/";
                        case AccommodationType.ANNUAL:
                            return "/location/annee/";
                        default:
                            return "/";
                    }
                })();
                return <SeoHelmet
                        title={intl.formatMessage(article_seo.title_rental,{
                                type: data.articleDetail.objectType.name,
                                objectname: data.articleDetail.title.value ,
                                location: data.articleDetail.address.city ,
                                rooms:  data.articleDetail.features.rooms
                            })}
                        description={intl.formatMessage(article_seo.description_rental,{
                                objectname: data.articleDetail.title.value ,
                                location: data.articleDetail.address.city ,
                                rooms:  data.articleDetail.features.rooms,
                                surface:  data.articleDetail.features.livingSpace,
                                features: generateFeatureString(features)
                            })}
                        canonical={`${url}${data.articleDetail.slug}?refId=${data.articleDetail.id}`}
                        keywords={intl.formatMessage(article_seo.keywords_rental,{location: data.articleDetail.address.city})}
                        image={data.articleDetail.seo.image}
            />
            }else{ //Sale
                const features = data.articleDetail.features as SaleAccommodationFeatures;
                const foreigner = features.isSellableToForeigners ? intl.formatMessage(article_seo.secondary_residence) : intl.formatMessage(article_seo.primary_residence);

                return  <SeoHelmet
                            title={ intl.formatMessage(article_seo.title_sale,{
                                    type: data.articleDetail.objectType.name,
                                    objectname: data.articleDetail.title.value ,
                                    location: data.articleDetail.address.city ,
                                    rooms:  data.articleDetail.features.rooms
                                })}
                            description={intl.formatMessage(article_seo.description_sale,{
                                    objectname: data.articleDetail.title.value ,
                                    location: data.articleDetail.address.city ,
                                    rooms:  data.articleDetail.features.rooms,
                                    surface:  data.articleDetail.features.livingSpace,
                                    features: generateFeatureString(features) ,
                                    foreigner: foreigner,
                                })
                            }
                            canonical={`/vente/${data.articleDetail.slug}?refId=${data.articleDetail.id}`}
                            keywords={intl.formatMessage(article_seo.keywords_sale,{location: data.articleDetail.address.city})}
                            image={data.articleDetail.seo.image}
                        />

            }
        }
    },[loading, data, type])

    const goBack = () => {
        navigate(-1)
    };

    const onCopy = (e) => {
        e.target.style.animationPlayState = "running";
        navigator.clipboard.writeText(url).then();
    };

    // To redirect 404!!
    if(error){
        return <NotFound />
    }
    return (
        <>
            {renderSeo}
            {/* Détails article */}
            <section className="article">
                <div className="article-header desktop">
                    <div>
                        <div className="goBack" onClick={goBack}>
                            <FontAwesomeIcon className="article-header-chevronLeft" icon={faChevronLeft} />
                            <span className="article-header-navigator">
                                {intl.formatMessage(buttonsTranslations.back)}
                            </span>
                        </div>
                    </div>
                    {renderTitle}
                    <div className="article-header-info">
                        {renderPrice}
                        <div className="article-header-icones">
                            <a
                                className="facebook"
                                href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`}
                                data-gloss={intl.formatMessage(article_translations.share_on_facebook)}
                                aria-label={intl.formatMessage(article_translations.share_on_facebook)}
                                target="_blank"
                                >
                                <FontAwesomeIcon icon={faFacebookF}/>
                            </a>
                            <a
                                className="whatsapp"
                                href={`whatsapp://send?text=${encodeURIComponent(url)}`}
                                data-gloss={intl.formatMessage(article_translations.share_on_whatsapp)}
                                aria-label={intl.formatMessage(article_translations.share_on_whatsapp)}
                                target="_blank"
                                >
                                <FontAwesomeIcon icon={faWhatsapp}/>
                            </a>
                            <a
                                className="email"
                                href={`mailto:?body=${encodeURIComponent(url)}`}
                                data-gloss={intl.formatMessage(article_translations.share_by_email)}
                                aria-label={intl.formatMessage(article_translations.share_by_email)}
                            >
                                <FontAwesomeIcon icon={faEnvelope}/>
                            </a>
                            <button
                                type="button"
                                className="copy"
                                onClick={onCopy}
                                data-gloss={intl.formatMessage(article_translations.copy_url)}
                                aria-label={intl.formatMessage(article_translations.copy_url)}
                            >
                                <FontAwesomeIcon icon={faCopy}/>
                            </button>
                            <button
                                className="print"
                                data-gloss={intl.formatMessage(article_translations.download_pdf)}
                                aria-label={intl.formatMessage(article_translations.download_pdf)}
                            >
                                <FontAwesomeIcon icon={faDownload} onClick={() => window.print()} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="article-image">
                {(type === AccommodationType.RENTAL && !loading) &&
                    <CardArticleDiscount discounts={(data?.articleDetail as RentalAccommodationArticle).discounts}/>
                }
                    <ArticleImageGrid images={data?.articleDetail.images} loading={loading} />
                </div>
                <div className="article-info">
                    <div className="article-header smartphone">
                        <div className="goBack" onClick={goBack}>
                            <FontAwesomeIcon className="article-header-chevronLeft" icon={faChevronLeft} />
                            <span className="article-header-navigator">
                                {intl.formatMessage(buttonsTranslations.back)}
                            </span>
                        </div>
                        {renderTitle}
                        {renderPrice}
                    </div>
                    <div className="article-description">
                        <h2>
                            {intl.formatMessage(article_translations.description, { s: (chunks: React.ReactNode) => <span>{chunks}</span> })}
                        </h2>
                        {(!loading && data) && !!data.articleDetail.description
                          ? <div className="r-format-html" dangerouslySetInnerHTML={{ __html: data.articleDetail.description.value }} />
                          : intl.formatMessage(article_translations.no_description)
                        }
                        { type === AccommodationType.SALE ?
                            <SaleArticleEquipment data={data?.articleDetail as unknown as SaleAccommodationArticle} loading={loading}/>
                        :
                            <RentArticleEquipement data={data?.articleDetail as unknown as RentalAccommodationArticle} loading={loading}/>
                        }
                            <div className="article-header-icones">
                                <a
                                    className="facebook"
                                    href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`}
                                    data-gloss={intl.formatMessage(article_translations.share_on_facebook)}
                                    aria-label={intl.formatMessage(article_translations.share_on_facebook)}
                                    target="_blank"
                                    >
                                    <FontAwesomeIcon icon={faFacebookF}/>
                                </a>
                                <a
                                    className="whatsapp"
                                    href={`whatsapp://send?text=${encodeURIComponent(url)}`}
                                    data-gloss={intl.formatMessage(article_translations.share_on_whatsapp)}
                                    aria-label={intl.formatMessage(article_translations.share_on_whatsapp)}
                                    target="_blank"
                                    >
                                    <FontAwesomeIcon icon={faWhatsapp}/>
                                </a>
                                <a
                                    className="email"
                                    href={`mailto:?body=${encodeURIComponent(url)}`}
                                    data-gloss={intl.formatMessage(article_translations.share_by_email)}
                                    aria-label={intl.formatMessage(article_translations.share_by_email)}
                                >
                                    <FontAwesomeIcon icon={faEnvelope}/>
                                </a>
                                <button
                                    type="button"
                                    className="copy"
                                    onClick={onCopy}
                                    data-gloss={intl.formatMessage(article_translations.copy_url)}
                                    aria-label={intl.formatMessage(article_translations.copy_url)}
                                >
                                    <FontAwesomeIcon icon={faCopy}/>
                                </button>
                                <button
                                    className="print"
                                    data-gloss={intl.formatMessage(article_translations.download_pdf)}
                                    aria-label={intl.formatMessage(article_translations.download_pdf)}
                                >
                                    <FontAwesomeIcon icon={faDownload} onClick={() => window.print()} />
                                </button>
                            </div>
                    </div>
                    <div className="article-form">
                        {returnForm}
                        {/* <ShareIcones /> */}
                    </div>
                </div>
            </section>

            {/* Map */}
            <section className="article-map">
                <h2>
                    {intl.formatMessage(article_translations.title_location,{s: (chunks:React.ReactNode) => <span>{chunks}</span>})}
                </h2>
                {renderMap}
            </section>

            {/* Nos annonces */}
            <section className="article-annonces">
                <h2>
                    {type != AccommodationType.SALE ?
                    intl.formatMessage(article_translations.title_announces_rent, { s: (chunks: React.ReactNode) => <span>{chunks}</span> })
                    :
                    intl.formatMessage(article_translations.title_announces_sale, { s: (chunks: React.ReactNode) => <span>{chunks}</span> })
                    }
                </h2>
                <ArticlesCarousel type={type}/>
            </section>
        </>
    );
};

const ArticleTitle = ({
    data,
    loading
}: ArticleTitleProps) => {
    const intl = useIntl();
    if(!loading && data){
        return(
            <div className="title">
                <span>
                    {data.features.rooms&&`${data.features.rooms} ${intl.formatMessage(bookingTranslations.rooms)}`}
                    {(!!data.features.livingSpace && data.features.rooms) &&` | `}
                    {!!data.features.livingSpace&&`${data.features.livingSpace} m²`}
                </span>
                <h1>
                    {data.title.value}
                </h1>
            </div>
        );
    }else{
        return(
            <>
                <Skeleton  width="8rem"/>
                <Skeleton className="title-skeleton"/>
            </>
        );
    }
};

const ArticleImageGrid = ({
    images,
    loading
}: ArticleImageGridProps) => {
    const intl = useIntl();
    const [showCarousel,setShowCarousel] = useState<boolean>(false);
    const { lockScroll, unlockScroll } = useScrollLock();

    const handleShowCarousel = (e: React.MouseEvent<HTMLElement>) => {
        const target = e.target as HTMLElement;

        if (target.tagName === "BUTTON") return;

        e.nativeEvent.stopImmediatePropagation();
        setShowCarousel(!showCarousel);
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        e.key === "Escape" && setShowCarousel(false);
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

    useEffect(() => {
        showCarousel && lockScroll();
        !showCarousel && unlockScroll();
    },[showCarousel]);

    if( loading ){
        return(
            <Skeleton height="50vh"/>
        );
    }else if (images && images.length > 0){
        return(
            <>
                <ArticlesCarouselFull
                    isActive={showCarousel}
                    setIsActive={setShowCarousel}
                    images={images}
                    onClick={handleShowCarousel}
                />
                <div className="article-image-grid" onClick={handleShowCarousel}>
                    {
                        images.slice(0, 5).map((img:Image, index: number) => {
                            return(
                                <div key={index}>
                                    <ResponsiveImage
                                        title={img.title}
                                        alternativeText={img.alternativeText}
                                        main={img.main}
                                        assets={img.assets}
                                        aspectRatio={img.aspectRatio}
                                        sizes="(max-width: 576px) 80vw, (max-width: 992px) 45vw, (max-width: 1200px) 35.833vw, 35.781vw"
                                    />
                                </div>
                            );
                        })
                    }

                    <div className="grid-btn" onClick={handleShowCarousel}>
                        <FontAwesomeIcon icon={faCamera}/>
                        {intl.formatMessage({ id:"carousel-see-photos", defaultMessage: "Voir les {length} photos"},{length:images.length})}
                    </div>
                </div>
            </>
        );
    }else{
        return(
            <div className="article-image-empty">
                <FontAwesomeIcon icon={faCamera}/>
                <p>
                    {intl.formatMessage(genericTermTranslations.no_image)}
                </p>
            </div>
        );
    }
};

const SaleArticleEquipment = ({
    data,
    loading
}: SaleArticleEquipmentProps) => {

    const intl = useIntl();
    const { print, transLib } = useLocaleFormatter();

    if(!loading && data!){
        return(
            <div className="article-sale-equipment">
                <ul>
                    {/* Price */}
                    <li>
                        <p>
                            <b>{intl.formatMessage(bookingTranslations.price)} : &nbsp;</b>
                            {data.price.onDemand
                          ? intl.formatMessage(bookingTranslations.fullPriceOnDemand)
                          : print.price(data?.price.value, { trailingZeroDisplay: "stripIfInteger" })}
                        </p>
                    </li>

                    {/* Years */}
                    <li>
                        <p>
                            <b>Année : &nbsp;</b>
                            {!!data?.features.constructionYear ?
                            data.features.constructionYear
                            :
                            "-"}
                        </p>
                    </li>

                    {/* Chambre */}
                    <li>
                        <p>
                            <b>{intl.formatMessage(featureTranslations.bedroom)} : &nbsp;</b>
                            {!!data?.features.bedrooms ?
                            data.features.bedrooms
                            :
                            "-"}
                        </p>
                    </li>

                    {/* Parking */}
                    <li>
                        <p>
                            <b>{intl.formatMessage(featureTranslations.parking)} : &nbsp;</b>
                            {data?.features.parkings ?
                            intl.formatMessage(globalTranslations.yes)
                            :
                            intl.formatMessage(globalTranslations.no)}
                        </p>
                    </li>

                    {/* Étage */}
                    <li>
                        <p>
                            <b>Étage : &nbsp;</b>
                            {!!data?.features.floor ?
                            data?.features.floor.value
                            :
                            "-"}
                        </p>
                    </li>
                </ul>

                <ul>
                    {/* Surface */}
                    <li>
                        <p>
                            <b>{intl.formatMessage(bookingTranslations.livingSpace)} : &nbsp;</b>
                            {!!data?.features.livingSpace ?
                            data.features.livingSpace
                            :
                            "-"}
                        </p>
                    </li>

                    {/* Orientation */}
                    <li>
                        <p>
                            <b>{intl.formatMessage(featureTranslations.orientation)} : &nbsp;</b>
                            {!!data?.features.orientation ?
                            transLib.match("orientations", data!.features.orientation)
                            :
                            "-"}
                        </p>
                    </li>

                    {/* Salle de bain */}
                    <li>
                        <p>
                            <b>{intl.formatMessage(featureTranslations.bathrooms)} : &nbsp;</b>
                            {!!data?.features.bathrooms ?
                            data.features.bathrooms
                            :
                            "-"}
                        </p>
                    </li>

                    {/* Chauffage */}
                    <li>
                        <p>
                            <b>{intl.formatMessage(featureTranslations.fireplace)} : &nbsp;</b>
                        {!!data?.features.withFireplace ?
                            intl.formatMessage(globalTranslations.yes)
                            :
                            intl.formatMessage(globalTranslations.no)}
                        </p>
                    </li>

                    {/* Ascenceur */}
                    <li>
                        <p>
                            <b>{intl.formatMessage(featureTranslations.withLift)} : &nbsp;</b>
                        {!!data?.features.withLift ?
                            intl.formatMessage(globalTranslations.yes)
                            :
                            intl.formatMessage(globalTranslations.no)}
                        </p>
                    </li>

                </ul>

                {/* Vente aux étranger */}
                <div>
                    <p>
                        <b>{intl.formatMessage(featureTranslations.isSellableToForeigners)} : &nbsp;</b>
                        {data?.features.isSellableToForeigners ?
                        intl.formatMessage(globalTranslations.yes)
                        :
                        intl.formatMessage(globalTranslations.no)}
                    </p>
                </div>
                {/* Charges estimatives annuelles */}
                <div>
                    <p>
                        <b>{intl.formatMessage(featureTranslations.workingCapitalFund)} : &nbsp;</b>
                        {!!data?.features.workingCapitalFund ?
                        data.features.workingCapitalFund.amount
                        :
                        "-"}
                    </p>
                </div>
            </div>
        );
    }else{
        return(
            <></>
        );
    }
};

const RentArticleEquipement = ({
    data,
    loading
}: RentalArticleEquipmentProps) => {
    const intl = useIntl();

    if(!loading && data){
        return(
            <div className="article-rent-equipment">
                <h2>
                    {intl.formatMessage(article_translations.title_rental_equipment, { s: (chunks: React.ReactNode) => <span>{chunks}</span> })}
                </h2>
                <ul>
                    <li>
                        <FontAwesomeIcon icon={faSmoking}/>
                        <p className={classNames({lineThrough: !data.features.isSmokerCompliant })}>
                            {intl.formatMessage(article_translations.smoker)}
                        </p>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faParking}/>
                        <p className={classNames({lineThrough: !data.features.withParking })}>
                            {intl.formatMessage(article_translations.free_parking)}
                        </p>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faDog}/>
                        <p className={classNames({lineThrough: !data.features.isPetCompliant })}>
                            {intl.formatMessage(article_translations.pet_allowed)}
                        </p>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faWifi}/>
                        <p className={classNames({lineThrough: !data.features.withWIFI })}>
                            {intl.formatMessage(article_translations.wifi_availaible)}
                        </p>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faTv}/>
                        <p className={classNames({lineThrough: !data.features.withWIFI })}>
                            {intl.formatMessage(article_translations.with_tv)}
                        </p>
                    </li>
                    <li>
                        <DishWasher/>
                        <p className={classNames({lineThrough: !data.features.withDishwasher })}>
                            {intl.formatMessage(article_translations.with_dishwasher)}
                        </p>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faWaterLadder}/>
                        <p className={classNames({lineThrough: !data.features.withPool })}>
                            {intl.formatMessage(article_translations.with_pool)}
                        </p>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faBagShopping}/>
                        <p className={classNames({lineThrough: !data.features.onShoppingArea })}>
                            {intl.formatMessage(article_translations.on_shopping_street)}
                        </p>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faEye}/>
                        <p className={classNames({lineThrough: !data.features.withClearView })}>
                            {intl.formatMessage(article_translations.clear_view)}
                        </p>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faCircleDot}/>
                        <p className={classNames({lineThrough: !data.features.onCityCenter })}>
                            {intl.formatMessage(article_translations.center)}
                        </p>
                    </li>
                    <li>
                        <SkiProximity/>
                        <p className={classNames({lineThrough: !data.features.nearSkiArea })}>
                            {intl.formatMessage(featureTranslations.skiArea)}
                        </p>
                    </li>
                </ul>
            </div>
        );
    }else{
        return(<></>);
    }
};

export default Article;
