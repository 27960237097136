import React, { ReactNode } from "react";
import { CmsImageField } from "../../models/cms-page";
import ResponsiveImage from "../../utils/images";
import { classNames } from "@ct-react/core";
import "./twoColumn.scss"

type TwoColumnProps = {
    children:ReactNode,
    imageSrc?:string,
    image?: CmsImageField,
    alt?:string,
    revert?:boolean
    className?:string
}

type ColumnTextProps = {
    children:ReactNode,
}

type ColumnImageProps = {
    image?: CmsImageField,
    src?: string,
    alt?: string,
};

const ColumnText = ({
    children,
    }:ColumnTextProps) => {
        return(
            <div className="column column-text">
                {children}
            </div>
        );
};

const ColumnImage = ({
    image,
    src,
    alt,
    }:ColumnImageProps) => {
        return(
            <div className="column column-image">
                {image ?
                <ResponsiveImage
                    alternativeText={alt}
                    main={false}
                    assets={image.assets}
                    aspectRatio={image.aspectRatio}
                    sizes="(max-width: 992px) 95vw, 42vw"
                />
                :
                <img src={src} alt={alt} loading="lazy"/>
                }
            </div>
        );
};

const TwoColumn = ({
    children,
    className = "",
    image,
    imageSrc = "",
    alt = "",
    revert = false,
    }: TwoColumnProps) => {
        return(
            <section className={classNames(`twoColumn ${className}`,{revertColumn:revert})}>
                <ColumnText>
                    {children}
                </ColumnText>
                <ColumnImage src={imageSrc} alt={alt} image={image}/>
            </section>
        );
};

export default TwoColumn;
