import React, { FormEvent, PropsWithChildren } from "react";
import { classNames } from "@ct-react/core";
import { LocaleLink } from "@ct-react/locale";
import "./buttons.scss";

type ButtonProps = PropsWithChildren & {
  type?: "button" | "submit" | "reset";
  to?: string;
  id?: string;
  classes?: string;
  onClick?: (e: React.MouseEvent) => void;
};

type RedButtonProps = PropsWithChildren & {
  type?: "button" | "submit" | "reset";
  uppercase?: boolean,
  disabled?:boolean,
  onClick: (e: FormEvent<any>) => void,
};

const Button = (
  {
    type = "button",
    to,
    id,
    classes: classProps,
    onClick,
    children
  } : ButtonProps) => {

  const classes = classNames("btn", classProps, { "with-arrow": !!to });

  if (!!to)
    return (
      <LocaleLink type={type} id={id} to={to} className={classes}>
        {children}
      </LocaleLink>);

  return(
    <button type={type} id={id} className={classes} onClick={onClick}>
      {children}
    </button>);

};

const RedButton = (
  {
    type = "button",
    uppercase=false,
    disabled=false,
    onClick,
    children
  }: RedButtonProps) => {

  const classes = classNames("btn-red", { uppercase });

  return(
    <button type={type} className={classes} disabled={disabled} onClick={onClick}>
      {children}
    </button>);

};

export {
    Button,
    RedButton
};
