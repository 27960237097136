import React, { createContext, useContext, useState, useEffect } from "react";

type ScrollLockContextType = {
  isScrollLocked: boolean;
  lockScroll: () => void;
  unlockScroll: () => void;
};

const ScrollLockContext = createContext<ScrollLockContextType | undefined>(undefined);

export const ScrollLockProvider = ({
    children
}: { children: React.ReactNode }) => {
  const [isScrollLocked, setIsScrollLocked] = useState(false);

  const lockScroll = () => {
    if (!isScrollLocked) {
      document.body.classList.add("no-scroll");
      setIsScrollLocked(true);
    }
  };

  const unlockScroll = () => {
    if (isScrollLocked) {
      document.body.classList.remove("no-scroll");
      setIsScrollLocked(false);
    }
  };

  useEffect(() => {
    return () => document.body.classList.remove("no-scroll");
  }, []);

  return (
    <ScrollLockContext.Provider value={{ isScrollLocked, lockScroll, unlockScroll }}>
      {children}
    </ScrollLockContext.Provider>
  );
};

export const useScrollLock = (): ScrollLockContextType => {
  const context = useContext(ScrollLockContext);
  if (!context) {
    throw new Error("useScrollLock must be used within a ScrollLockProvider");
  }
  return context;
};
