import React, { useContext, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";
import gsap from "gsap";
import { classNames } from "@ct-react/core";
import { LocaleNavLink } from "@ct-react/locale";
import { genericTermTranslations } from "../../i18n/sharable-defs";
import { CmsContext } from "../../contexts/cms";
import { MenuContext } from "../../contexts/menu";
import { useScrollLock } from "../../contexts/lockScroll";
import { BasketIcone, ProfilIcone } from "../../components/icones/icones";
import { SubNav, SubNavLink } from "./subnav";
import Languages from "./languages";
import Logo from "../../../assets/svg/logo-header.svg";
import "./menu.scss";

const Menu = () => {

  const intl = useIntl();
  const location = useLocation();
  const { navigation: cmsNavigation } = useContext(CmsContext);
  const { isDown, setIsDown } = useContext(MenuContext);
  const { lockScroll, unlockScroll } = useScrollLock();

  const gsapMedia = gsap.matchMedia();
  const navigation = useRef(null);
  const line1Ref = useRef(null);
  const line2Ref = useRef(null);
  const line3Ref = useRef(null);

  const openMenu = () => {
    setIsDown(true);
    const tl = gsap.timeline();
    gsapMedia.add("(max-width: 900px)",() => {
      lockScroll();
      tl.addLabel("start")
        .to(navigation.current,{x: 0, autoAlpha: 1},"start")
        .to(line1Ref.current,{attr:{y1:37.11,y2:37,x1:23,x2:58}}, "start")
        .to(line3Ref.current,{attr:{y1:37.11,y2:37,x1:23,x2:58}}, "start")
        .to(line2Ref.current,{autoAlpha:0}, "start")
        .addLabel("afterStart", "0")
        .to(line1Ref.current,{attr:{y1:50.11,y2:25,x1:26,x2:55},delay:0.4}, "afterStart")
        .to(line3Ref.current,{attr:{y1:25.11,y2:50,x1:26,x2:55},delay:0.4}, "afterStart");
    })
    return () => tl.kill();
  };

  const closeMenu = () => {
    setIsDown(false);
    const tl = gsap.timeline();
    gsapMedia.add("(max-width: 900px)",() => {
      unlockScroll();
      tl.addLabel("start")
        .to(navigation.current,{x: "-100%", autoAlpha: 0},"start")
        .to(line1Ref.current,{attr:{y1:37.11,y2:37,x1:23,x2:58}}, "start")
        .to(line3Ref.current,{attr:{y1:37.11,y2:37,x1:23,x2:58}}, "start")
        .to(line2Ref.current,{autoAlpha:1}, "start")
        .addLabel("afterStart", "0")
        .to(line1Ref.current,{attr:{y1:25.11,y2:25,x1:23,x2:58},delay:0.4}, "afterStart")
      .to(line3Ref.current,{attr:{y1:50.11,y2:50,x1:23,x2:58},delay:0.4}, "afterStart");
    })
    return () => tl.kill();
  };

  const handleOnClick = () => isDown ? closeMenu() : openMenu();

  useEffect(() => { !isDown && closeMenu(); }, [ isDown ]);

  return (
    <header>
      <LocaleNavLink to={"/"}
                     onClick={()=>setIsDown(false)}
                     className="logo" aria-label="Page d'accueil">
        <Logo/>
      </LocaleNavLink>

      <nav ref={navigation} className="navigation">
        <ul>
          <SubNav link={intl.formatMessage(genericTermTranslations.location)}
                  classes={classNames({active: location.pathname.includes("/location/")})}>
            <SubNavLink click={()=>setIsDown(false)} to="location/vacances">
              {intl.formatMessage(genericTermTranslations.locationHolidays)}
            </SubNavLink>
            <SubNavLink click={()=>setIsDown(false)} to="location/saison">
              {intl.formatMessage(genericTermTranslations.locationSeasonal)}
            </SubNavLink>
            <SubNavLink click={()=>setIsDown(false)} to="location/annee">
              {intl.formatMessage(genericTermTranslations.locationYearly)}
            </SubNavLink>
          </SubNav>

          <li>
            <LocaleNavLink to={"vente"} onClick={()=>setIsDown(false)}>
              {intl.formatMessage(genericTermTranslations.sale)}
            </LocaleNavLink>
          </li>

          <SubNav link={intl.formatMessage(genericTermTranslations.services)}
                  classes={classNames({ active: location.pathname.includes("/services") })}>
            {!!cmsNavigation.services && cmsNavigation.services.map(({ path, label }, i) =>
              <SubNavLink key={i} to={path} click={() => setIsDown(false)}>{label}</SubNavLink>)
            }
          </SubNav>

          <SubNav link={intl.formatMessage(genericTermTranslations.agence)}
                  classes={classNames({ active: location.pathname.includes("/agency") })}>
            {!!cmsNavigation.agency && cmsNavigation.agency.map(({ path, label }, i) =>
              <SubNavLink key={i} to={path} click={() => setIsDown(false)}>{label}</SubNavLink>)
            }
          </SubNav>

          <li>
            <LocaleNavLink to={"contact"} onClick={()=>setIsDown(false)}>
              {intl.formatMessage(genericTermTranslations.contact)}
            </LocaleNavLink>
          </li>

        </ul>
        <div>
          <ProfilIcone/>
          <BasketIcone/>
          <Languages/>
        </div>
      </nav>

      <div className="burger" onClick={handleOnClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="5em" viewBox="0 0 80 80">
          <line ref={line1Ref} x1="23" y1="25.11" x2="58" y2="25" strokeLinecap="round" strokeWidth="3"/>
          <line ref={line2Ref} x1="23" y1="37.11" x2="58" y2="37" strokeLinecap="round" strokeWidth="3"/>
          <line ref={line3Ref} x1="23" y1="50.11" x2="58" y2="50" strokeLinecap="round" strokeWidth="3"/>
        </svg>
      </div>

    </header>);

};

export default Menu;
