import {  AccommodationType } from "@shared/models/article";
import { AnyArticleFilterInput } from "../../models/search-param";

export const determineArticleCarouselsConfig = (type: AccommodationType): AnyArticleFilterInput => {
    switch (type) {
        case AccommodationType.RENTAL:
            return { rentalAccommodation: {} }
            break;
        case AccommodationType.SEASONAL:
            return { seasonalAccommodation: {} }
            break;
    
        case AccommodationType.ANNUAL:
            return { annualAccommodation: {} }
            break;
    
        case AccommodationType.SALE:
            return { saleAccommodation: {} }
            break;
        default:
            return { rentalAccommodation: {} }
            break;
    }
};