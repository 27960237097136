import React from "react";
import { useIntl } from "react-intl";

import { main } from "./translations";

const LegalMention = (
    {
    }) => {
    const intl = useIntl();

    return(
        <section>
            <h4 className="marged">{intl.formatMessage(main.text_1)}</h4>
            <p>
                {intl.formatMessage(main.text_2)}<br/>
                {intl.formatMessage(main.text_3)}<br/>
                {intl.formatMessage(main.text_4)}<br/>
                E-mail : [gerance@montanagence.ch]<br/>
                {intl.formatMessage(main.text_5)}
            </p>

            <h4 className="marged">{intl.formatMessage(main.text_6)}</h4>
            <p>
                <a href="https://www.creative-technologies.ch/" target="_blank">C.T. Creative Technologies SA </a><br/>
                {intl.formatMessage(main.text_7)}<br/>
                {intl.formatMessage(main.text_8)}
            </p>

            <h4 className="marged">{intl.formatMessage(main.text_9)}</h4>
            <p>
                {intl.formatMessage(main.text_10)}
            </p>

            <h4 className="marged">{intl.formatMessage(main.text_11)}</h4>
            <p>
                {intl.formatMessage(main.text_12)}
            </p>
            <br/>
            <p>
                {intl.formatMessage(main.text_13)}
            </p>
        </section>
    );

};

export default LegalMention;