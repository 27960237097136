import React, { useContext, useEffect, useState } from "react";
import TwoColumn from "../../components/sections/twoColumn";
import { Button } from "../../components/buttons/buttons";
import { useIntl } from "react-intl";
import { classNames } from "@ct-react/core";
import { LocaleLink } from "@ct-react/locale";
import { AccommodationType } from "@shared/models/article";
import { buttonsTranslations, genericTermTranslations } from "../../i18n/sharable-defs";
import {
  section_annonces,
  section_expertises,
  section_newsletter,
  section_services,
  section_vendreOuLouer,
  seo
} from "./translations";
import { CmsContext } from "../../contexts/cms";
import { ArticlesCarousel } from "../../components/carousels/article-carousels";
import { NewsletterForm } from "../../components/forms/forms";
import SeoHelmet from "../../components/seo-helmet/seo-helmet";
import MainImage from "../../../assets/img/tim-arnold-jVxU1HRbi_A-unsplash.jpg";
import ExpertImmobilier from "../../../assets/img/expert-immobilier.jpg";
import Image_1 from "../../../assets/img/claire-berclaz-immobilier-crans-montana.png";
import Red_arrow from "../../../assets/svg/fleche-rouge-photo.svg";
import Icone_1 from "../../../assets/svg/icone-montagence-13.svg";
import Icone_3 from "../../../assets/svg/icone-montagence-14.svg";
import Icone_2 from "../../../assets/svg/icone-montagence-17.svg";
import Icone_4 from "../../../assets/svg/icone-montagence-15.svg";
import Icone_5 from "../../../assets/svg/icone-montagence-19.svg";
import Icone_6 from "../../../assets/svg/icone-montagence-16.svg";
import Icone_7 from "../../../assets/svg/icone-montagence-18.svg";
import "./home.scss";

const Home = () => {

    const intl = useIntl();
    const { navigation: cmsNavigation } = useContext(CmsContext);
    const [ type, setType ] = useState<AccommodationType>(AccommodationType.SALE);
    const [ cmsAbout, setCmsAbount ] = useState<{ path: string } | undefined>(undefined);
    const [ cmsSellOut, setCmsSellOut ] = useState<{ path: string } | undefined>(undefined);
    const [ cmsRentOut, setCmsRentOut ] = useState<{ path: string } | undefined>(undefined);
    const [ cmsRealEstate, setCmsRealEstate ] = useState<{ path: string } | undefined>(undefined);
    const [ cmsDomiciliation, setCmsDomiciliation ] = useState<{ path: string } | undefined>(undefined);
    const [ cmsBuildingAdmin, setCmsBuildingAdmin ] = useState<{ path: string } | undefined>(undefined);

    useEffect(() => {
      const searchAbout = cmsNavigation.agency?.find(np => np.id === "eaa4b5eb-7e65-4642-bb58-9b2ec2e084ec");
      const searchRentOut = cmsNavigation.services?.find(np => np.id === "55add8e6-4242-4c69-8874-c52c22db1bed");
      const searchSellOut = cmsNavigation.services?.find(np => np.id === "e2d81526-cdb2-46b1-b18b-f8e7812f2230");
      const searchRealEstate = cmsNavigation.services?.find(np => np.id === "7d176654-a352-4476-a5bd-c70a7fa5a1a4");
      const searchDomiciliation = cmsNavigation.services?.find(np => np.id === "0cc8448b-0789-4d97-bf06-ec310fb82869");
      const searchBuildingAdmin = cmsNavigation.services?.find(np => np.id === "0d65e3b0-1404-4343-95bb-f6f99e61fbb0");
      !!searchAbout && setCmsAbount(searchAbout);
      !!searchRentOut && setCmsRentOut(searchRentOut);
      !!searchSellOut && setCmsSellOut(searchSellOut);
      !!searchRealEstate && setCmsRealEstate(searchRealEstate);
      !!searchDomiciliation && setCmsDomiciliation(searchDomiciliation);
      !!searchBuildingAdmin && setCmsBuildingAdmin(searchBuildingAdmin);
    }, [ cmsNavigation ]);

    const switchType = (event: React.MouseEvent) => {
        //@ts-ignore
        const type = event.currentTarget.id;
        setType(AccommodationType[type]);
    };

    return(
        <>
            <SeoHelmet
                title={intl.formatMessage(seo.title)}
                description={intl.formatMessage(seo.description)}
                canonical="/"
                keywords={["agence immobilière crans montana", "montana agence", "immobilier crans montana", "agence immobilière valais", "annonce immobilière"]}
                image={MainImage}
            />

            {/* Hero */}
            <section className="hero">
                <img src={MainImage} alt="Crans-montana"/>
                <hgroup>
                    <h1 className="home-page-h1">
                        <span>{intl.formatMessage(genericTermTranslations.agency)}</span><br/>
                        <span className="crans">{intl.formatMessage(genericTermTranslations.atCran)}</span>
                    </h1>
                    <p>{intl.formatMessage(genericTermTranslations.montanagence_agency)}</p>
                </hgroup>
            </section>

            {/* Nos annonces */}
            <section className="section-annonces">
                <h2>
                    {intl.formatMessage(section_annonces.title)}<br/>
                    <span>{intl.formatMessage(section_annonces.span)}</span>
                </h2>
                <p>
                    {intl.formatMessage(section_annonces.text)}
                </p>
                <div className="switchs">
                    <Button classes={classNames({activ: type === AccommodationType.SALE})} id={"SALE"} onClick={switchType}>
                        {intl.formatMessage(genericTermTranslations.inSale)}
                    </Button>
                    <Button classes={classNames({activ: type === AccommodationType.SEASONAL})} id={"SEASONAL"} onClick={switchType}>
                        {intl.formatMessage(genericTermTranslations.inRent)}
                    </Button>
                    <Button classes={classNames({activ: type === AccommodationType.RENTAL})} id={"RENTAL"} onClick={switchType}>
                        {intl.formatMessage(genericTermTranslations.locationHolidays)}
                    </Button>
                </div>
                {type === AccommodationType.SALE &&
                    <ArticlesCarousel type={AccommodationType.SALE}/>
                }
                {type === AccommodationType.SEASONAL &&
                    <ArticlesCarousel type={AccommodationType.SEASONAL}/>
                }
                {type === AccommodationType.RENTAL &&
                    <ArticlesCarousel type={AccommodationType.RENTAL}/>
                }
            </section>

            {/* Vendre ou louer? */}
            <section className="section-vendre">
                <h2>
                    {intl.formatMessage(section_vendreOuLouer.title)}<br/>
                    <span>{intl.formatMessage(section_vendreOuLouer.span)}</span>
                </h2>
                <div className="text">
                    <p>
                        {intl.formatMessage(section_vendreOuLouer.text)}
                    </p>
                </div>
                <div className="links">
                  {!!cmsSellOut &&
                    <LocaleLink to={cmsSellOut.path} className="btn-red">
                      {intl.formatMessage(section_vendreOuLouer.button_1)}
                    </LocaleLink>
                  }
                  {!!cmsRentOut &&
                    <LocaleLink to={cmsRentOut.path} className="btn-red">
                      {intl.formatMessage(section_vendreOuLouer.button_2)}
                    </LocaleLink>
                  }
                </div>
            </section>

            {/* Expertise */}
            <TwoColumn imageSrc={ExpertImmobilier}>
                <h2>{intl.formatMessage(section_expertises.title)}<br/>
                    <span>{intl.formatMessage(section_expertises.span)}</span>
                </h2>
                <p>
                    {intl.formatMessage(section_expertises.text1,{b: (chunks:React.ReactNode) => <strong>{chunks}</strong>})}<br/>
                    <br/>
                    {intl.formatMessage(section_expertises.text2)}<br/>
                    <br/>
                    {intl.formatMessage(section_expertises.text3)}<br/>
                    <br/>
                    {intl.formatMessage(section_expertises.text4)}
                </p>
                {!!cmsAbout &&
                  <Button to={cmsAbout.path}>{intl.formatMessage(buttonsTranslations.know_more)}</Button>
                }

            </TwoColumn>

            {/* Nos services immobiliers */}
            <section className="section-services">
                <h2>
                    {intl.formatMessage(section_services.title)}<br/>
                    <span>{intl.formatMessage(section_services.span)}</span>
                </h2>
                <p>
                    {intl.formatMessage(section_services.text)}
                </p>
                <div className="services">
                    {/* Courtage et estimations */}
                    <div>
                        <div className="service-icone">
                            <Icone_7/>
                        </div>
                        <h3>{intl.formatMessage(section_services.serviceTitle_1)}</h3>
                        <div>
                            <p>{intl.formatMessage(section_services.serviceText_1)}</p>
                            {!!cmsSellOut &&
                              <Button to={cmsSellOut.path}>{intl.formatMessage(buttonsTranslations.know_more)}</Button>
                            }
                        </div>
                    </div>

                    {/* Location vacances */}
                    <div>
                        <div className="service-icone">
                            <Icone_2/>
                        </div>
                        <h3>{intl.formatMessage(section_services.serviceTitle_2)}</h3>
                        <div>
                            <p>{intl.formatMessage(section_services.serviceText_2)}</p>
                            {!!cmsRentOut &&
                              <Button to={cmsRentOut.path}>{intl.formatMessage(buttonsTranslations.know_more)}</Button>
                            }
                        </div>
                    </div>

                    {/* Locations annuel */}
                    <div>
                        <div className="service-icone">
                            <Icone_3/>
                        </div>
                        <h3>{intl.formatMessage(section_services.serviceTitle_3)}</h3>
                        <div>
                            <p>{intl.formatMessage(section_services.serviceText_3)}</p>
                            {!!cmsRentOut &&
                              <Button to={cmsRentOut.path}>{intl.formatMessage(buttonsTranslations.know_more)}</Button>
                            }
                        </div>
                    </div>

                    {/* Promotion immo */}
                    <div>
                        <div className="service-icone">
                            <Icone_1/>
                        </div>
                        <h3>{intl.formatMessage(section_services.serviceTitle_4)}</h3>
                        <div>
                            <p>{intl.formatMessage(section_services.serviceText_4)}</p>
                            {!!cmsRealEstate &&
                              <Button to={cmsRealEstate.path}>{intl.formatMessage(buttonsTranslations.know_more)}</Button>
                            }
                        </div>
                    </div>

                    {/* Location saisonière */}
                    <div>
                        <div className="service-icone">
                            <Icone_4/>
                        </div>
                        <h3>{intl.formatMessage(section_services.serviceTitle_5)}</h3>
                        <div>
                            <p>{intl.formatMessage(section_services.serviceText_5)}</p>
                            {!!cmsRentOut &&
                              <Button to={cmsRentOut.path}>{intl.formatMessage(buttonsTranslations.know_more)}</Button>
                            }
                        </div>
                    </div>

                    {/* Domiciliation */}
                    <div>
                        <div className="service-icone">
                            <Icone_5/>
                        </div>
                        <h3>{intl.formatMessage(section_services.serviceTitle_6)}</h3>
                        <div>
                            <p>{intl.formatMessage(section_services.serviceText_6)}</p>
                            {!!cmsDomiciliation &&
                              <Button to={cmsDomiciliation.path}>{intl.formatMessage(buttonsTranslations.know_more)}</Button>
                            }
                        </div>
                    </div>

                    {/* Gestion */}
                    <div>
                        <div className="service-icone">
                            <Icone_6/>
                        </div>
                        <h3>{intl.formatMessage(section_services.serviceTitle_7)}</h3>
                        <div>
                            <p>{intl.formatMessage(section_services.serviceText_1)}</p>
                            {!!cmsBuildingAdmin &&
                              <Button to={cmsBuildingAdmin.path}>{intl.formatMessage(buttonsTranslations.know_more)}</Button>
                            }
                        </div>
                    </div>
                </div>
                <img className="floating-img" src={Image_1} alt="Texte alternatif par défaut"/>
                <div className="floating-text">
                    <Red_arrow/>
                    <h4>Claire Berclaz</h4>
                </div>
            </section>

            {/* Newsletter */}
            <section className="section-newsletter">
                <div>
                    <h2>
                        {intl.formatMessage(section_newsletter.title)}<br/>
                        <span>{intl.formatMessage(section_newsletter.title_span)}</span>
                    </h2>
                    <p>{intl.formatMessage(section_newsletter.text)}</p>
                </div>
                <NewsletterForm/>
            </section>
        </>
    );
};

export default Home;
