import fetch from "isomorphic-fetch";
import {
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  createHttpLink,
  defaultDataIdFromObject,
  from,
  InMemoryCache
} from "@apollo/client";
import { AppConfig } from "@ct-react/core";
import { Locale } from "@ct-react/locale";

const buildConsumerMiddleWare = (channelId: string, locale?: Locale): ApolloLink => {
  return new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        "GQL-Consumer-Channel": channelId,
        ...(!!locale) && { "GQL-Consumer-Lang": locale.basename }
      }
    }));
    return forward(operation);
  });
}

const buildApolloClient = (ctx: AppConfig, ssrMode: boolean = false, locale?: Locale, restoringCache?: string) => {
  const cache = new InMemoryCache({
    dataIdFromObject: (responseObject) => {
      if (responseObject.__typename === "Cart") return `Cart:${responseObject.cartId}`;
      return defaultDataIdFromObject(responseObject);
    },
    possibleTypes: {
      AnyArticle: [
        "RentalAccommodationArticle",
        "SeasonalAccommodationArticle",
        "AnnualAccommodationArticle",
        "SaleAccommodationArticle"
      ]
    },
    typePolicies: {
      AnyArticle: {
        fields: {
          address: {
            merge: (existing, incoming) => ({ ...existing, ...incoming })
          },
          features: {
            merge: (existing, incoming) => ({ ...existing, ...incoming })
          }
        }
      },
      Image: {
        fields: {
          url: {
            // Garde les images en cache en utilisant l'URL comme identifiant
            merge: (existing, incoming) => incoming || existing
          }
        }
      }
    }
  });

  if (!!restoringCache) cache.restore(JSON.parse(restoringCache));

  return new ApolloClient({
    ssrMode,
    connectToDevTools: !ssrMode && ctx.env === "development",
    link: from([
      buildConsumerMiddleWare(ctx.gqlChannelId, locale),
      createHttpLink({ uri: ctx.gqlClientUrl, fetch })
    ]),
    cache
  } as unknown as ApolloClientOptions<InMemoryCache>);
}

export default buildApolloClient;
