import {defineMessages} from "react-intl";

export const main = defineMessages({
    text_1: {id: "privacy-policy-1", defaultMessage: "Collecte et utilisation des données personnelles"},
    text_2: {id: "privacy-policy-2", defaultMessage: "Les données personnelles collectées sur le site www.montanagence.ch (formulaires de contact, inscriptions, etc.) sont traitées conformément au RGPD (Règlement Général sur la Protection des Données) et aux législations applicables en Suisse. Les informations collectées sont utilisées uniquement pour :"},
    text_3: {id: "privacy-policy-3", defaultMessage: "La gestion des demandes de renseignements ou des transactions immobilières."},
    text_4: {id: "privacy-policy-4", defaultMessage: "L’envoi d’informations relatives aux services proposés par Montan'agence."},
    text_5: {id: "privacy-policy-5", defaultMessage: "La gestion des réservations ou des relations contractuelles."},
    text_6: {id: "privacy-policy-6", defaultMessage: "Vos données ne seront jamais vendues ou transmises à des tiers sans votre consentement préalable."},
    text_7: {id: "privacy-policy-7", defaultMessage: "Droits des utilisateurs"},
    text_8: {id: "privacy-policy-8", defaultMessage: "En tant qu'utilisateur, vous disposez des droits suivants sur vos données :"},
    text_9: {id: "privacy-policy-9", defaultMessage: "Droit d'accès : obtenir une copie de vos données personnelles."},
    text_10: {id: "privacy-policy-10", defaultMessage: "Droit de rectification : demander la correction d'informations erronées."},
    text_11: {id: "privacy-policy-11", defaultMessage: "Droit à l’effacement : demander la suppression de vos données."},
    text_12: {id: "privacy-policy-12", defaultMessage: "Droit à la limitation : restreindre leur traitement."},
    text_13: {id: "privacy-policy-13", defaultMessage: "Droit d’opposition : vous opposer à leur utilisation."},
    text_14: {id: "privacy-policy-14", defaultMessage: "Droit à la portabilité : recevoir vos données dans un format électronique structuré."},
    text_15: {id: "privacy-policy-15", defaultMessage: "Pour exercer vos droits, contactez-nous à l’adresse suivante : [gerance@montanagence.ch]."},
    text_16: {id: "privacy-policy-16", defaultMessage: "Sécurité des données"},
    text_17: {id: "privacy-policy-17", defaultMessage: "Montan'agence met en œuvre des mesures techniques et organisationnelles rigoureuses pour garantir la sécurité de vos données contre les accès non autorisés, pertes ou altérations."},
    text_18: {id: "privacy-policy-18", defaultMessage: "Cookies"},
    text_19: {id: "privacy-policy-19", defaultMessage: "Le site www.montanagence.ch utilise des cookies pour améliorer votre expérience utilisateur. Vous pouvez gérer vos préférences via les paramètres de votre navigateur ou refuser les cookies non essentiels via le bandeau de consentement affiché lors de votre première visite."},
    text_20: {id: "privacy-policy-20", defaultMessage: "Transferts internationaux de données"},
    text_21: {id: "privacy-policy-21", defaultMessage: "Si des données sont hébergées ou traitées en dehors de la Suisse, Montan'agence veille à ce que des garanties appropriées soient mises en place conformément aux réglementations locales et internationales. Pour toute question relative à la protection des données, veuillez nous écrire à : [gerance@montanagence.ch]."},
});
