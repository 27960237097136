import React from "react";
import { UpdateUser, User } from "@shared/models/user";
import { useLocalFetcher, usePostLocalFetcher } from "../../hooks/local-fetcher";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
import UserProfileForm from "../../components/forms/user-profile";

import { main } from "./translations";

const Profile = () => {

  const intl = useIntl();
  const { data, loading } = useLocalFetcher<User>("/user");
  const [ update ] = usePostLocalFetcher<UpdateUser, User>("/user");
  
  return loading ? null: (
    <>
      <Helmet>
        <title>{intl.formatMessage(main.title)}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="form-wrapper">
            <UserProfileForm user={data} update={update} />
      </div>
    </>
  );

};

export default Profile;
