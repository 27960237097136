import {defineMessages} from "react-intl";

export const main = defineMessages({
    title: {id: "fiscal-situation-title", defaultMessage: "Situation fiscale <s>en Valais</s>"},
    text: {id: "fiscal-situation-text", defaultMessage: "La situation fiscale en Valais compte parmi les plus favorables, notamment par l’absence d’impôt sur les successions en ligne directe, par une imposition très avantageuse et dégressive sur les gains immobiliers et par une imposition faible de la fortune."},
});

export const section_1 = defineMessages({
    title: {id: "fiscal-situation-section-1-title", defaultMessage: "Eléments <s>généraux</s>"},
    text_1: {id: "fiscal-situation-section-1-text-1", defaultMessage: "Une fois propriétaire de votre logement, votre imposition des personnes physiques est modifiée ; vous pouvez déduire les intérêts de votre dette, ainsi que différents frais liés à votre bien, mais devez déclarer la valeur locative de celui-ci. Cette dernière correspond à une estimation de ce que votre bien pourrait théoriquement vous rapporter si vous deviez décider de le louer à l’année."},
    text_2: {id: "fiscal-situation-section-1-text-2", defaultMessage: "Votre bien sera également déclaré pour l’impôt sur la fortune, tout en pouvant déduire la valeur de votre dette."},
    text_3: {id: "fiscal-situation-section-1-text-3", defaultMessage: "Par ailleurs, pour les ressortissant-e-s étrangers, il est possible de négocier une imposition à forfait. Ce type d’imposition n’est possible que sous certaines conditions et la valeur de votre bien immobilier sert alors de base de calcul pour cet impôt."},
    text_4: {id: "fiscal-situation-section-1-text-4", defaultMessage: "Différentes taxes communales (p.e. taxes de voirie, de séjour, de déblaiement des neiges), ainsi qu’un impôt foncier, doivent, selon les circonstances, être pris en compte."},
    text_5: {id: "fiscal-situation-section-1-text-5", defaultMessage: "Enfin, en cas d’achat et de revente d’un bien immobilier, un impôt sur les gains immobiliers est prélevé. Il se base sur la différence entre la valeur d’achat et la valeur de vente."},
});

export const section_2 = defineMessages({
    title: {id: "fiscal-situation-section-2-title", defaultMessage: "Financement <s>immobilier</s>"},
    text_1: {id: "fiscal-situation-section-2-text-1", defaultMessage: "Voici quelques notions vous permettant d’aborder la question du financement de votre bien immobilier :"},
    subtitle_1: {id: "fiscal-situation-section-2-subtitle-1", defaultMessage: "Fonds propres"},
    text_2: {id: "fiscal-situation-section-2-text-2", defaultMessage: "Pour l’achat d’une résidence secondaire et selon la banque choisie, les fonds propres doivent représenter au minimum 30% de la valeur du bien que vous souhaitez acquérir. Il s’agit de la somme que vous apportez personnellement (en argent ou en nature, p.e. par le biais de votre terrain) lors de l’emprunt hypothécaire. Dans le cas d’un achat en résidence principale, les fonds propres doivent représenter un minimum de 20%."},
    subtitle_2: {id: "fiscal-situation-section-2-subtitle-2", defaultMessage: "Hypothèque"},
    text_3: {id: "fiscal-situation-section-2-text-3", defaultMessage: "un emprunt bancaire passe par une hypothèque du bien que vous souhaitez acquérir. Cela signifie que votre bien « appartient » à la banque qui vous concède un prêt, jusqu’au moment où celui-ci aura été remboursé."},
    subtitle_3: {id: "fiscal-situation-section-2-subtitle-3", defaultMessage: "Amortissement"},
    text_4: {id: "fiscal-situation-section-2-text-4", defaultMessage: "l’amortissement correspond aux sommes que vous payez annuellement ou selon le rythme défini avec votre banque, afin de rembourser l’emprunt consenti pour l’achat de votre bien."},
    subtitle_4: {id: "fiscal-situation-section-2-subtitle-4", defaultMessage: "2e et 3e piliers de prévoyance vieillesse"},
    text_5: {id: "fiscal-situation-section-2-text-5", defaultMessage: "ces deux piliers de prévoyance, respectivement professionnelle et privée, peuvent être des aides utiles, notamment pour parvenir à réunir les 30% de fonds propres (résidence secondaire) ou 20% de fonds propres (résidence principale) nécessaires à l’acquisition d’un bien ou dans l’idée d’obtenir une hypothèque plus élevée. Votre caisse de pension sera à même de vous fournir tous les renseignements utiles et les différentes possibilités qui vous sont offertes."},
});

export const section_3 = defineMessages({
    title: {id: "fiscal-situation-section-3-title", defaultMessage: "Frais de <s>notaire</s>"},
    text_1: {id: "fiscal-situation-section-3-text-1", defaultMessage: "Il faut compter environ 3% du prix d’achat pour les frais de notariat. Cette somme inclut également les frais annexes, tels que les mutations ou les diverses inscriptions nécessaires au registre foncier."},
});