import React from "react";
import { useIntl } from "react-intl";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { faBed, faCamera, faLocationDot, faSquareParking, faWifi } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArticlePrice, CardArticleDiscount } from "../article/article";
import Skeleton from "react-loading-skeleton";
import ResponsiveImage from "../../utils/images";
import {AccommodationType, Image} from "../../../shared/models/article";
import { AccommodationBookingOption, AnyAccommodationFeatures, AnyArticle } from "../../models/search-param";
import { RentalAccommodationArticle, RentalAccommodationFeatures } from "../../models/article-rental";
import Icone_surface from "../../../assets/svg/icone-surface.svg";
import Icone_piece from "../../../assets/svg/icone-piece.svg";
import { SeasonalAccommodationFeatures } from "app/models/article-seasonal";
import { buildArticleUrl } from "@shared/models/urls";
import { LocaleLink } from "@ct-react/locale";

import { card_translations } from "./translations";
import "./card-article.scss";


type CardArticleEquipmentProps = {
    features: AnyAccommodationFeatures
    type: AccommodationType
};

type CardArticleProps = {
    data:AnyArticle,
    type: AccommodationType,
    bookingSuggestion?: AccommodationBookingOption | null,
    index: number
};

const CardArticleEquipment = ({
    features,
    type
}:CardArticleEquipmentProps) => {
    const intl = useIntl();

    if( type === AccommodationType.SALE || type === AccommodationType.ANNUAL){
        return(
            <div className="card-equipment sale">
                {features.parkings > 0 &&
                    <div className="card-icone">
                        <FontAwesomeIcon icon={faSquareParking}/>
                        {intl.formatMessage(card_translations.equipment_spaces, {count: features.parkings})}
                    </div>
                }
                {features.livingSpace > 0 &&
                    <div className="card-icone">
                        <Icone_surface/>
                        {`${features.livingSpace} m²`}
                    </div>
                }
                {features.rooms > 0 &&
                    <div className="card-icone">
                        <Icone_piece/>
                        {intl.formatMessage(card_translations.equipment_rooms, {count: features.rooms})}
                    </div>
                }
            </div>
        );
    }else{
        return(
            <div className="card-equipment rent">
                {features.parkings > 0 &&
                    <div className="card-icone">
                        <FontAwesomeIcon icon={faSquareParking}/>
                        {intl.formatMessage(card_translations.equipment_spaces, {count: features.parkings})}
                    </div>
                }
                {features.bedrooms > 0 &&
                    <div className="card-icone">
                        <FontAwesomeIcon icon={faBed}/>
                        {intl.formatMessage(card_translations.equipment_beds, {count: features.bedrooms})}
                    </div>
                }
                {(features as RentalAccommodationFeatures | SeasonalAccommodationFeatures).withWIFI &&
                    <div className="card-icone" data-gloss={intl.formatMessage(card_translations.equipment_wifi)}>
                        <FontAwesomeIcon icon={faWifi}/> Wifi
                    </div>
                }
            </div>
        );
    }
};

export const CardArticle = ({
    data,
    type,
    bookingSuggestion,
    index
}:CardArticleProps) => {
    const intl = useIntl();

    return(
        <article itemProp="itemListElement" itemScope itemType="https://schema.org/Offer" className="card">
            {/* <meta itemProp="url" content={buildArticleUrl(data.id, type)}/>
            <meta itemProp="category" content={typeForShema(type)}/> */}
            {data.images.length > 1 &&
            <meta itemProp="image" content={data.images[0].assets[0].url}/>
            }
            <meta itemProp="position" content={index?.toString()}/>

            <LocaleLink to={buildArticleUrl(data.slug, data.id, type)}/>

            <div className="chip">
                {data.recommended &&
                    <span className="recommended">
                        {intl.formatMessage(card_translations.recommended)}
                    </span>
                }
                {data.novelty &&
                    <span className="novelty">
                        {intl.formatMessage(card_translations.novelty)}
                    </span>
                }
                {data.promoted &&
                    <span className="promoted">
                        {intl.formatMessage(card_translations.promoted)}
                    </span>
                }
            </div>

            {type === AccommodationType.RENTAL &&
                <CardArticleDiscount discounts={(data as RentalAccommodationArticle).discounts}/>
            }

            <div className="card-carousel">
                { data.images.length > 0 ?
                    <Splide aria-label="Images" options={{
                        type:"slide",
                        height: "205px",
                        drag:false,
                        start:0,
                        pagination:false,
                        rewind:true,
                        classes: {
                            arrows: 'splide__arrows cardImage-arrowsBlock',
                            arrow : 'splide__arrow cardImage-arrow',
                        },

                    }}>
                        {data.images.map((img:Image, index:number)=>{
                            return(
                                <SplideSlide key={index}>
                                    <ResponsiveImage
                                        title={img.title}
                                        alternativeText={img.alternativeText}
                                        main={img.main}
                                        assets={img.assets}
                                        aspectRatio={img.aspectRatio}
                                        sizes="(max-width: 576px) 85.499vw, (max-width: 992px) 41.815vw, (max-width: 1200px) 40.346vw, 19.531vw"
                                />
                                </SplideSlide>
                            )
                        })}
                    </Splide>
                :
                    <div className="emptyImg">
                        <FontAwesomeIcon icon={faCamera}/>
                        <p>
                            {intl.formatMessage({id:"no-image", defaultMessage: "Aucune image disponible"})}
                        </p>
                    </div>
                }
            </div>

            <div className="card-info">
                <div className="card-location">
                    <FontAwesomeIcon icon={faLocationDot}/>
                    {data.address.city}
                </div>
                <h3 className="card-title">{data.title.value}</h3>
                <CardArticleEquipment features={data.features} type={type}/>
                <div className="card-price">
                    <ArticlePrice data={data} type={type} suggestion={bookingSuggestion}/>
                </div>
            </div>
        </article>
    );
};

export const CardArticleSkeleton = ({
})=>{
    return(
        <div className="card">
            <Skeleton className="card-carousel skeleton"/>
            <div className="card-info">
                <Skeleton width="50%"/>
                <Skeleton count={2} className="skeleton-card-title"/>
                <Skeleton/>
                <div className="card-price">
                    <Skeleton width="8rem"/>
                    <Skeleton className="skeleton-card-btn"/>
                </div>
            </div>
        </div>
    );
};
