import { DefaultContactFormData, DefaultNewsletterFormData } from "../../models/forms.data";
import { CartForm } from "../../models/cart";

export type Action={
    type:string;
    value:any;
}

const emailChain = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const textChain = /[^a-zéàèüûúùöôõóòäêâîïëçñA-ZÂÀÁÄÃÜÛÚÙÊËÉÈÔÓÒÖÕîïíì\s']/gmu;
const telChain = /[^+0-9]/gm;
const textareaChain = /script/gm;

export function contactFormReducer(formData:DefaultContactFormData, action:Action) {
    switch (action.type) {
        case ("reset"):{
            return(
                {
                    ...formData,
                    name:"",
                    nameValid:false,
                    surname:"",
                    surnameValid:false,
                    email:"",
                    emailValid:false,
                    tel:"",
                    telValid:false,
                    subject:"",
                    message:"",
                    messageValid:false,
                }
            )
        }
        case ("name"):{
            if(textChain.test(action.value)||action.value===""){
                return(
                    {
                        ...formData,
                        name:action.value,
                        nameValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        name:action.value,
                        nameValid:true,
                    }
                )
            }
        }
        case ("surname"):{
            if(textChain.test(action.value)||action.value===""){
                return(
                    {
                        ...formData,
                        surname:action.value,
                        surnameValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        surname:action.value,
                        surnameValid:true
                    }
                )
            }
        }
        case ("email"):{
            if(!emailChain.test(action.value)||action.value===""){
                return(
                    {
                        ...formData,
                        email:action.value,
                        emailValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        email:action.value,
                        emailValid:true
                    }
                )
            }
        }
        case ("tel"):{
            if(telChain.test(action.value)||action.value===""){
                return(
                    {
                        ...formData,
                        tel:action.value,
                        telValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        tel:action.value,
                        telValid:true,
                    }
                )
            }
        }
        case ("subject"):{
            return(
                    {
                        ...formData,
                        subject:action.value
                    }
                )
        }
        case ("message"):{
            if(textareaChain.test(action.value)||action.value===""){
                return(
                    {
                        ...formData,
                        message:action.value,
                        messageValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        message:action.value,
                        messageValid:true,
                    }
                )
            }
        }
        case ("contactType"):{
            return(
                {
                    ...formData,
                    contactType:action.value,
                }
            )
        }
        default:{
            throw Error('Unknown action: ' + action.type);
        }
    }
}

export function newsletterFormReducer(formData:DefaultNewsletterFormData, action:Action) {
    switch (action.type) {
        case ("name"):{
            if(textChain.test(action.value)||action.value===""){
                return(
                    {
                        ...formData,
                        name:action.value,
                        nameValid:false
                    }
                );
            }else{
                return(
                    {
                        ...formData,
                        name:action.value,
                        nameValid:true,
                    }
                );
            }
        }
        case ("email"):{
            if(!emailChain.test(action.value)||action.value===""){
                return(
                    {
                        ...formData,
                        email:action.value,
                        emailValid:false
                    }
                );
            }else{
                return(
                    {
                        ...formData,
                        email:action.value,
                        emailValid:true
                    }
                );
            }
        }
        default:{
            throw Error('Unknown action: ' + action.type);
        }
    }
}


export default function ProfileFormReducer(formData:CartForm, action:Action) {
    switch (action.type) {
        case ("initialize"):{
            if(!!action.value){
                return(
                    {
                        ...formData,
                        name:action.value.lastName,
                        nameValid:textChain.test(action.value.lastName) || action.value.lastName === "" ? false : true,
                        surname:action.value.firstName,
                        surnameValid:textChain.test(action.value.firstName) || action.value.firstName === "" ? false : true,
                        email:action.value.email,
                        emailValid: !emailChain.test(action.value.email) || action.value.email === "" ? false : true,
                        tel:action.value.phone,
                        telValid: telChain.test(action.value.phone) ? false : true,
                        street: action.value.address.street,
                        streetValid: textChain.test(action.value.address.street) || action.value.address.street === "" ? false : true,
                        streetNbr:action.value.address.streetNumber,
                        complementaryAdress:action.value.address.streetComplement,
                        npa: action.value.address.zip,
                        npaValid: telChain.test(action.value.address.zip) || action.value.address.zip === "" ? false : true,
                        city: action.value.address.city,
                        cityValid: textChain.test(action.value.address.city) || action.value.address.city === "" ? false : true,
                        country:action.value.address.country,
                        countryValid:action.value.address.country === "" ? false : true
                    }
                )
            }
        };
        case ("name"):{
            if(textChain.test(action.value) || action.value === ""){
                return(
                    {
                        ...formData,
                        name:action.value,
                        nameValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        name:action.value,
                        nameValid:true,
                    }
                )
            }
        };
        case ("surname"):{
            if(textChain.test(action.value) || action.value === ""){
                return(
                    {
                        ...formData,
                        surname:action.value,
                        surnameValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        surname:action.value,
                        surnameValid:true
                    }
                )
            }
        };
        case ("email"):{
            if(!emailChain.test(action.value) || action.value === ""){
                return(
                    {
                        ...formData,
                        email:action.value,
                        emailValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        email:action.value,
                        emailValid:true
                    }
                )
            }
        };
        case ("tel"):{
            if(telChain.test(action.value)){
                return(
                    {
                        ...formData,
                        tel:action.value,
                        telValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        tel:action.value,
                        telValid:true,
                    }
                )
            }
        };
        case ("street"):{
            if(textChain.test(action.value) || action.value === ""){
                return(
                    {
                        ...formData,
                        street:action.value,
                        streetValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        street:action.value,
                        streetValid:true,
                    }
                )
            }
        };
        case ("streetNbr"):{
            return(
                {
                    ...formData,
                    streetNbr:action.value,
                }
            )
        };
        case ("complementaryAdress"):{
            return(
                {
                    ...formData,
                    complementaryAdress:action.value,
                }
            )
        };
        case ("npa"):{
            if(telChain.test(action.value) || action.value === ""){
                return(
                    {
                        ...formData,
                        npa:action.value,
                        npaValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        npa:action.value,
                        npaValid:true,
                    }
                )
            }
        };
        case ("city"):{
            if(textChain.test(action.value) || action.value === ""){
                return(
                    {
                        ...formData,
                        city:action.value,
                        cityValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        city:action.value,
                        cityValid:true,
                    }
                )
            }
        };
        case ("country"):{
            if(action.value === ""){
                return(
                    {
                        ...formData,
                        country:action.value,
                        countryValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        country:action.value,
                        countryValid:true,
                    }
                )
            }
        };
        default:{
            throw Error('Unknown action: ' + action.type);
        };
    }
}

