import {defineMessages} from "react-intl";

export const booking_box = defineMessages({
    select_arrival: {id:"calendar-select-arrival", defaultMessage: "Sélectionnez la date d'arrivée"},
    select_departure: {id:"calendar-select-departure", defaultMessage: "Sélectionnez la date de départ"},
    delete_dates: {id:"delete-dates", defaultMessage: "Effacer les dates"},
    add_guests: {id:"add-guests", defaultMessage: "Ajouter le nombre de personne"},
    nbr_adult: {id:"nbr-of-adult", defaultMessage: "{adult ,plural, =1 { # adulte } other { # adultes }}"},
    nbr_child: {id:"nbr-of-child", defaultMessage: "{child ,plural, =1 { # enfant } other { # enfants }}"},
    nbr_exempt: {id:"nbr-of-exempt", defaultMessage: "{exempt ,plural, =1 { # exonéré } other { # exonérés }}"},
    total_price_nights: {id: "total-price-nights", defaultMessage: "Prix pour {night, plural, =1 {# nuit} other {# nuits}}"},
    taxes: {id:"taxes", defaultMessage: "Taxes de séjour"},
    choose_your_dates: {id:"choose-your-dates", defaultMessage: "Choisissez vos dates pour afficher les prix"},
    add_persons: {id:"add-persons", defaultMessage: "Ajoutez des participants"},
    select_persons: {id:"select-participate", defaultMessage: "Séléctionnez des participants"},
    maxGuests_message: {id: "maxGuest-message", defaultMessage: "{max} personnes maximum autorisé dans cet établissement"},
    form_callback_article_add_succeded: {id:"form-callback-article-add-succeded",defaultMessage : "L'article a bien été ajouté à votre panier !"},
    form_callback_article_add_failed: {id:"form-callback-article-add-failed",defaultMessage : "L'article n'a pas pu être ajouté au panier"},
    form_callback_article_continue: {id:"form-callback-article-continue",defaultMessage : "Continuer mes achats"},
    form_callback_article_goToCart: {id:"form-callback-article-goToCart",defaultMessage : "Aller au panier"},
    reduction: {id:"reduction",defaultMessage : "Réduction"},
});
