
export class DefaultContactFormData{
    name:string;
    nameValid:boolean;
    surname:string;
    surnameValid:boolean;
    email:string;
    emailValid:boolean;
    tel:string;
    telValid:boolean;
    subject:string;
    message:string;
    messageValid:boolean;
    contactType:"email"|"phone";

    constructor(
        name:string = "",
        nameValid:boolean=false,
        surname:string = "",
        surnameValid:boolean=false,
        email:string = "",
        emailValid:boolean=false,
        tel:string = "",
        telvalid:boolean=false,
        subject:string = "Acheter",
        message:string = "",
        messageValid:boolean=false,
        contactType:"email"|"phone" = "email"
    ){
        this.name = name;
        this.nameValid = nameValid;
        this.surname = surname;
        this.surnameValid = surnameValid;
        this.email = email;
        this.emailValid = emailValid;
        this.tel = tel;
        this.telValid = telvalid;
        this.subject = subject;
        this.message = message;
        this.messageValid = messageValid;
        this.contactType = contactType
    }
};

export class DefaultNewsletterFormData{
    name:string;
    nameValid:boolean;
    email:string;
    emailValid:boolean;
    constructor(
        name:string = "",
        nameValid:boolean=false,
        email:string = "",
        emailValid:boolean=false,
    ){
        this.name = name;
        this.nameValid = nameValid;
        this.email = email;
        this.emailValid = emailValid;
    }
};