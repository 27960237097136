import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useMatch, useNavigate } from "react-router";
import { useIntl } from "react-intl";
import gsap from "gsap";
import { classNames, isValidPeriod, Periodable } from "@ct-react/core";
import SubInput from "./subInput";
import { useQuery } from "@apollo/client";
import { useSearchContext } from "../../contexts/search-module";
import { useScrollLock } from "../../contexts/lockScroll";
import { AccommodationType, ObjectTypeRef, Period } from "@shared/models/article";
import { useSearchParams } from "react-router-dom";
import {
  faCircleDot,
  faDog,
  faLadderWater,
  faMagnifyingGlass,
  faSquareParking,
  faWifi,
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { generateSearchArticleParamData, OBJECT_QUERY, ResultObjectRef } from "./search-modules-config";
import { useLocaleContext, useLocaleFormatter } from "@ct-react/locale";
import moment, { Moment } from "moment";
import { RangePickerController, useDefaultCalendarBlock } from "@ct-react/calendar";
import { useDisplayUntilDesktop } from "../../utils/breakpoints";
import { formatPrice } from "../../utils/price";
import Guests from "../../components/inputs/guests";
import NbrGuests from "../../models/guests";

import { search_module } from "./translations";
import {
  bookingTranslations,
  buttonsTranslations,
  genericTermTranslations,
  globalTranslations
} from "../../i18n/sharable-defs";
import "./search-module.scss";

import Chevron_down from "../../../assets/svg/chevron-down-solid.svg";
import Arrow_right from "../../../assets/svg/custom_arrow_down.svg";
import SkiProximity from "../../../assets/svg/icone-ski.svg";
import { usePickerLabelize } from "../../hooks/calendar";


const SearchModule = ({
}) => {
    const {
        searchCriteria,
        setArticleType,
        setObjectTypes,
        setPriceRange,
        setRoomsRange,
        setGuests,
        setPeriod,
        setSurface,
        setFeatures
    } = useSearchContext();
    const { lockScroll, unlockScroll } = useScrollLock();
    const intl = useIntl();
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { locale } = useLocaleContext();
    const isMobile = useDisplayUntilDesktop();
    const { print, transLib } = useLocaleFormatter();
    const calendarLabelize = usePickerLabelize();

    //Current page detection
    const isHomePage = useMatch(`${locale.basename}/`);
    const isOnRentalPage = useMatch(`${locale.basename}/location/vacances`);
    const isOnSeasonalPage = useMatch(`${locale.basename}/location/saison`);
    const isOnAnnualPage = useMatch(`${locale.basename}/location/annee`);
    const isOnSalePage = useMatch(`${locale.basename}/vente`);

    //Module position
    const [modulePosition, setModulePosition] = useState<"down"|"up">("down");
    const [scrollY, setScrollY] = useState(0);

    //Smartphone
    const [smartphoneFilterModuleVisible, setSmartphoneFilterModuleVisible] = useState<boolean>(false);
    const [smartphoneSearchModuleSticky, setSmartphoneSearchModuleSticky] = useState<boolean>(false);
    const [smartphoneTypeSearchShow, setSmartphoneTypeSearchShow] = useState<boolean>(false);
    const [smartphoneObjectTypeShow, setSmartphoneObjectTypeShow] = useState<boolean>(false);
    const [smartphonePriceRangeShow, setSmartphonePriceRangeShow] = useState<boolean>(false);
    const [smartphoneCalendarShow, setSmartphoneCalendarShow] = useState<boolean>(false);
    const [smartphoneRoomRangeShow, setSmartphoneRoomRangeShow] = useState<boolean>(false);
    const [smartphoneSurfaceShow, setSmartphoneSurfaceShow] = useState<boolean>(false);
    const [smartphoneGuestsShow, setSmartphoneGuestsShow] = useState<boolean>(false);
    const [smartphoneEquipmentShow, setSmartphoneEquipmentShow] = useState<boolean>(false);

    //Initial Object type
    const [initialObjectTypes, setInitialObjectTypes] = useState<[ObjectTypeRef] | []>([]);

    //Price range
    const [priceColorRange, setPriceColorRange] = useState<string>("linear-gradient(to right, #EFEBE3 0%, #F4161D 0%, #F4161D 100%, #EFEBE3 100%)");
    const [spanMinPricePosition, setSpanMinPricePosition] = useState<string>("0");
    const [spanMaxPricePosition, setSpanMaxPricePosition] = useState<string>("6em");

    //Room range
    const [roomsRangeColorRange, setRoomsRangeColorRange] = useState<string>("linear-gradient(to right, #EFEBE3 0%, #F4161D 0%, #F4161D 100%, #EFEBE3 100%)");
    const [spanMinRoomPosition, setSpanMinRoomPosition] = useState<string>("0");
    const [spanMaxRoomPosition, setSpanMaxRoomPosition] = useState<string>("18em");

    //Surface
    const [surfaceColorRange, setSurfaceColorRange] = useState<string>("linear-gradient(to right, #EFEBE3 0%, #F4161D 0%, #F4161D 50%, #EFEBE3 50%)");
    const [spanMinSurfacePosition, setSpanMinSurfacePosition] = useState<string>("0");

    //Calendar
    const {
        range: [ pickerRange, setPickerRange ],
        focus: [ pickerFocus, setPickerFocus ],
    } = useDefaultCalendarBlock();

    //TYPE OF SEARCH
    //________________________________________________________

    const locationChoiceTitle = useMemo( () => {
        if(searchCriteria.articleType === AccommodationType.RENTAL){
            return intl.formatMessage(genericTermTranslations.locationHolidays);
        }else if(searchCriteria.articleType === AccommodationType.SEASONAL){
            return intl.formatMessage(genericTermTranslations.locationSeasonal);
        }else if(searchCriteria.articleType === AccommodationType.ANNUAL){
            return intl.formatMessage(genericTermTranslations.locationYearly);
        }else {
            return intl.formatMessage(genericTermTranslations.rent);
        }
    },[searchCriteria.articleType, locale]);

    const handleTypeOfSearch = (e: React.MouseEvent<HTMLButtonElement> | React.ChangeEvent<HTMLInputElement>) => {
        if(!!e.currentTarget){
            refetch();
            const target = e.currentTarget.id as unknown as AccommodationType;
            setArticleType(target);
            setPickerFocus("start");
            setPickerRange({
                start: undefined,
                end: undefined
            } as  Periodable<Moment>);
        }
        setSmartphoneTypeSearchShow(false);
    };

    useEffect(() => { //Update type corresponding to page
        let type;
        if(location.pathname.includes("location/vacances")) type = AccommodationType.RENTAL;
        if(location.pathname.includes("location/saison")) type = AccommodationType.SEASONAL;
        if(location.pathname.includes("location/annee")) type = AccommodationType.ANNUAL;
        if(location.pathname.includes("vente"))type = AccommodationType.SALE;

        if (type === searchCriteria.articleType || type === undefined) return; //Already on same type so it does not reset module or if on another page

        setArticleType(type);
        setPickerFocus("start");
        setPickerRange({
            start: undefined,
            end: undefined
        } as  Periodable<Moment>);
    },[location]);

    //OBJECT TYPE
    //________________________________________________________

    const { loading, refetch } = useQuery(OBJECT_QUERY, {
        ssr: false,
        variables: {
            accommodationType: searchCriteria.articleType || AccommodationType.RENTAL
        },
        onCompleted: (data: ResultObjectRef) => {
            if (data?.searchableAccommodationObjectTypes) {
                setInitialObjectTypes(data?.searchableAccommodationObjectTypes);
            }

        }
    });

    const handleObjectType = (e: React.ChangeEvent<HTMLInputElement>) => {
        let nextArray;
        const checked = e.target.checked;
        const name = e.target.name;
        const id = e.target.value;

        if(checked){//add
            nextArray = [...searchCriteria.objectTypes, {name: name, id: parseInt(id)} as unknown as ObjectTypeRef];
        }else{//remove
            nextArray = [...searchCriteria.objectTypes.filter( (type:ObjectTypeRef) => type.name !== name )]
        }

        setObjectTypes(nextArray);
    };

    const renderObjectTypes = useMemo( () => {
        if(searchCriteria.objectTypes === null || !initialObjectTypes) return null;
        return (
            initialObjectTypes.map( (type: ObjectTypeRef) => {
                const selectedNames = new Set(searchCriteria.objectTypes.map((obj: ObjectTypeRef) => obj.name));

                return(
                    <label htmlFor={type.name} key={type.name}>
                    <input
                        type="checkbox"
                        id={type.name}
                        value={type.id}
                        name={type.name}
                        checked={selectedNames.has(type.name)}
                        onChange={handleObjectType}
                    />
                        {transLib.match("objectTypes", type.id)}
                    </label>
                );
            })
        );
    },[searchCriteria.objectTypes, initialObjectTypes, searchParams, location]);

    //PRICE RANGE
    //________________________________________________________

    const handleMinPrice = (e: React.ChangeEvent<HTMLInputElement>)=>{
        const max = !!searchCriteria.priceRange ? searchCriteria.priceRange[1] : searchCriteria.priceLimits[1];
        const value = parseInt(e.currentTarget.value);
        setPriceRange([value, max]);
    };

    const handleMaxPrice =(e: React.ChangeEvent<HTMLInputElement>)=>{
        const min = !!searchCriteria.priceRange ? searchCriteria.priceRange[0] : searchCriteria.priceLimits[0];
        const value = parseInt(e.currentTarget.value);
        setPriceRange([min, value]);
    };

    const handlePriceRangeInputUI = useCallback( () => {
        let percent1 = !!searchCriteria.priceRange ? (searchCriteria.priceRange[0] / searchCriteria.priceLimits![1]) * 100 : 0;
        let percent2 = !!searchCriteria.priceRange ? (searchCriteria.priceRange[1] / searchCriteria.priceLimits![1]) * 100 : 100;
        setPriceColorRange(`linear-gradient(to right, #EFEBE3 ${percent1}% , #F4161D ${percent1}% , #F4161D ${percent2}%, #EFEBE3 ${percent2}%)`);
        let left1 = percent1 < 1 ? 0 : (percent1 / 10) * 0.8 - 1;
        let left2 = (percent2 / 10 ) * 1.2 + 1;
        setSpanMinPricePosition(`${left1.toString()}em`);
        setSpanMaxPricePosition(`${left2.toString()}em`);
    },[searchCriteria.priceRange]);

    useEffect( () => {//Handle input price range UI
        handlePriceRangeInputUI();
    },[searchCriteria.priceRange]);

    const formatModulePrice = (value: number): any => {
        return formatPrice(value);
    };

    const renderStep = () => {
        if(searchCriteria.articleType === AccommodationType.SALE && !!searchCriteria.priceRange){
            if(searchCriteria.priceRange[1] >= 5000000) return 1000000;
            if(searchCriteria.priceRange[1] < 5000000) return 100000;
        }else{
            return 500;
        }
    };

    //CALENDAR
    //________________________________________________________

    useEffect( () => {//Set period on picker change
        const formattedRange: Period<Moment> = {
            start: pickerRange.start,
            end: pickerRange.end
        };
        setPeriod(formattedRange || {start: undefined, end: undefined});
    },[pickerFocus]);

    const nights = () => {
        const nightsCount = searchCriteria.period.end! && searchCriteria.period.end.diff(searchCriteria.period.start,"days")
        return intl.formatMessage(bookingTranslations.nightsPeriod,{nightsCount});
    };

    //ROOM RANGE
    //________________________________________________________

    const handleRoomsRangeMin = ( e: React.ChangeEvent<HTMLInputElement>) => {
        const max = !!searchCriteria.roomsRange? searchCriteria.roomsRange[1] : 6;
        const value = parseInt(e.currentTarget.value);
        setRoomsRange([value, max]);
    };

    const handleRoomsRangeMax = ( e: React.ChangeEvent<HTMLInputElement>) => {
        const min = !!searchCriteria.roomsRange? searchCriteria.roomsRange[0] : 0;
        const value = parseInt(e.currentTarget.value);
        setRoomsRange([min, value]);
    };

    const handleRoomsRangeInputUI = useCallback( () => {
        const max = !!searchCriteria.roomsRange? searchCriteria.roomsRange[1] : 6;
        const min = !!searchCriteria.roomsRange? searchCriteria.roomsRange[0] : 0;

        let percent1 = ((min - 1) / 5) * 100;
        let percent2 = ((max - 1) / 5) * 100;
        setRoomsRangeColorRange(`linear-gradient(to right, #EFEBE3 ${percent1}% , #F4161D ${percent1}% , #F4161D ${percent2}%, #EFEBE3 ${percent2}%)`);
        let left1 = percent1 < 1 ? 0 : (percent1/10) * 1.6 + 1;
        let left2 = (percent2 / 10) * 1.6 + 1;
        setSpanMinRoomPosition(`${left1.toString()}em`);
        setSpanMaxRoomPosition(`${left2.toString()}em`);
    },[searchCriteria.roomsRange]);

    useEffect( () => {//Handle input rooms range UI
        handleRoomsRangeInputUI();
    },[searchCriteria.roomsRange]);

    const showRoom = (value) => {
        if(value <= 1) return "Studio";
        if(value === 6) return "6+";
        return value;
    };

    //SURFACE
    //________________________________________________________

    const handleSurface = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.currentTarget.value);
        setSurface(value);
    };

    const handleSurfaceRangeInputUI = () => {
        let percent = ((searchCriteria.surface || 0) / 250) * 100;
        setSurfaceColorRange(`linear-gradient(to right, #EFEBE3 0% , #F4161D 0% , #F4161D ${percent}%, #EFEBE3 ${percent}%)`);
        let left = (percent / 10) * 1.3;
        setSpanMinSurfacePosition(`${left.toString()}em`);
    };

    useEffect( () => {
        handleSurfaceRangeInputUI();
    },[searchCriteria.surface]);

    //GUESTS
    //________________________________________________________

    const renderGuestsQuantity = useMemo(() => {
        const total = searchCriteria.guests.adult + searchCriteria.guests.child + searchCriteria.guests.exempt;
        return intl.formatMessage(bookingTranslations.nbrOfGuests, {total});
    },[searchCriteria.guests]);

    //FEATURES - EQUIPMENT

    const handleFeatures = (e: React.ChangeEvent<HTMLInputElement>) => {
        let nextArray;
        const checked = e.target.checked;
        const value = e.target.value;
        if(checked){//add
            nextArray = [...searchCriteria.features, value];
        }else{//remove
            nextArray = [...searchCriteria.features.filter( (f:string) => f !== value )]
        }
        setFeatures(nextArray);
    };

    const renderEquipment = useMemo(() => {
        return(
            <div className="equipment">
                <label htmlFor="parking">
                    <input
                        type="checkbox"
                        value="parking"
                        id="parking"
                        name="parking"
                        onChange={handleFeatures}
                        checked={(searchCriteria.features).includes("parking")}
                        />
                    <FontAwesomeIcon icon={faSquareParking}/>
                </label>
                <label htmlFor="wifi">
                    <input
                        type="checkbox"
                        value="wifi"
                        id="wifi"
                        name="wifi"
                        onChange={handleFeatures}
                        checked={(searchCriteria.features).includes("wifi")}
                        />
                    <FontAwesomeIcon icon={faWifi}/>
                </label>
                <label htmlFor="pet">
                    <input
                        type="checkbox"
                        value="pet"
                        id="pet"
                        name="pet"
                        onChange={handleFeatures}
                        checked={(searchCriteria.features).includes("pet")}
                        />
                    <FontAwesomeIcon icon={faDog}/>
                </label>
                <label htmlFor="pool">
                    <input
                        type="checkbox"
                        value="pool"
                        id="pool"
                        name="pool"
                        onChange={handleFeatures}
                        checked={(searchCriteria.features).includes("pool")}
                        />
                    <FontAwesomeIcon icon={faLadderWater}/>
                </label>
                <label htmlFor="cityCenter">
                    <input
                        type="checkbox"
                        value="cityCenter"
                        id="cityCenter"
                        name="cityCenter"
                        onChange={handleFeatures}
                        checked={(searchCriteria.features).includes("cityCenter")}
                        />
                    <FontAwesomeIcon icon={faCircleDot}/>
                </label>
                <label htmlFor="skiArea">
                    <input
                        type="checkbox"
                        value="skiArea"
                        id="skiArea"
                        name="skiArea"
                        onChange={handleFeatures}
                        checked={(searchCriteria.features).includes("skiArea")}
                        />
                    <SkiProximity/>
                </label>
            </div>
        );
    },[searchCriteria.features]);

    //FORM HANDLING
    //________________________________________________________

    const submitSearchData = () => {
        const paramArrays = generateSearchArticleParamData(searchCriteria);

        const path = () => {
            switch (searchCriteria.articleType) {
              case AccommodationType.RENTAL:
                return "/location/vacances";
              case AccommodationType.SEASONAL:
                return "/location/saison";
              case AccommodationType.ANNUAL:
                return "/location/annee";
              case AccommodationType.SALE:
                return "/vente";
              default:
                return "/location/vacances";
            }
        };

        isMobile && setSmartphoneFilterModuleVisible(false); //Close filter module

        navigate(`${locale.basename}${path()}?${paramArrays}`,{
            state: { fromSearch: true },
            replace: true
        });
    };

    //ANIMATION
    //________________________________________________________

    const searchModule= useRef<HTMLDivElement>(null);

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {//Handle up or down searchmodule position
        if(isHomePage) {
            window.addEventListener('scroll', handleScroll);
            setModulePosition("down")
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }else{
            setModulePosition("up")
        }
    }, [isHomePage]);

    useEffect(() => { //Handle position of Search Module with gsap
        if(isMobile) return;
        if (scrollY > 200 && modulePosition === "down") {
            gsap.to(searchModule.current,{
                className:"searchModule up",
                ease:"power1",
                duration:0.5,
                onComplete : () => setModulePosition("up"),
            });
        }else if (scrollY < 200 && modulePosition === "up"){
            gsap.to(searchModule.current,{
                className:"searchModule down",
                ease:"power1",
                duration:0.5,
                onComplete : () => setModulePosition("down"),
            });
        }
    }, [scrollY]);

    //SMARTPHONE
    //________________________________________________________

    const resetFilterModule = () => { //Reset the whole filter module
        setObjectTypes(initialObjectTypes);
        setPriceRange(null);
        setRoomsRange(null);
        setGuests(new NbrGuests());
        setPeriod({start: undefined, end: undefined});
        setPickerRange({});
        setPickerFocus("start");
        setSurface(0);
        setFeatures([]);
    };

    const renderSearchText = useMemo(() => {
        if(searchCriteria.articleType === AccommodationType.RENTAL) return intl.formatMessage(search_module.search_module_search_rental_text);
        if(searchCriteria.articleType === AccommodationType.SEASONAL) return intl.formatMessage(search_module.search_module_search_seasonal_text);
        if(searchCriteria.articleType === AccommodationType.ANNUAL) return intl.formatMessage(search_module.search_module_search_annual_text);
        if(searchCriteria.articleType === AccommodationType.SALE) return intl.formatMessage(search_module.search_module_search_sale_text);
    },[searchCriteria.articleType]);

    const handleFilerModuleInputsClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => { //Open corresponding inputs and close already open
        const type = e.currentTarget.dataset.type;
        const currentlyOpen = document.querySelector(".search-module-button.show");
        !!currentlyOpen && currentlyOpen.classList.remove("show");
        if (type === null) return;
        type === "objectType" && setSmartphoneObjectTypeShow(!smartphoneObjectTypeShow);
        type === "priceRange" && setSmartphonePriceRangeShow(!smartphonePriceRangeShow);
        type === "calendar" && setSmartphoneCalendarShow(!smartphoneCalendarShow);
        type === "roomRange" && setSmartphoneRoomRangeShow(!smartphoneRoomRangeShow);
        type === "surface" && setSmartphoneSurfaceShow(!smartphoneSurfaceShow);
        type === "guests" && setSmartphoneGuestsShow(!smartphoneGuestsShow);
        type === "equipment" && setSmartphoneEquipmentShow(!smartphoneEquipmentShow);
    };

    useEffect(() => { //Set search module smartphone to position sticky when on search page
        const isOnSearchPage = isOnRentalPage || isOnSeasonalPage || isOnAnnualPage || isOnSalePage;
        if(isOnSearchPage){
            setSmartphoneSearchModuleSticky(true);
        }else{
            setSmartphoneSearchModuleSticky(false);
        }
    },[location]);

    const renderGuestsQuantitySmartPhone = useMemo(() => {
        const total = searchCriteria.guests.adult + searchCriteria.guests.child + searchCriteria.guests.exempt;
        return intl.formatMessage(bookingTranslations.nbrOfGuests_smartphone, {total});
    },[searchCriteria.guests]);

    useEffect(() => {
        smartphoneFilterModuleVisible && lockScroll();
        !smartphoneFilterModuleVisible && unlockScroll();
    },[smartphoneFilterModuleVisible])


    if(isMobile){ //Smartphone search module and Filte module
        return (
        <>
            <div ref={searchModule} className={classNames(`${modulePosition} searchModule`,{
                sticky: smartphoneSearchModuleSticky,
                hide: !!searchParams.get("refId") || location.pathname.includes("checkout")
            })}>
                <div className="container">
                    {/*Type of search*/}
                    <div className="search-type-button">
                        <div
                            id="location"
                            className={ classNames("search-module-button", {
                                red: searchCriteria.articleType != AccommodationType.SALE,
                                show: smartphoneTypeSearchShow
                            })}
                            onClick={() => setSmartphoneTypeSearchShow(true)}
                        >
                            {locationChoiceTitle}
                            <Chevron_down/>
                            <SubInput
                                onReset={() => setArticleType(AccommodationType.RENTAL)}
                                key="typeOfSearch"
                                type="typeOfSearch"
                                title={intl.formatMessage({ id: "search-rent-type-title", defaultMessage: "Quelle location désirez-vous ?" })}
                                modulePosition={modulePosition}
                                className="type"
                            >
                                <label htmlFor="RENTAL">
                                    <input
                                        type="radio"
                                        id="RENTAL"
                                        value="RENTAL"
                                        name="locationBrowser"
                                        checked={searchCriteria.articleType === AccommodationType.RENTAL ? true : false}
                                        onChange={handleTypeOfSearch}
                                    />
                                    {intl.formatMessage(genericTermTranslations.locationHolidays)}
                                </label>
                                <label htmlFor="SEASONAL">
                                    <input
                                        type="radio"
                                        id="SEASONAL"
                                        value="SEASONAL"
                                        name="locationBrowser"
                                        checked={searchCriteria.articleType === AccommodationType.SEASONAL ? true : false}
                                        onChange={handleTypeOfSearch}
                                    />
                                    {intl.formatMessage(genericTermTranslations.locationSeasonal)}
                                </label>
                                <label htmlFor="ANNUAL">
                                    <input
                                        type="radio"
                                        id="ANNUAL"
                                        value="ANNUAL"
                                        name="locationBrowser"
                                        checked={searchCriteria.articleType === AccommodationType.ANNUAL ? true : false}
                                        onChange={handleTypeOfSearch}
                                    />
                                    {intl.formatMessage(genericTermTranslations.locationYearly)}
                                </label>
                            </SubInput>
                        </div>
                        <button
                            id="SALE"
                            type="button"
                            className={classNames("search-module-button",{ red: searchCriteria.articleType === AccommodationType.SALE })}
                            onClick={handleTypeOfSearch}
                        >
                            {intl.formatMessage(genericTermTranslations.sale)}
                        </button>
                    </div>
                    <div className="search search-module-button" onClick={() => setSmartphoneFilterModuleVisible(true)}>
                        <FontAwesomeIcon icon={faMagnifyingGlass}/>
                        {renderSearchText}
                    </div>
                </div>
            </div>
            <div className={classNames("filter-module", {show: smartphoneFilterModuleVisible})}>
                <div className="filter-module-header">
                    <h3>{searchCriteria.articleType != AccommodationType.SALE ?
                        locationChoiceTitle
                        :
                        intl.formatMessage(genericTermTranslations.sale)
                    }</h3>
                    <FontAwesomeIcon icon={faXmark} onClick={() => setSmartphoneFilterModuleVisible(false)}/>
                </div>
                <div className="filter-module-inputs">
                    {/*ObjectType*/}
                    <div className={classNames("search-module-button", {show: smartphoneObjectTypeShow})}>
                        <div data-type="objectType" onClick={(e) => handleFilerModuleInputsClick(e)}>
                            <h6>{intl.formatMessage(search_module.search_module_object_type)}</h6>
                            {
                                searchCriteria.objectTypes.length > 0 &&
                                intl.formatMessage(search_module.search_module_type_quantity, {types: searchCriteria.objectTypes.length})
                            }
                            <Chevron_down/>
                        </div>
                        <SubInput
                                className={"objectType"}
                                onReset={() => setObjectTypes(initialObjectTypes)}
                                key="typeOfArticle"
                                type="typeOfArticle"
                                title={intl.formatMessage({id:"search-module-choose-guests-type", defaultMessage: "Quel(s) type(s) de bien ?"})}
                                modulePosition={modulePosition}
                                inFilterModule={true}
                            >
                                {renderObjectTypes}
                        </SubInput>
                    </div>

                    {/* Prix */}
                    <div className={classNames("search-module-button", {
                        show: smartphonePriceRangeShow,
                        hide: searchCriteria.articleType === AccommodationType.RENTAL
                    })}>
                        <div data-type="priceRange" onClick={(e) => handleFilerModuleInputsClick(e)}>
                            <h6>{intl.formatMessage(search_module.search_module_range_price)}</h6>
                            {!!searchCriteria.priceRange &&
                            <>
                                {formatModulePrice(searchCriteria.priceRange[0])} - {formatModulePrice(searchCriteria.priceRange[1])}
                            </>
                            }
                            <Chevron_down/>
                        </div>
                        <SubInput
                            onReset={() => setPriceRange(null)}
                            key="price"
                            type="price"
                            title={intl.formatMessage({id:"searchmodule-choose-price",defaultMessage: "Quel prix désirez-vous ?"})}
                            modulePosition={modulePosition}
                        >
                            <label className="rangeLabel">
                                <div className="inputRangeColor" style={!!priceColorRange ? {background:priceColorRange} : {}}></div>
                                <input
                                    name="minPrice"
                                    type="range"
                                    step={renderStep()}
                                    value={!!searchCriteria.priceRange ? searchCriteria.priceRange[0] : 0}
                                    min={0}
                                    max={searchCriteria.priceLimits![1]}
                                    onChange={handleMinPrice}
                                />
                                <input
                                    name="maxPrice"
                                    type="range"
                                    step={renderStep()}
                                    value={!!searchCriteria.priceRange? searchCriteria.priceRange[1] : searchCriteria.priceLimits[1]}
                                    min={0}
                                    max={searchCriteria.priceLimits![1]}
                                    onChange={handleMaxPrice}
                                />
                                <span style={{left:spanMinPricePosition}}>
                                    {`CHF ${formatModulePrice(!!searchCriteria.priceRange? searchCriteria.priceRange[0] : searchCriteria.priceLimits[0])}`}
                                </span>
                                <span style={{left:spanMaxPricePosition}}>
                                    {`CHF ${formatModulePrice(!!searchCriteria.priceRange? searchCriteria.priceRange[1] : searchCriteria.priceLimits[1])}`}
                                </span>
                            </label>
                        </SubInput>
                    </div>

                    {/* Calendar */}
                    <div className={classNames("search-module-button calendar", {
                        hide: searchCriteria.articleType != AccommodationType.RENTAL,
                        show: smartphoneCalendarShow
                        })}>
                            <div data-type="calendar" onClick={(e) => handleFilerModuleInputsClick(e)}>
                                <h6>{intl.formatMessage(bookingTranslations.dates)}</h6>
                                {isValidPeriod(pickerRange) &&
                                <span>{print.period(pickerRange)}</span>
                                }
                                <Chevron_down/>
                            </div>
                        <SubInput
                            onReset={() => { setPickerRange({}); setPickerFocus("start")} }
                            key="stay"
                            type="stay"
                            title={intl.formatMessage(bookingTranslations.choose_date)}
                            modulePosition={modulePosition}
                        >
                        <RangePickerController
                            firstDayOfWeek={1}
                            labelize={calendarLabelize}
                            selectedRange={pickerRange}
                            pickFocus={pickerFocus}
                            onSelectedRangeChange={setPickerRange}
                            onPickFocusChange={setPickerFocus}
                            numberOfMonths={1}
                            minDate={moment()}
                            maxDate={moment().add(1, 'y')} />
                        </SubInput>
                    </div>

                    {/* Room Range */}
                    <div className={classNames("search-module-button", {show: smartphoneRoomRangeShow})}>
                        <div data-type="roomRange" onClick={(e) => handleFilerModuleInputsClick(e)}>
                            <h6>{intl.formatMessage(search_module.search_module_nbr_of_room)}</h6>
                            {searchCriteria.roomsRange === null || searchCriteria.roomsRange[1] === 6 && searchCriteria.roomsRange[0] === 0  ?
                                ""
                            :
                                <>
                                    {`${showRoom(searchCriteria.roomsRange[0])} - ${showRoom(searchCriteria.roomsRange[1])}`}
                                </>
                            }
                            <Chevron_down/>
                        </div>
                        <SubInput
                            onReset={() => setRoomsRange(null)}
                            key="room"
                            type="room"
                            title={intl.formatMessage(search_module.search_module_minRoom)}
                            modulePosition={modulePosition}
                        >
                            <label className="rangeLabel">
                                <div className="inputRangeColor" style={!!roomsRangeColorRange ? {background:roomsRangeColorRange} : {}}></div>
                                <input
                                    name="minRoom"
                                    type="range"
                                    step={1}
                                    value={!!searchCriteria.roomsRange? searchCriteria.roomsRange[0] : 0}
                                    min={1}
                                    max={6}
                                    onChange={handleRoomsRangeMin}
                                />
                                <input
                                    name="maxRoom"
                                    type="range"
                                    step={1}
                                    value={!!searchCriteria.roomsRange? searchCriteria.roomsRange[1] : 6}
                                    min={1}
                                    max={6}
                                    onChange={handleRoomsRangeMax}
                                />
                                <span style={{left:spanMinRoomPosition}}>
                                    {`${searchCriteria.roomsRange === null || searchCriteria.roomsRange[0] === 0 ? "Studio" : showRoom(searchCriteria.roomsRange[0]) }`}
                                </span>
                                <span style={{left:spanMaxRoomPosition}}>
                                    {`${searchCriteria.roomsRange === null || searchCriteria.roomsRange[1] === 6 ? "6+" : showRoom(searchCriteria.roomsRange[1]) }`}
                                </span>
                            </label>
                        </SubInput>
                    </div>

                    {/* Surface */}
                    <div className={classNames("search-module-button ", {
                        hide: searchCriteria.articleType != AccommodationType.SALE && searchCriteria.articleType != AccommodationType.ANNUAL,
                        show: smartphoneSurfaceShow
                        })}
                    >
                        <div data-type="surface" onClick={(e) => handleFilerModuleInputsClick(e)}>
                            <h6>{intl.formatMessage(bookingTranslations.livingSpace)}</h6>
                            {searchCriteria.surface != 0 && `${searchCriteria.surface} m²`}
                            <Chevron_down/>
                        </div>
                        <SubInput
                            onReset={() => setSurface(0)}
                            key="surface"
                            type="surface"
                            title={intl.formatMessage(search_module.search_module_choose_surface)}
                            modulePosition={modulePosition}
                        >
                            <label className="rangeLabel">
                                <div className="inputRangeColor" style={!!surfaceColorRange ? {background: surfaceColorRange} : {}}></div>
                                <input
                                    id="minSurface"
                                    name="minSurface"
                                    type="range"
                                    value={searchCriteria.surface || 0}
                                    step={10}
                                    min={0}
                                    max={250}
                                    onChange={handleSurface}
                                />
                                <span style={{left:spanMinSurfacePosition}}>{`${searchCriteria.surface || 0} m²`}</span>
                            </label>
                        </SubInput>
                    </div>

                    {/* Guests */}
                    <div className={classNames("search-module-button ", {
                        hide: searchCriteria.articleType != AccommodationType.RENTAL,
                        show: smartphoneGuestsShow
                        })}
                    >
                        <div data-type="guests" onClick={(e) => handleFilerModuleInputsClick(e)}>
                            <h6>{intl.formatMessage(bookingTranslations.guests)}</h6>
                            {renderGuestsQuantitySmartPhone}
                            <Chevron_down/>
                        </div>
                        <SubInput
                            onReset={() => setGuests(new NbrGuests())}
                            key="guests"
                            type="guests"
                            title={intl.formatMessage(search_module.search_module_choose_guests)}
                            modulePosition={modulePosition}
                        >
                            <Guests guests={searchCriteria.guests} setGuests={setGuests}/>
                        </SubInput>
                    </div>

                    {/* Filters Equipment */}
                    <div className={classNames("search-module-button ", {
                        hide: searchCriteria.articleType != AccommodationType.RENTAL && searchCriteria.articleType != AccommodationType.SEASONAL,
                        show: smartphoneEquipmentShow
                        })}>
                            <div data-type="equipment" onClick={(e) => handleFilerModuleInputsClick(e)}>
                                <h6>{intl.formatMessage(bookingTranslations.equipment)}</h6>
                                {
                                    searchCriteria.features.length > 0 &&
                                    intl.formatMessage(search_module.search_module_equipment_quantity, {value: searchCriteria.features.length})
                                }
                                <Chevron_down/>
                            </div>
                        <SubInput
                            onReset={() => setFeatures([])}
                            key="equipment"
                            type="equipment"
                            title={intl.formatMessage(search_module.search_module_choose_object_equipment)}
                            modulePosition={modulePosition}
                        >
                            {renderEquipment}
                        </SubInput>
                    </div>

                </div>
                <div className="filter-module-buttons">
                    <button type="button" className="btn" onClick={resetFilterModule}>
                        {intl.formatMessage(buttonsTranslations.delete_all)}
                    </button>
                    <button type="button" className="btn-red" onClick={submitSearchData}>
                        {intl.formatMessage(globalTranslations.search)}
                        <Arrow_right/>
                    </button>
                </div>
            </div>
        </>
        );
    }else{ //Desktop
        return(
            <div id="searchModule" ref={searchModule} className={classNames(`${modulePosition} searchModule`)}>
                <div className="container">

                    {/*Type of search*/}
                    <div className="search-type-button">
                        <div
                            id="location"
                            className={classNames("search-module-button",{ red: searchCriteria.articleType != AccommodationType.SALE })}
                        >
                            {locationChoiceTitle}
                            <Chevron_down/>
                            <SubInput
                                onReset={() => setArticleType(AccommodationType.RENTAL)}
                                key="typeOfSearch"
                                type="typeOfSearch"
                                title={intl.formatMessage({ id: "search-rent-type-title", defaultMessage: "Quelle location désirez-vous ?" })}
                                modulePosition={modulePosition}
                                className="type"
                            >
                                <label htmlFor="RENTAL">
                                    <input
                                        type="radio"
                                        id="RENTAL"
                                        value="RENTAL"
                                        name="locationBrowser"
                                        checked={searchCriteria.articleType === AccommodationType.RENTAL ? true : false}
                                        onChange={handleTypeOfSearch}
                                    />
                                    {intl.formatMessage(genericTermTranslations.locationHolidays)}
                                </label>
                                <label htmlFor="SEASONAL">
                                    <input
                                        type="radio"
                                        id="SEASONAL"
                                        value="SEASONAL"
                                        name="locationBrowser"
                                        checked={searchCriteria.articleType === AccommodationType.SEASONAL ? true : false}
                                        onChange={handleTypeOfSearch}
                                    />
                                    {intl.formatMessage(genericTermTranslations.locationSeasonal)}
                                </label>
                                <label htmlFor="ANNUAL">
                                    <input
                                        type="radio"
                                        id="ANNUAL"
                                        value="ANNUAL"
                                        name="locationBrowser"
                                        checked={searchCriteria.articleType === AccommodationType.ANNUAL ? true : false}
                                        onChange={handleTypeOfSearch}
                                    />
                                    {intl.formatMessage(genericTermTranslations.locationYearly)}
                                </label>
                            </SubInput>
                        </div>
                        <button
                            id="SALE"
                            type="button"
                            className={classNames("search-module-button",{ red: searchCriteria.articleType === AccommodationType.SALE })}
                            onClick={handleTypeOfSearch}
                        >
                            {intl.formatMessage(genericTermTranslations.sale)}
                        </button>
                    </div>

                    {/*ObjectType*/}
                    <div className="search-module-button">
                            {intl.formatMessage(search_module.search_module_type)}
                            <Chevron_down/>
                            <SubInput
                                className={"objectType"}
                                onReset={() => setObjectTypes(initialObjectTypes)}
                                key="typeOfArticle"
                                type="typeOfArticle"
                                title={intl.formatMessage({id:"search-module-choose-guests-type", defaultMessage: "Quel(s) type(s) de bien ?"})}
                                modulePosition={modulePosition}
                            >
                                {renderObjectTypes}
                            </SubInput>
                    </div>

                    {/* Prix */}
                    <div className={classNames("search-module-button", {
                        show: smartphonePriceRangeShow,
                        hide: searchCriteria.articleType === AccommodationType.RENTAL
                    })}>
                        {!!searchCriteria.priceRange ?
                            <>
                                {formatModulePrice(searchCriteria.priceRange[0])} - {formatModulePrice(searchCriteria.priceRange[1])}
                            </>
                        :
                            intl.formatMessage(bookingTranslations.price)
                        }
                        <Chevron_down/>
                        <SubInput
                            onReset={() => setPriceRange(null)}
                            key="price"
                            type="price"
                            title={intl.formatMessage({id:"searchmodule-choose-price",defaultMessage: "Quel prix désirez-vous ?"})}
                            modulePosition={modulePosition}
                        >
                            <label className="rangeLabel">
                                <div className="inputRangeColor" style={!!priceColorRange ? {background:priceColorRange} : {}}></div>
                                <input
                                    name="minPrice"
                                    type="range"
                                    step={renderStep()}
                                    value={!!searchCriteria.priceRange ? searchCriteria.priceRange[0] : 0}
                                    min={0}
                                    max={searchCriteria.priceLimits![1]}
                                    onChange={handleMinPrice}
                                />
                                <input
                                    name="maxPrice"
                                    type="range"
                                    step={renderStep()}
                                    value={!!searchCriteria.priceRange? searchCriteria.priceRange[1] : searchCriteria.priceLimits[1]}
                                    min={0}
                                    max={searchCriteria.priceLimits![1]}
                                    onChange={handleMaxPrice}
                                />
                                <span style={{left:spanMinPricePosition}}>
                                    {`CHF ${formatModulePrice(!!searchCriteria.priceRange? searchCriteria.priceRange[0] : searchCriteria.priceLimits[0])}`}
                                </span>
                                <span style={{left:spanMaxPricePosition}}>
                                    {`CHF ${formatModulePrice(!!searchCriteria.priceRange? searchCriteria.priceRange[1] : searchCriteria.priceLimits[1])}`}
                                </span>
                            </label>
                        </SubInput>
                    </div>

                    {/* Calendar */}
                    <div className={classNames("search-module-button ", {hide: searchCriteria.articleType != AccommodationType.RENTAL})}>
                        {(searchCriteria.period.start && searchCriteria.period.end ) ?
                            nights()
                            :
                            intl.formatMessage(bookingTranslations.checkIn_checkOut)
                        }
                        <Chevron_down/>
                        <SubInput
                            onReset={() => { setPickerRange({}); setPickerFocus("start")} }
                            key="stay"
                            type="stay"
                            title={intl.formatMessage(bookingTranslations.choose_date)}
                            modulePosition={modulePosition}>
                        <RangePickerController
                            firstDayOfWeek={1}
                            labelize={calendarLabelize}
                            selectedRange={pickerRange}
                            pickFocus={pickerFocus}
                            onSelectedRangeChange={setPickerRange}
                            onPickFocusChange={setPickerFocus}
                            numberOfMonths={2}
                            minDate={moment()}
                            maxDate={moment().add(1, 'y')} />
                        </SubInput>
                    </div>

                    {/* Room Range */}
                    <div className="search-module-button">
                        {searchCriteria.roomsRange === null || searchCriteria.roomsRange[1] === 6 && searchCriteria.roomsRange[0] === 0  ?
                            intl.formatMessage(bookingTranslations.rooms)
                            :
                            <>
                                {`${showRoom(searchCriteria.roomsRange[0])} - ${showRoom(searchCriteria.roomsRange[1])}`}
                            </>
                        }
                        <Chevron_down/>
                        <SubInput
                            onReset={() => setRoomsRange([0,6])}
                            key="room"
                            type="room"
                            title={intl.formatMessage(search_module.search_module_minRoom)}
                            modulePosition={modulePosition}
                        >
                            <label className="rangeLabel">
                                <div className="inputRangeColor" style={!!roomsRangeColorRange ? {background:roomsRangeColorRange} : {}}></div>
                                <input
                                    name="minRoom"
                                    type="range"
                                    step={1}
                                    value={!!searchCriteria.roomsRange? searchCriteria.roomsRange[0] : 0}
                                    min={1}
                                    max={6}
                                    onChange={handleRoomsRangeMin}
                                />
                                <input
                                    name="maxRoom"
                                    type="range"
                                    step={1}
                                    value={!!searchCriteria.roomsRange? searchCriteria.roomsRange[1] : 6}
                                    min={1}
                                    max={6}
                                    onChange={handleRoomsRangeMax}
                                />
                                <span style={{left:spanMinRoomPosition}}>
                                    {`${searchCriteria.roomsRange === null || searchCriteria.roomsRange[0] === 0 ? "Studio" : showRoom(searchCriteria.roomsRange[0]) }`}
                                </span>
                                <span style={{left:spanMaxRoomPosition}}>
                                    {`${searchCriteria.roomsRange === null || searchCriteria.roomsRange[1] === 6 ? "6+" : showRoom(searchCriteria.roomsRange[1]) }`}
                                </span>
                            </label>
                        </SubInput>
                    </div>

                    {/* Surface */}
                    <div className={classNames("search-module-button ", {
                        hide: searchCriteria.articleType != AccommodationType.SALE && searchCriteria.articleType != AccommodationType.ANNUAL
                        })}
                    >
                        {searchCriteria.surface != 0
                          ? `${searchCriteria.surface} m²`
                          : intl.formatMessage(bookingTranslations.livingSpace)
                        }
                        <Chevron_down/>
                        <SubInput
                            onReset={() => setSurface(0)}
                            key="surface"
                            type="surface"
                            title={intl.formatMessage(search_module.search_module_choose_surface)}
                            modulePosition={modulePosition}
                        >
                            <label className="rangeLabel">
                                <div className="inputRangeColor" style={!!surfaceColorRange ? {background: surfaceColorRange} : {}}></div>
                                <input
                                    id="minSurface"
                                    name="minSurface"
                                    type="range"
                                    value={searchCriteria.surface || 0}
                                    step={10}
                                    min={0}
                                    max={250}
                                    onChange={handleSurface}
                                />
                                <span style={{left:spanMinSurfacePosition}}>{`${searchCriteria.surface} m²`}</span>
                            </label>
                        </SubInput>
                    </div>

                    {/* Guests */}
                    <div className={classNames("search-module-button ", {
                        hide: searchCriteria.articleType != AccommodationType.RENTAL
                        })}
                    >
                        {renderGuestsQuantity}
                        <Chevron_down/>
                        <SubInput
                            onReset={() => setGuests(new NbrGuests())}
                            key="guests"
                            type="guests"
                            title={intl.formatMessage(search_module.search_module_choose_guests)}
                            modulePosition={modulePosition}
                        >
                            <Guests guests={searchCriteria.guests} setGuests={setGuests}/>
                        </SubInput>
                    </div>

                    <button type="button" className="btn-red" onClick={submitSearchData}>
                        {intl.formatMessage(globalTranslations.search)}
                        <Arrow_right/>
                    </button>

                </div>
            </div>
        );
    }
};

export default SearchModule;
