import React from "react";
import { useIntl } from "react-intl";

import { main,section_1,section_2,section_3 } from "./translations";
import "./fiscal-situation.scss";


const Fiscal_situation = (
    {
    }) => {
    const intl = useIntl();

    return(
        <>
            <hgroup className="page-title">
                <h1>
                    {intl.formatMessage(main.title,{s: (chunks:React.ReactNode) => <span>{chunks}</span>})}<br/>
                </h1>
                <p>
                    {intl.formatMessage(main.text)}
                </p>
            </hgroup>
            <section>
                <div className="floating-section">
                    {/*Élément généraux */}
                    <section>
                        <h2>
                            {intl.formatMessage(section_1.title,{s: (chunks:React.ReactNode) => <span>{chunks}</span>})}<br/>
                        </h2>
                        <p>
                            {intl.formatMessage(section_1.text_1)}
                        </p>
                        <br/>
                        <p>
                            {intl.formatMessage(section_1.text_2)}
                        </p>
                        <br/>
                        <p>
                            {intl.formatMessage(section_1.text_3)}
                        </p>
                        <br/>
                        <p>
                            {intl.formatMessage(section_1.text_4)}
                        </p>
                        <br/>
                        <p>
                            {intl.formatMessage(section_1.text_5)}
                        </p>
                    </section>

                    {/*Frais de notaire */}
                    <section>
                        <h2>
                            {intl.formatMessage(section_3.title,{s: (chunks:React.ReactNode) => <span>{chunks}</span>})}<br/>
                        </h2>
                        <p>
                            {intl.formatMessage(section_3.text_1)}
                        </p>
                    </section>
                </div>
                <div className="floating-section">
                    {/*Financement immobilier */}
                    <section>
                        <h2>
                            {intl.formatMessage(section_2.title,{s: (chunks:React.ReactNode) => <span>{chunks}</span>})}<br/>
                        </h2>
                        <p className="strong">
                            {intl.formatMessage(section_2.text_1)}
                        </p>
                        <br/>
                        <h3>{intl.formatMessage(section_2.subtitle_1)}</h3>
                        <p>
                            {intl.formatMessage(section_2.text_2)}
                        </p>
                        <br/>
                        <h3>{intl.formatMessage(section_2.subtitle_2)}</h3>
                        <p>
                            {intl.formatMessage(section_2.text_3)}
                        </p>
                        <br/>
                        <h3>{intl.formatMessage(section_2.subtitle_3)}</h3>
                        <p>
                            {intl.formatMessage(section_2.text_4)}
                        </p>
                        <br/>
                        <h3>{intl.formatMessage(section_2.subtitle_4)}</h3>
                        <p>
                            {intl.formatMessage(section_2.text_5)}
                        </p>
                        <br/>
                    </section>
                </div>
            </section>

        </>
    );
};

export default Fiscal_situation;