import {defineMessages} from "react-intl";

export const article_translations = defineMessages({
    description: {id: "description", defaultMessage: "Description <s>du bien</s>"},
    no_description: {id:"no-description", defaultMessage: "Ce bien ne possède pas de description pour le moment. Pour plus d'informations veuillez nous contacter."},
    interest_in_the_accomodation: {id:"interest-in-the-accomodation", defaultMessage: "Contactez-nous <s>pour le visiter</s>"},
    title_location: {id:"title-location", defaultMessage: "Où se trouve <s>le logement ?</s>"},
    title_announces_rent: {id:"title-announces-rent", defaultMessage: "Autres logements <s>à louer</s>"},
    title_announces_sale: {id:"title-announces-sale", defaultMessage: "Autres logements <s>à vendre</s>"},
    title_rental_equipment: {id:"title-rental-equipment", defaultMessage: "Ce que propose ce <s>bien</s>"},
    smoker: {id:"smoker", defaultMessage: "Fumeur"},
    free_parking: {id:"free-parking", defaultMessage: "Parking gratuit"},
    pet_allowed: {id:"pet-allowed", defaultMessage: "Animaux acceptés"},
    wifi_availaible: {id:"wifi-availaible", defaultMessage: "Wifi sur place"},
    with_tv: {id:"with-tv", defaultMessage: "Télévision"},
    with_dishwasher: {id:"with-dishwasher", defaultMessage: "Lave-vaisselle"},
    with_pool: {id:"with-pool", defaultMessage: "Piscine commune"},
    on_shopping_street: {id:"on-shopping-street", defaultMessage: "Sur rue commerçante"},
    clear_view: {id:"clear-view", defaultMessage: "Vue dégagé"},
    center: {id:"center", defaultMessage: "Au centre"},
    copy_url: {id:"copy-url", defaultMessage: "Copier l'url"},
    download_pdf: {id:"download_pdf", defaultMessage: "Télécharger en pdf"},
    share_on_facebook: {id:"share-on-facebook", defaultMessage: "Partager sur Facebook"},
    share_on_whatsapp: {id:"share-on-whatsapp", defaultMessage: "Partager sur WhatsApp"},
    share_by_email: {id:"share-by-email", defaultMessage: "Partager par email"},
    empty_map: {id:"empty-map", defaultMessage: "Les coordonées de ce bien ne sont pas encore disponible."},
});

export const article_seo = defineMessages({
    title_sale: {id:"seo-article-title-sale", defaultMessage: "{type} {objectname} à vendre à {location} - {rooms}pièces"},
    title_rental: {id:"seo-article-title-rental", defaultMessage: "{type} {objectname} à louer à {location} - {rooms}pièces"},
    primary_residence: {id:"article-primary-residence", defaultMessage: "Idéal pour résidence principale"},
    secondary_residence: {id:"article-primary-secondary", defaultMessage: "Idéal pour résidence secondaire"},
    isFurnished: { id: "seo-isFurnished", defaultMessage: "Meublé" },
    withClearView: { id: "seo-withClearView", defaultMessage: "Vue dégagée" },
    withBalcony: { id: "seo-withBalcony", defaultMessage: "Avec balcon" },
    withFireplace: { id: "seo-withFireplace", defaultMessage: "Avec cheminée" },
    withGarage: { id: "seo-withGarage", defaultMessage: "Avec garage" },
    withOutdoorSpace: { id: "seo-withOutdoorSpace", defaultMessage: "Avec espace extérieur" },
    withParking: { id: "seo-withParking", defaultMessage: "Parking disponible" },
    withPool: { id: "seo-withPool", defaultMessage: "Piscine" },
    withSauna: { id: "seo-withSauna", defaultMessage: "Sauna" },
    withTerrace: { id: "seo-withTerrace", defaultMessage: "Avec terrasse" },
    nearSkiArea: { id: "seo-nearSkiArea", defaultMessage: "À proximité des pistes" },
    onCityCenter: { id: "seo-onCityCenter", defaultMessage: "Au Centre-ville" },
    onShoppingArea: { id: "seo-onShoppingArea", defaultMessage: "Proche des commerces" },
    description_sale: {id:"seo-article-description-sale", defaultMessage: "Découvrez {objectname}, un bien d'exception à vendre à {location}. {rooms} pièces, {surface} m². {features}. {foreigner}."},
    description_rental: {id:"seo-article-description-rental", defaultMessage: "Découvrez {objectname}, un bien d'exception à louer à {location}. {rooms} pièces, {surface} m². {features}."},
    keywords_sale: {id:"seo-article-keyword-sale", defaultMessage: "appartement à vendre à {location}, immobilier Montana, achat appartement montagne, Iris et Lotus Montana, propriété de luxe Montana, résidence secondaire montagne, investissement immobilier Montana"},
    keywords_rental: {id:"seo-article-keyword-rental", defaultMessage: "appartement à louer à {location}, immobilier Montana, location appartement montagne, vacances Montana, propriété de luxe Montana, location semaine"},
});

