import React, { FunctionComponent, useEffect, useMemo, useReducer, useState } from "react";
import { useIntl } from "react-intl";
import * as isoCountries from "i18n-iso-countries";
import { LocaleLink, useLocaleContext } from "@ct-react/locale";
import { useCartData } from "./cart";
import { useNavigate } from "react-router";
import { formTranslations } from "../../i18n/sharable-defs";

import "./cart-information.scss";
import { cart_translations } from "./translations";

type FormEvent = React.FormEvent<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>;

const CartInformation:FunctionComponent = ()=>{
    const intl = useIntl();
    const { locale } = useLocaleContext();
    const  {
        customer,
        setCustomer
      } = useCartData();
    const navigate = useNavigate();
    const [isDisabled,setIsDisabled] = useState<boolean>(true);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleFormData = (e:FormEvent) => {
        setCustomer({
            type: e.currentTarget.name,
            value: e.currentTarget.value,
        });
    };

    const handleOnsubmit = () => {
        setIsSubmitted(true);
    };

    useEffect(() => {
        if(Object.values(customer).includes(false)){
            setIsSubmitted(false);
            setIsDisabled(true);
        }else{
            setIsDisabled(false);
        }
    },[customer]);

    useEffect(() => {
        if(!isSubmitted) return;
        if(isSubmitted && Object.values(customer).includes(false)){
            setIsSubmitted(false);
            setIsDisabled(true);
        }else{
            navigate(`/${locale.basename}/checkout/paiement`);
        }
    },[isSubmitted]);

    const countries = useMemo(() => Object.fromEntries(Object
        .entries(isoCountries.getNames(locale.basename, { select: "official" }))
        .sort((a, b) => a[1].localeCompare(b[1]))),
    [ locale ]);

    return(
        <form className="connexion-form">
            <div>
                <div className="input-block name">
                    <label htmlFor="name">{intl.formatMessage(formTranslations.lastNameLabel)}</label>
                    <input
                        id="name"
                        type="text"
                        name="name"
                        placeholder={intl.formatMessage(formTranslations.lastNamePlaceholder)}
                        required
                        onInput={handleFormData}
                        value={customer.name}
                    />
                    <span>
                        {(!customer.nameValid && customer.name != "") && intl.formatMessage(formTranslations.informationError)}
                        {isSubmitted && customer.name === "" && intl.formatMessage(formTranslations.emptyError)}
                    </span>
                </div>

                <div className="input-block surname">
                    <label htmlFor="surname">{intl.formatMessage(formTranslations.firstNameLabel)}</label>
                    <input
                        id="surname"
                        type="text"
                        name="surname"
                        value={customer.surname}
                        placeholder={intl.formatMessage(formTranslations.firstNamePlaceholder)}
                        required
                        onInput={handleFormData}
                    />
                    <span>
                        {(!customer.surnameValid && customer.surname != "") && intl.formatMessage(formTranslations.informationError)}
                        {isSubmitted && customer.surname === "" && intl.formatMessage(formTranslations.emptyError)}
                    </span>
                </div>

                <div className="input-block email">
                    <label htmlFor="email">{intl.formatMessage(formTranslations.emailLabel)}</label>
                    <input
                        id="email"
                        type="email"
                        name="email"
                        value={customer.email}
                        placeholder={intl.formatMessage(formTranslations.emailPlaceholder)}
                        required
                        onInput={handleFormData}
                    />
                    <span>
                        {(!customer.emailValid && customer.email != "") && intl.formatMessage(formTranslations.mailError)}
                        {isSubmitted && customer.email === "" && intl.formatMessage(formTranslations.emptyError)}
                    </span>
                </div>

                <div className="input-block tel">
                    <label htmlFor="tel">{intl.formatMessage(formTranslations.phoneLabel)}</label>
                    <input
                        id="tel"
                        type="tel"
                        name="tel"
                        value={customer.tel}
                        placeholder={intl.formatMessage(formTranslations.phonePlaceholder)}
                        onInput={handleFormData}
                    />
                    <span>
                        {(!customer.telValid && customer.tel != "") && intl.formatMessage(formTranslations.phoneError)}
                        {isSubmitted && customer.tel === "" && intl.formatMessage(formTranslations.emptyError)}
                    </span>
                </div>
            </div>

            <div>
                <div className="input-block street">
                        <label htmlFor="street">{intl.formatMessage(formTranslations.street)}</label>
                        <input
                            id="street"
                            type="text"
                            name="street"
                            value={customer.street}
                            placeholder={intl.formatMessage(formTranslations.streetPlaceholder)}
                            required
                            onInput={handleFormData}
                        />
                    <span>
                        {(!customer.streetValid && customer.street != "") && intl.formatMessage(formTranslations.informationError)}
                        {isSubmitted && customer.street === "" && intl.formatMessage(formTranslations.emptyError)}
                    </span>
                </div>

                <div className="input-block streetNbr">
                    <label htmlFor="streetNbr">N°</label>
                    <input
                        id="streetNbr"
                        type="text"
                        name="streetNbr"
                        value={customer.streetNbr}
                        placeholder="0"
                        onInput={handleFormData}
                    />
                </div>

                <div className="input-block complementaryAdress">
                        <label htmlFor="complementaryAdress">{intl.formatMessage(formTranslations.streetComplement)}</label>
                        <input
                            id="complementaryAdress"
                            type="text"
                            name="complementaryAdress"
                            value={customer.complementaryAdress}
                            placeholder={intl.formatMessage(formTranslations.streetComplementPlaceholder)}
                            onInput={handleFormData}
                        />
                </div>

                <div className="input-block npa">
                    <label htmlFor="npa">NPA</label>
                    <input
                        id="npa"
                        type="text"
                        name="npa"
                        placeholder="NPA"
                        value={customer.npa}
                        onInput={handleFormData}
                    />
                    <span>
                        {(!customer.npaValid && customer.npa != "") && intl.formatMessage(formTranslations.informationError)}
                        {isSubmitted && customer.npa === "" && intl.formatMessage(formTranslations.emptyError)}
                    </span>
                </div>

                <div className="input-block city">
                    <label htmlFor="city">{intl.formatMessage(formTranslations.city)}</label>
                    <input
                        id="city"
                        type="text"
                        name="city"
                        value={customer.city}
                        placeholder={intl.formatMessage(formTranslations.cityPlaceholder)}
                        onInput={handleFormData}
                    />
                    <span>
                        {(!customer.cityValid && customer.city != "") && intl.formatMessage(formTranslations.informationError)}
                        {isSubmitted && customer.city === "" && intl.formatMessage(formTranslations.emptyError)}
                    </span>
                </div>

                <div className="input-block country">
                    <label htmlFor="country">{intl.formatMessage(formTranslations.country)}</label>
                    <select name="country"
                            value={customer.country || ""}
                            onInput={handleFormData}
                            required
                            >
                        <option value="">{intl.formatMessage(formTranslations.countryPlaceholder)}</option>
                        {Object.entries(countries).map(([ val, label ], i) =>
                            <option key={i} value={val}>{label}</option>
                        )}
                    </select>
                </div>
            </div>
            <div className="connexion-form-buttons">
                <LocaleLink to="/checkout/panier" className="btn-red inverted">
                    {intl.formatMessage(cart_translations.change_reservation)}
                </LocaleLink>
                <button className="btn-red" type="button" onClick={handleOnsubmit} disabled={isDisabled}>
                    {intl.formatMessage(cart_translations.define_model_payment)}
                </button>
            </div>
        </form>
    );
};

export default CartInformation;
