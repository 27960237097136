import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Image } from "@shared/models/article";

type ImageWithSizes = Image & {
  sizes: string,
};

export const ResponsiveImage = ({
    title,
    alternativeText,
    main,
    aspectRatio,
    assets,
    sizes,
}: ImageWithSizes) => {
  // Trier les assets par largeur décroissante
  const [loading, setLoading] = useState(true);
  const sortedAssets = [...assets].sort((a, b) => b.width - a.width);

  // Générer le srcset pour les assets ayant un breakpoint défini
  const srcSet = sortedAssets
    .map(asset => `${asset.url} ${asset.width}w`)
    .join(', ');

  // Récupérer l'URL par défaut (la plus grande image)
  const defaultSrc = sortedAssets[0].url;

  const handleImageLoaded = () => {
    setLoading(false);
  };

  const handleImageError = () => {
    setLoading(false); // Gère aussi l'erreur pour masquer le loading
    console.error('Erreur lors du chargement de l\'image');
  };

  return (
    <>
      <img
        src={defaultSrc}
        srcSet={srcSet}
        alt={alternativeText || 'Image'}
        sizes={sizes}
        onLoad={handleImageLoaded}
        onError={handleImageError}
      />
      {loading && <Skeleton width="100%" height="100%"/>}
    </>
  );
};

export default ResponsiveImage;
