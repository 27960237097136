import React from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { AccommodationBookingOption, AnyArticle } from "../../models/search-param";
import { AccommodationType, DiscountDefinition } from "@shared/models/article";
import { bookingTranslations, reservationTermTranslations } from "../../i18n/sharable-defs";
import { RentalAccommodationArticle } from "../../models/article-rental";
import { SeasonalAccommodationArticle } from "../../models/article-seasonal";
import { AnnualAccommodationArticle } from "../../models/article-annual";
import { SaleAccommodationArticle } from "../../models/article-sale";
import { useLocaleFormatter } from "@ct-react/locale";

import "./article.scss";
import DiscountSvg from "../../../assets/svg/discount.svg";

type ArticlePriceProps = {
    data:AnyArticle,
    type: AccommodationType,
    suggestion?:AccommodationBookingOption | null
};

type CardArticleDiscountProps = {
    discounts: [DiscountDefinition]
};

export const ArticlePrice =({
    data,
    type,
    suggestion
}: ArticlePriceProps ) => {
    const intl = useIntl();
    const { print } = useLocaleFormatter();

    if(type === AccommodationType.RENTAL) {
        const price = (data as RentalAccommodationArticle).lowestPrice

        if(!!suggestion && suggestion.bookable) { //Price with suggestion
            return(
                <span className="price-span">{print.price(suggestion.price.amount)}</span>
            );
        }else if(!price.onDemand && !!price.value) { // Price without suggestion
            return(
                <span className="price-span">{intl.formatMessage(bookingTranslations.lowestPrice, {
                  price: print.price(price.value),
                  temp: intl.formatMessage(reservationTermTranslations.week)
                })}</span>
            );
        }else { // Price on demand or default
            return(
                <span className="price-span">{intl.formatMessage(bookingTranslations.fullPriceOnDemand)}</span>
            );
        }

    }

    if(type === AccommodationType.SEASONAL) {
        const price = (data as SeasonalAccommodationArticle).price
        if(price === null || price.onDemand) { //Price on demand or null
            return(
                <div className="price-span">
                <span>
                    {intl.formatMessage(bookingTranslations.fullPriceOnDemand)}
                </span>
                <span className="period-span">
                    {/* Empty space for UI reason */}
                    &nbsp;
                </span>
            </div>
            );
        }else if(price.isMonthly) { // Price monthly
            return(
                <div className="price-span">
                    <span>{`${print.price(price.value)} / ${intl.formatMessage(reservationTermTranslations.month)}`}</span>
                    <span className="period-span">
                        {intl.formatMessage(reservationTermTranslations.seasonly_price_period, {
                          from: intl.formatDate(moment(price.period[0]).toDate()),
                          to: intl.formatDate(moment(price.period[1]).toDate())
                        })}
                    </span>
                </div>
            );
        }else { // Price not monthly
            return(
                <div className="price-span">
                    <span>{print.price(price.value)}</span>
                    <span className="period-span">
                        {intl.formatMessage(reservationTermTranslations.seasonly_price_period, {
                          from: intl.formatDate(moment(price.period[0]).toDate()),
                          to: intl.formatDate(moment(price.period[1]).toDate())
                        })}
                    </span>
                </div>
            );
        }
    }

    if(type === AccommodationType.ANNUAL) {
        const price = (data as AnnualAccommodationArticle).price
        if(!price.onDemand) { // With price
            const availability = !!price.availableAt ?
                    intl.formatMessage(reservationTermTranslations.availaible_from, {
                      date: intl.formatDate(moment(price.availableAt).toDate())
                    })
                :
                    intl.formatMessage(reservationTermTranslations.availaible_now)
            ;
            return(
                <div className="price-span">
                    <span>{`${print.price(price.value)} / ${intl.formatMessage(reservationTermTranslations.month)}`}</span>
                    <span className="period-span">{availability}</span>
                </div>
            );
        }else{ // Price on demand
            return(
                <span className="price-span">{intl.formatMessage(bookingTranslations.fullPriceOnDemand)}</span>
            );
        }
    }

    if(type === AccommodationType.SALE){
        const price = (data as SaleAccommodationArticle).price
        if(!price.onDemand) { // With price
            return(
                <span className="price-span">
                    {print.price(price.value)}
                </span>
            );
        }else{ // Price on demand
            return(
                <span className="price-span">{intl.formatMessage(bookingTranslations.fullPriceOnDemand)}</span>
            );
        }
    }

    //DEFAULT
    return (<span className="price-span">{intl.formatMessage(bookingTranslations.fullPriceOnDemand)}</span>);
};

export const CardArticleDiscount = ({
    discounts
}: CardArticleDiscountProps) => {
    const intl = useIntl();
    const { print } = useLocaleFormatter();

    if(discounts.length > 0){
        return(
            <div className="card-discount">
            <DiscountSvg />
            <div>
                {!!discounts[0].fixedValue ?
                    intl.formatMessage(reservationTermTranslations.reduction_value, {value: print.price(discounts[0].fixedValue)})
                :
                    intl.formatMessage(reservationTermTranslations.reduction_percentage, {percentage: discounts[0].percentageValue * 100})
                }
                <br/>
                {intl.formatMessage(
                    {id:"card-article-reduction-dates", defaultMessage: "À partir du {from} au {to}"},
                    {
                      from: intl.formatDate(moment(discounts[0].period[0]).toDate()),
                      to: intl.formatDate(moment(discounts[0].period[1]).toDate()),
                    }
                )}
            </div>
        </div>
        );
    }else{
        return <></>;
    }
};
