import React from "react";
import { useIntl } from "react-intl";
import { ContactForm } from "../../components/forms/forms";
import { MapContainer, MapMarker } from "../../bundles/map-implement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faMapPin, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FacebookIcone, InstagramIcone, LinkedinIcone, WhattsappIcone } from "../../components/icones/icones";


import { genericTermTranslations } from "../../i18n/sharable-defs";
import { main } from "./translations";
import "./contact.scss";

import Image_1 from "../../../assets/img/rene-jean-paul-berclaz-ski-montana.png";
import Red_arrow from "../../../assets/svg/fleche-rouge-photo.svg";

const HomeMarker = () => {
    const intl = useIntl();

    return (
        <div className="homeMarker">
        <span>{intl.formatMessage(genericTermTranslations.montanagence_agency)}</span>
            <div>
                <FontAwesomeIcon icon={faHome} />
            </div>
        </div>
    );
};

const Contact = (
    {
    }) => {
    const intl = useIntl();
    const position:number[] =[46.31276174491367, 7.483991126029801];

    return(
        <>
            <hgroup className="page-title">
                <h1>
                    {intl.formatMessage(main.title,{s: (chunks:React.ReactNode) => <em>{chunks}</em>})}<br/>
                </h1>
            </hgroup>
            <section className="basic-50-50 contact">
                <div>
                    <h2>
                        {intl.formatMessage(main.form_title,{s: (chunks:React.ReactNode) => <span>{chunks}</span>})}<br/>
                    </h2>
                    <ContactForm/>
                </div>
                <div>
                    <div>
                            <h3>
                                {intl.formatMessage(main.subtitle_1,{s: (chunks:React.ReactNode) => <span>{chunks}</span>})}<br/>
                            </h3>
                            <address>
                                <a href="tel:+41 27 481 43 43">
                                    <FontAwesomeIcon className="footer-icone" width={16} height={16} icon={faPhone}/>
                                    +41 27 481 43 43
                                </a>
                                <a href="https://maps.app.goo.gl/FWpuuSnaGZ6ZF94Z9" target="_blank">
                                    <FontAwesomeIcon className="footer-icone" width={16} height={16} icon={faMapPin}/>
                                    Avenue de la gare 19<br/>
                                    Crans-Montana 3963
                                </a>
                            </address>
                            <div className="social-network">
                                <FacebookIcone/>
                                <LinkedinIcone/>
                                <InstagramIcone/>
                                <WhattsappIcone/>
                            </div>
                    </div>
                    <div>
                        <h3>
                            {intl.formatMessage(main.subtitle_2,{s: (chunks:React.ReactNode) => <span>{chunks}</span>})}<br/>
                        </h3>
                        <p>
                            {intl.formatMessage(main.schedule_1,{br: () => <br/>})}<br/>
                            <br/>
                            {intl.formatMessage(main.schedule_2,{br: () => <br/>})}<br/>
                            <br/>
                            {intl.formatMessage(main.schedule_3)}<br/>
                        </p>
                    </div>
                    <img className="floating-img" src={Image_1} alt="Texte alternatif par défaut"/>
                    <div className="floating-text">
                        <Red_arrow/>
                        <h4>René et Jean-Paul Berclaz</h4>
                    </div>
                </div>
            </section>
            <MapContainer center={position} zoom={17}>
                <MapMarker position={position} icon={HomeMarker()} />
            </MapContainer>
        </>
    );
};

export default Contact;
