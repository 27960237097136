import React from "react";
import { useIntl } from "react-intl";

import { main } from "./translations";

const PrivacyPolicy = (
    {
    }) => {
    const intl = useIntl();

    return(
        <section>
            <h4 className="marged">{intl.formatMessage(main.text_1)}</h4>
            <p>
                {intl.formatMessage(main.text_2)}
            </p>
            <ul>
                <li>{intl.formatMessage(main.text_3)}</li>
                <li>{intl.formatMessage(main.text_4)}</li>
                <li>{intl.formatMessage(main.text_5)}</li>
            </ul>
            <p>
                {intl.formatMessage(main.text_6)}
            </p>

            <h4 className="marged">{intl.formatMessage(main.text_7)}</h4>
            <p>{intl.formatMessage(main.text_8)}</p>
            <ul>
                <li>{intl.formatMessage(main.text_9)}</li>
                <li>{intl.formatMessage(main.text_10)}</li>
                <li>{intl.formatMessage(main.text_11)}</li>
                <li>{intl.formatMessage(main.text_12)}</li>
                <li>{intl.formatMessage(main.text_13)}</li>
                <li>{intl.formatMessage(main.text_14)}</li>
            </ul>
            <p>{intl.formatMessage(main.text_15)}</p>
            
            <h4 className="marged">{intl.formatMessage(main.text_16)}</h4>
            <p>
                {intl.formatMessage(main.text_17)}
            </p>

            <h4 className="marged">{intl.formatMessage(main.text_18)}</h4>
            <p>
                {intl.formatMessage(main.text_19)}
            </p>

            <h4 className="marged">{intl.formatMessage(main.text_20)}</h4>
            <p>
                {intl.formatMessage(main.text_21)}
            </p>
        </section>
    );

};

export default PrivacyPolicy;