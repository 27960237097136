import React, { useEffect, useState } from "react";
import LocaleRoutes from "./routes";
import { useLocation } from "react-router";
import { IntlProvider } from 'react-intl';
import { Helmet } from "react-helmet-async";
import { defaultLocale, useLocaleContext } from "@ct-react/locale";
import { getIntlMessages } from "./i18n";
import CmsProvider from "./contexts/cms";
import { ScrollLockProvider } from "./contexts/lockScroll";
import { MenuProvider } from "./contexts/menu";
import { SearchProvider } from "./contexts/search-module";
import Menu from "./layout/menu/menu";
import SearchModule from "./layout/search-module/search-module";
import { RGPDBanner, RGPDConsentProcessor, RGPDConsentProvider } from "./bundles/client-only-ui";
import Footer from "./layout/footer/footer";
import "./styles/global.scss";

const cookieConsentOptions = { consentCookieAttributes: { expires: 180 } };

const App =( ) => {

  const { locale } = useLocaleContext();
  const location = useLocation();

  useEffect(() => window.scrollTo(0,0), [ location ]);

  return(
    <IntlProvider locale={locale.code}
                  defaultLocale={defaultLocale.code}
                  messages={getIntlMessages(locale)}
                  onError={() => void 0}>
      <RGPDConsentProvider useCookieConsentHooksOptions={cookieConsentOptions}>
        <Helmet>
          <html lang={locale.code} />
          <meta property="og:locale" content={locale.code} />
          <meta property="og:type" content="website" />
        </Helmet>
        <RGPDConsentProcessor />
        <CmsProvider>
          <ScrollLockProvider>
            <MenuProvider state={false}>
              <Menu/>
            </MenuProvider>
            <SearchProvider>
              <SearchModule />
              <main><LocaleRoutes /></main>
            </SearchProvider>
            <Footer />
          </ScrollLockProvider>
        </CmsProvider>
      </RGPDConsentProvider>
    </IntlProvider>);

};

export default App;
