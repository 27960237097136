import React, { createContext, PropsWithChildren, useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import slugify from "slugify";
import { useAppConfig } from "@ct-react/core";
import { useLocaleContext } from "@ct-react/locale";

const CMS_PAGES_GQL_DATA = gql`
  query cmsPages($templateId: UUID!) {
    cmsPages(templateId: $templateId) { id createdAt associationRef data }
  }
`;

type Navigation = { [ key: string ]: { id: string, path: string, label: string }[] };
type CmsContextProps = { navigation: Navigation, pages: any[] };

export const CmsContext = createContext<CmsContextProps>({ navigation: {}, pages: [] });

const CmsProvider = ({ children }: PropsWithChildren) => {

  const { options: { cmsTemplateId: templateId } } = useAppConfig();
  const { locale } = useLocaleContext();
  const [ value, setValue ] = useState<CmsContextProps>({ navigation: {}, pages: [] })

  const { data } = useQuery(CMS_PAGES_GQL_DATA, { ssr: false, variables: { templateId } });

  useEffect(() => {
    if (!data || !data.cmsPages) return;
    const ordered = [ ...data.cmsPages ].sort((a: any, b: any) =>
      new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
    const navigation = ordered.reduce((acc: Navigation, page: any) => {
      const navGroup = page.data.find((pg:any) => pg.slug === "nav-label")!;
      const label = navGroup.text.find((pc: any) => pc.languageCode === locale.basename)!.description;
      const slug = slugify(navGroup.text.find((pc: any) => pc.languageCode === "fr")!.description, { lower: true });
      const path = `/${page.associationRef}/${slug}?pageId=${page.id}`;
      acc[page.associationRef] = [ ...(acc[page.associationRef] || []), { id: page.id, path, label } ];
      return acc;
    }, {});
    setValue({ navigation, pages: data.cmsPages });
  }, [ data ]);

  return (
    <CmsContext.Provider value={value}>
      {children}
    </CmsContext.Provider>);
}

export default CmsProvider;
