import { Moment } from "moment";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { bookingTranslations } from "../i18n/sharable-defs";

export const usePickerLabelize = (withYear: boolean = true) => {

  const intl = useIntl();

  return useMemo(() => ({
    month: (m: Moment) => intl.formatDate(m.toDate(), { month: "long", ...(withYear) && { year: "numeric" } }),
    dayOfWeek: (d: Moment) => intl.formatDate(d.toDate(), { weekday: "short" }),
    onlineRestricted: intl.formatMessage(bookingTranslations.onDemandOnly)
  }), [ intl ]);

}
