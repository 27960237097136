import {defineMessages} from "react-intl";

export const globalTranslations = defineMessages({
  yes: { id: "generics-yes", defaultMessage: "Oui" },
  no: { id: "generics-no", defaultMessage: "Non" },
  share: { id: "generics-share", defaultMessage: "Partager" },
  login: { id: "generics-login", defaultMessage: "Se connecter" },
  logout: { id: "generics-logout", defaultMessage: "Se déconnecter" },
  signup: { id: "generics-signup", defaultMessage: "S'inscrire" },
  continue: { id: "generics-continue", defaultMessage: "Continuer" },
  cancel: { id: "generics-cancel", defaultMessage: "Annuler" },
  profile: { id: "generics-profile", defaultMessage: "Mon profil" },
  profile_title: { id: "generics-profile-title", defaultMessage: "Mon profil" },
  cart: { id: "generics-cart", defaultMessage: "Mon panier" },
  send: { id: "generics-send", defaultMessage: "Envoyer" },
  save: { id: "generics-save", defaultMessage: "Sauvegarder" },
  search: { id: "generics-search", defaultMessage: "Rechercher" },
  booking: { id: "generics-booking", defaultMessage: "Réservation" }
});

export const bookingTranslations = defineMessages({
  checkIn: { id: "generics-check-in-label", defaultMessage: "Arrivée" },
  checkOut: { id: "generics-check-out-label",  defaultMessage: "Départ" },
  checkIn_checkOut: { id: "generics-check-in-check-out-label",  defaultMessage: "Arrivée-Départ" },
  period: { id: "generics-period", defaultMessage: "Période" },
  periodPlaceholder: { id: "generics-check-in-out-placeholder", defaultMessage: "Ajouter des dates" },
  dates: { id: "generics-dates", defaultMessage: "Dates" },
  price: { id: "generics-price", defaultMessage: "Prix" },
  nightsPeriod: { id: "generics-selection-period", defaultMessage: "{nightsCount, plural, =1 {# nuit} other {# nuits}}" },
  nbrOfGuests: { id: "generics-nbr-of-guests", defaultMessage: "{total, plural, =1 {# Personne} =0 {Personne} other {# Personnes}}" },
  nbrOfGuests_smartphone: { id: "generics-nbr-of-guests-smartphone", defaultMessage: "{total, plural, =1 {# Personne} =0 {} other {# Personnes}}" },
  guests: { id: "generics-guests", defaultMessage: "Personnes" },
  total: { id: "generics-total", defaultMessage: "Total" },
  priceOnDemand: { id: "generics-price-on-demand", defaultMessage: "Sur demande" },
  fullPriceOnDemand: { id: "generics-full-price-on-demand", defaultMessage: "Prix sur demande" },
  lowestPrice: { id: "generics-lowest-price", defaultMessage: "Dès {price} / {temp}" },
  onDemandOnly: { id: "generics-on-demand-only", defaultMessage: "Sur demande uniquement" },
  discount: { id: "generics-discount-valued", defaultMessage: "Réduction de {val}" },
  reduction: { id:"reduction", defaultMessage: "Réduction" },
  book: { id: "generics-booking-button", defaultMessage: "Réserver maintenant" },
  check_availabilities: { id: "generics-availabilities-button", defaultMessage: "Vérifier les disponibilités" },
  benefits: { id: "generics-benefits", defaultMessage: "Prestations" },
  customerCharge: { id: "generics-customer-charge", defaultMessage: "Frais de service" },
  customerChargeAmount: { id: "generics-customer-charge-amount", defaultMessage: "{adaptive, select, true {jusqu'à } other {}}{amount}" },
  rooms: { id: "generics-rooms", defaultMessage: "Pièces" },
  livingSpace: { id: "generics-living-space", defaultMessage: "Surface" },
  equipment: { id: "generics-equipment", defaultMessage: "Équipement" },
  adults: { id: "generics-adults", defaultMessage: "Adultes" },
  kids: { id: "generics-kids", defaultMessage: "Enfants" },
  kids_age_range: { id:"generics-guests-kids-age-range", defaultMessage: "De 6 à 16 ans" },
  exempts: { id: "generics-exempts", defaultMessage: "Exemptés" },
  exempts_age_range: { id:"generics-guests-exempts-age-range", defaultMessage: "De 0 à 5 ans" },
  choose_date: {id: "choose-dates", defaultMessage: "Quelles sont vos dates ?"},
  add_date: {id: "add-date", defaultMessage: "Ajouter une date"},
  module_contact: {id:"contact-for-special-date", defaultMessage:"Pour toute demande relative à des dates spécifiques, n'hésitez pas à nous contacter."},
  tourist_tax: {id:"tourist-tax", defaultMessage : "Taxe de séjour"},
  amount: { id: "amount", defaultMessage: "Montant" },
  downPayment: { id: "down-payment", defaultMessage: "Acompte" },
  booking_charge_info: {id:"booking-charge-info", defaultMessage: "Ces frais participent au fonctionnement de notre plateforme."},
});

export const featureTranslations = defineMessages({
  wifi: { id: "generics-features-wifi", defaultMessage: "WIFI" },
  tv: { id: "generics-features-tv", defaultMessage: "TV" },
  washingMachine: { id: "generics-features-washing-machine", defaultMessage: "Lave-linge" },
  dishwasher: { id: "generics-features-dishwasher", defaultMessage: "Lave-vaisselle" },
  fireplace: { id: "generics-features-fireplace", defaultMessage: "Cheminée" },
  parking: { id: "generics-features-parking", defaultMessage: "Parking" },
  balcony: { id: "generics-features-balcony", defaultMessage: "Balcon" },
  terrace: { id: "generics-features-terrace", defaultMessage: "Terrasse" },
  pool: { id: "generics-features-pool", defaultMessage: "Piscine" },
  sauna: { id: "generics-features-sauna", defaultMessage: "Sauna" },
  smoker: { id: "generics-features-no-smoking", defaultMessage: "Non-fumeur" },
  pet: { id: "generics-features-pet-compliant", defaultMessage: "Animaux admis" },
  disabledPerson: { id: "generics-features-disabled-compliant", defaultMessage: "Accès pour personnes handicapées" },
  smokerAlarm: { id: "generics-features-smoke-alarm", defaultMessage: "Détecteur de fumée" },
  carbonAlarm: { id: "generics-features-carbon-alarm", defaultMessage: "Détecteur de monoxyde de carbone" },
  bedroom: { id: "generics-features-bedroom", defaultMessage: "Chambre" },
  isSellableToForeigners: { id: "generics-features-isSellableToForeigners", defaultMessage: "Vente aux étrangers/res secondaire" },
  orientation: { id: "generics-features-orientation", defaultMessage: "Orientation" },
  bathrooms: { id: "generics-features-bathrooms", defaultMessage: "Salle de bain" },
  withFireplace: { id: "generics-features-withFireplace", defaultMessage: "Poêle à bois" },
  withLift: { id: "generics-features-withLift", defaultMessage: "Ascenceur" },
  workingCapitalFund: { id: "generics-features-workingCapitalFund", defaultMessage: "Charges estimatives annuelles" },
  cityCenter: { id: "generics-features-cityCenter", defaultMessage: "Au centre ville" },
  skiArea: {id:"generics-features-skiArea", defaultMessage: "À proximité des remontées"},
});

export const agencyTranslations = defineMessages({
  contact: { id: "generics-agency-contact", defaultMessage: "Contacter l'agence" },
});

export const formTranslations = defineMessages({
  firstNameLabel: { id: "generics-first-name-label", defaultMessage: "Prénom" },
  firstNamePlaceholder: { id: "generics-first-name-placeholder", defaultMessage: "Votre prénom" },
  lastNameLabel: { id: "generics-last-name-label", defaultMessage: "Nom" },
  lastNamePlaceholder: { id: "generics-last-name-placeholder", defaultMessage: "Votre nom" },
  emailLabel: { id: "generics-email-label", defaultMessage: "E-mail" },
  emailPlaceholder: { id: "generics-email-placeholder", defaultMessage: "Votre e-mail" },
  phoneLabel: { id: "generics-phone-label", defaultMessage: "Téléphone" },
  phonePlaceholder: { id: "generics-phone-placeholder", defaultMessage: "Votre téléphone" },
  street: { id: "generics-street", defaultMessage: "Rue" },
  streetPlaceholder: { id: "generics-street-placeholder", defaultMessage: "Votre rue" },
  streetNumberPlaceholder: { id: "generics-street-number-placeholder", defaultMessage: "N°" },
  streetComplement: { id: "generics-street-complement", defaultMessage: "Adresse complémentaire" },
  streetComplementPlaceholder: { id: "generics-street-complement-placeholder", defaultMessage: "Votre adresse complémentaire" },
  zipPlaceholder: { id: "generics-zip-placeholder", defaultMessage: "NPA" },
  city: { id: "generics-city", defaultMessage: "Localité" },
  cityPlaceholder: { id: "generics-city-placeholder", defaultMessage: "Votre localité" },
  country: { id: "generics-country", defaultMessage: "Pays" },
  countryPlaceholder: { id: "genrics-country-placeholder", defaultMessage: "Sélectionnez un pays" },
  messageLabel: { id: "generics-message-label", defaultMessage: "Message" },
  subjectLabel: { id: "generics-subject-label", defaultMessage: "Sujet" },
  messagePlaceholder: { id: "generics-message-placeholder", defaultMessage: "Dites-nous comment nous pouvons vous aider." },
  emptyError: { id: "generics-form-empty-error", defaultMessage: "Cette information est requise." },
  informationError: { id: "generics-form-information-error", defaultMessage: "Cette information n'est pas valide." },
  mailError: { id: "generics-form-mail-error", defaultMessage: "Cet e-mail n'est pas valide." },
  phoneError: { id: "generics-form-phone-error", defaultMessage: "Ce numéro n'est pas valide. Veuillez respecter le format international (exemple: +41 xx xxx xx xx)." },
  sendWithBookingPeriod: { id: "generics-form-bookingperiod-label", defaultMessage: "Souhaitez-vous transmettre la période de réservation sélectionnée ?" },
  minError: { id: "generics-form-min-error", defaultMessage: "Doit contenir au moins {value, plural, =1 {# caractère} other {# caractères}}." },
  optionError: { id: "generics-form-option-error", defaultMessage: "Veuillez sélectionner cette option." },
  sent: { id: "generics-form-send-success", defaultMessage: "Votre message a été envoyé avec succès." },
  sent_text: { id: "generics-form-send-success-text", defaultMessage: "Nous vous répondrons dès que possible." },
  saved: { id: "generics-form-save-success", defaultMessage: "Vos données ont été sauvegardées." },
  failed: { id: "generics-form-send-error", defaultMessage: "Une erreur est survenue à l'envoi de votre message." },
  failed_text: { id: "generics-form-send-error-text", defaultMessage: "Veuillez réessayer ou nous contacter si l'erreur se produit à nouveau." },
  failedSave: { id: "generics-form-save-error", defaultMessage: "Une erreur est survenue à la sauvegarde." }
});

export const genericTermTranslations = defineMessages({
  agency: { id: "generics-agency-def", defaultMessage: "Agence immobilière" },
  atCran: { id: "generics-at-cran", defaultMessage: "à Crans-Montana" },
  montanagence_agency: { id: "generics-crans-montana-agency", defaultMessage: "Montan'agence"},
  cransMontana: { id: "generics-crans-montana", defaultMessage: "Crans-Montana" },
  location: { id: "generics-location", defaultMessage: "Location" },
  locationHolidays: { id: "generics-location-vacances", defaultMessage: "Location vacances" },
  locationSeasonal: { id: "generics-location-seasonal", defaultMessage: "Location saison" },
  locationYearly: { id: "generics-location-yearly", defaultMessage: "Location année" },
  longLocation: { id: "generics-location-long", defaultMessage: "Location longue durée" },
  sale: { id: "generics-buy", defaultMessage: "Acheter" },
  services: { id: "generics-services", defaultMessage: "Services" },
  administration: { id: "generics-administration", defaultMessage: "Administration" },
  building_administration: { id: "generics-building-administration", defaultMessage: "Administration d'immeuble" },
  put_on_rent: { id: "generics-put-on-rent", defaultMessage: "Mettre en location" },
  put_on_sale: { id: "generics-put-on-sale", defaultMessage: "Mettre en vente" },
  domiciliation: { id: "generics-domiciliation", defaultMessage: "Domiciliation" },
  real_estate_development: { id: "generics-real-estate-development", defaultMessage: "Promotion immobilière" },
  promotion: { id: "generics-promotion", defaultMessage: "Promotion" },
  managment_cooproprety: { id: "generics-managment-cooproprety", defaultMessage: "Gestion copropriété" },
  agence: { id: "generics-agence", defaultMessage: "Agence" },
  about: { id: "generics-about", defaultMessage: "À propos" },
  contact: { id: "generics-contact", defaultMessage: "Contact" },
  inSale: { id: "generics-in-sale", defaultMessage: "En vente" },
  inRent: { id: "generics-in-rent", defaultMessage: "À louer" },
  rent: { id: "generics-rent", defaultMessage: "Louer" },
  buy: { id: "generics-buy", defaultMessage: "Acheter" },
  buySale: { id: "generics-buy-sale", defaultMessage: "Acheter, Vendre" },
  goodToSale: { id: "generics-good-to-sale", defaultMessage: "Biens à vendre" },
  saleItsGood: { id: "generics-sale-its-good", defaultMessage: "Vendre son bien" },
  managment: { id: "generics-managment", defaultMessage: "Gérance" },
  fiscalSituation: { id: "generics-fiscal-situation", defaultMessage: "Situation fiscale en Valais" },
  goodForCompany: { id: "generics-good-for-company", defaultMessage: "Biens pour entreprise" },
  goodToRent: { id: "generics-good-to-rent", defaultMessage: "Biens à louer" },
  entrustItsGood: { id: "generics-entrust-its-good", defaultMessage: "Confier son bien" },
  no_image: { id:"no-image", defaultMessage: "Aucune image disponible" },
  other: { id:"other", defaultMessage: "Autre"},
  choose_option: { id:"choose-option", defaultMessage: "Choisissez une option"},
});

export const reservationTermTranslations = defineMessages({
  week: { id: "generics-location-week", defaultMessage: "semaine" },
  month: { id: "generics-location-month", defaultMessage: "mois" },
  seasonly_price_period: { id: "seasonly-period-price-period", defaultMessage: "Du {from} au {to}"},
  availaible_from: { id: "availaible-from", defaultMessage: "Libre à partir du {date}"},
  availaible_now: { id: "availaible-now", defaultMessage: "Libre immédiatement"},
  reduction_percentage: {id: "reduction-percentage", defaultMessage: "Réduction de {percentage}%",},
  reduction_value: {id: "reduction-value", defaultMessage: "Réduction de {value}",},
});

export const buttonsTranslations = defineMessages({
  know_more: { id: "button-know-more", defaultMessage: "En savoir plus" },
  contact_agency: { id: "button-contact-agency", defaultMessage: "Écrire à l'agence" },
  contact_us: { id: "button-contact-us", defaultMessage: "Contactez-nous" },
  send: { id: "button-send", defaultMessage: "Envoyer" },
  back: { id: "button-back", defaultMessage: "Retour" },
  subscribe: { id: "button-subscribe", defaultMessage: "S'inscrire" },
  save: { id: "button-save", defaultMessage: "Enregistrer" },
  delete_all: { id: "button-delete-all", defaultMessage: "Tout effacer" },
  close: { id: "button-close", defaultMessage: "Fermer" },
  next: { id: "button-next", defaultMessage: "Suivant" },
  change_the_dates: { id: "button-change-the-dates", defaultMessage: "Changer les dates" },
  change_the_informations: { id: "button-change-the-infos", defaultMessage: "Changer les informations" },
  book: { id: "button-book", defaultMessage: "Réserver" },
  book_now: { id: "button-book-now", defaultMessage: "Réserver maintenant" },
  check_availability: { id: "button-availability", defaultMessage: "Vérifier la disponibilité" },
  reset: {id: "reset", defaultMessage: "Réinitialiser"},
  delete: {id: "delete", defaultMessage: "Supprimer"},
  explore: {id: "explore", defaultMessage: "Explorez"},
  retry: {id: "retry", defaultMessage: "Réssayer"},
  back_home: {id: "back-home", defaultMessage: "Retour à l'accueil"},
});

