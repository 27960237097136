import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faUser } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faInstagram, faLinkedinIn, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { classNames } from "@ct-react/core";
import { LocaleNavLink, useLocaleUrl } from "@ct-react/locale";
import { useVisitor } from "@ct-react/visitor";
import { useIntl } from "react-intl";
import { CART_BADGE_GQL_DATA } from "@shared/fragments";
import UserMenu from "../../layout/menu/user-menu";

import { globalTranslations } from "../../i18n/sharable-defs";
import "./icones.scss";

export const ProfilIcone = () => {
  const intl = useIntl();
  const { currentUrl } = useLocaleUrl();
  const { isLoggedIn: isLogged } = useVisitor();
  const [ opened, setOpened ] = useState<boolean>(false);

  useEffect(() => setOpened(false), [ currentUrl ]);

  return (
    <div className={classNames("profil", { isLogged })}>
      <FontAwesomeIcon className="icone user" icon={faUser}/>
      <span></span>
      <UserMenu />
    </div>);

};

export const BasketIcone = () => {
  const intl = useIntl();
  const { sessionId, userId } = useVisitor();
  const { data } = useQuery(CART_BADGE_GQL_DATA, {
    nextFetchPolicy: "cache-only",
    variables: { sessionId, userId }
  });

  return(
    <LocaleNavLink to="/checkout/panier" className="basket" data-gloss={intl.formatMessage(globalTranslations.cart)}>
      <FontAwesomeIcon className="icone" icon={faCartShopping} />
      {(!!data?.cart && data.cart.itemsCount > 0) &&
        <span className="cartQuantity">{data.cart.itemsCount}</span>
      }
    </LocaleNavLink>);

};

export const FacebookIcone = () => (
  <a className="social-network-icone" href="https://www.facebook.com/montanagence?locale=fr_FR" target="_blank">
    <FontAwesomeIcon icon={faFacebookF} />
  </a>);

export const LinkedinIcone = () => (
  <a className="social-network-icone" href="https://www.linkedin.com/company/montan-agence-sa/?viewAsMember=true" target="_blank">
    <FontAwesomeIcon icon={faLinkedinIn} />
  </a>);

export const InstagramIcone = () => (
  <a className="social-network-icone" href="https://www.instagram.com/montanagence_sa/" target="_blank">
    <FontAwesomeIcon icon={faInstagram} />
  </a>);

export const WhattsappIcone = () => (
  <a className="social-network-icone" href="https://whatsapp.com/channel/0029Vae5und4o7qFazLkJK47" target="_blank">
    <FontAwesomeIcon icon={faWhatsapp} />
  </a>);
