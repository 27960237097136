import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { makeReference, useMutation } from "@apollo/client";
import { useCartData } from "./cart";
import CartItem, { Skeletonitem } from "../../components/cart/cart-item";
import EmptyCart from "../../components/cart/empty-cart";
import { useNavigate } from "react-router";
import Skeleton from "react-loading-skeleton";
import { AnyCartItem } from "../../models/cart";
import { REMOVE_CART_ITEM } from "./cart-config";
import PopUp from "../../components/pop-up/pop-up";
import { Loader } from "../../components/loaders/loaders";
import { useLocaleContext, useLocaleFormatter } from "@ct-react/locale";

import { cart_translations } from "./translations";
import { bookingTranslations, buttonsTranslations, globalTranslations } from "../../i18n/sharable-defs";
import "./cart-items.scss";
import { calculateCartDownPaymentConfig } from "@ct-react/calendar";

const CartItems = () => {

    const intl = useIntl();
    const { print } = useLocaleFormatter();
    const navigate = useNavigate();
    const { locale } = useLocaleContext();
    const  {
      cartData,
      setCartData,
      loading,
      refetch,
      sessionId,
      userId,
    } = useCartData();
    const [idToRemove, setIdToRemove] = useState<string | null>(null);

    const [ removeFromCart, { loading: removeItemLoading } ] = useMutation(REMOVE_CART_ITEM, {
      update: (cache, { data: { cartRemoveItem } }) => cache.modify({
        id: cache.identify(makeReference(`Cart:${cartRemoveItem.cartId}`)),
        fields: {
          // @ts-ignore
          items: () => [],
          itemsCount: () => 0,
          fullPrice: () => cartRemoveItem.fullPrice
        }
      }),
      refetchQueries: [ "CartBadge" ]
    });

    const onRemove = (itemId: string) => {
        setIdToRemove(itemId);
    };

    const remove = (itemId: string) => {
        const variables = {
          sessionId: sessionId,
          userId: userId,
          item: itemId
        };
        removeFromCart({ variables })
        .then(() => {
            document.body.style.overflow = "initial";
            setIdToRemove(null);
            return refetch();
        })
        .then((refetchResult: any) => {
          // Update cartData with the result of the refetch
          if (refetchResult?.data?.cart) {
              setCartData(refetchResult.data.cart);
          }
      })
    };

    const nextStep = () => {
        if(!!userId) navigate(`/${locale.basename}/checkout/information`);
        if(!userId) navigate(`/${locale.basename}/checkout/connexion`);
    };

    const renderItems = useMemo(() => {
      if(loading) return <Skeletonitem/>;
      if(!loading && (!cartData || cartData.items.length === 0)) return <EmptyCart/>;
      if(!loading && !!cartData.items && cartData.items.length > 0){
        return cartData.items.map((item: AnyCartItem, index: number) => <CartItem key={index} item={item} onRemove={onRemove}/>);
      }
    },[loading, cartData]);

    const renderPrice = useMemo(() => {
      if(loading){
        return(
          <div className="cart-price">
            <Skeleton height="25px"/>
            <Skeleton width="20%"/>
            <Skeleton/>
            <Skeleton/>
            <Skeleton className="skeleton-card-btn"/>
          </div>
        );
      }
      if(!loading && !!cartData && cartData.items.length > 0) {
        const config = calculateCartDownPaymentConfig(cartData.items);
        return(
          <div className="cart-price">
            <h3>
                {intl.formatMessage(cart_translations.summary)}
            </h3>
            <div className="cart-price-total">
                <p>{intl.formatMessage(cart_translations.total_amount)}</p>
                <span>{print.price(cartData.fullPrice.amount)}</span>
              {!!config.providerCharge &&
                <>
                  <p>{intl.formatMessage(bookingTranslations.customerCharge)}</p>
                  <span>{intl.formatMessage(bookingTranslations.customerChargeAmount, {
                    adaptive: config.rate < 1,
                    amount: print.price(config.providerCharge)
                  })}</span>
                </>
              }
            </div>
            <div className="cart-price-button">
              <button className="btn-red" onClick={nextStep}>
                {intl.formatMessage(buttonsTranslations.book_now)}
              </button>
            </div>
          </div>
        );
      }
    },[ loading, cartData, cartData]);

    const popUpRemove = useMemo(() => {
      if(!!idToRemove){
        return(
          <PopUp setState={() => setIdToRemove(null)}>
              <h3>
                  {intl.formatMessage(cart_translations.confirm_deletion_item)}
              </h3>
              <div className="form-result-buttons">
                <button className="btn-red inverted" onClick={() => setIdToRemove(null)}>
                  {intl.formatMessage(globalTranslations.no)}
                </button>
                <button className="btn-red" onClick={() => remove(idToRemove)}>
                  {intl.formatMessage(globalTranslations.yes)}
                </button>
              </div>
          </PopUp>
        );
      }
    },[idToRemove]);

    return(
        <>
        <div className="cart-content">
          {removeItemLoading&& <Loader/>}
          {renderItems}
          {renderPrice}
        </div>
        { popUpRemove }
        </>
    )
}


export default CartItems;
