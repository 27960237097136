import React, { useState } from "react";
import gsap from "gsap";

import "./menu.scss";
import { LocaleNavLink } from "@ct-react/locale";

type SubNavLinkProps = {
    classnames?:string,
    to:string,
    click:()=>void,
    children : React.ReactNode
}

const SubNavLink = (
    {
        classnames = "",
        to="/",
        click = ():void=>{},
        children
    }:SubNavLinkProps)=>{

    return(
        <li className={classnames} onClick={()=>click()}>
            <LocaleNavLink to={to}>
                {children}
            </LocaleNavLink>
        </li>
    );
}

type SubNavProps={
    link: string,
    children:React.ReactNode,
    classes?: string
};

const SubNav = (
    {
        link,
        classes = "",
        children
    }: SubNavProps) => {
    const [isDown,setIsDown] = useState<boolean>(false);

    const handleOnClick = ()=>{
        setIsDown(!isDown)
    };

    const handleMouseEnter = ()=>{
        setIsDown(true)
    };

    const handleMouseLeave = ()=>{
        setIsDown(false)
    };

    return(
        <li className="subnav" 
        onClick={handleOnClick}
        onMouseEnter={handleMouseEnter} 
        onMouseLeave={handleMouseLeave}
        >
            <span className={classes}>
                {link}
                <svg xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 512 512"
                    style={{transform:isDown?"rotate(180deg)":"rotate(0deg)"}}
                    >
                    <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
                </svg>
            </span>
            { isDown&&
            <ul >
                {children}
            </ul>
            }
        </li>
    );
};

export {SubNav, SubNavLink};