import React from "react";
import { useIntl } from "react-intl";
import { bookingTranslations } from "../../i18n/sharable-defs";
import NbrGuests from "../../models/guests";

import "./inputs.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

type GuestsProps = {
    guests: NbrGuests,
    disableMax?: boolean
    setGuests: (guests: NbrGuests) => void,
};

const Guests = ({ 
    guests,
    disableMax,
    setGuests,
 }: GuestsProps) => {
    const intl = useIntl();

    const handleNbrGuests = (event: React.MouseEvent<HTMLButtonElement>, type: keyof NbrGuests, increment: boolean) => {
        event.stopPropagation();
        const maxLimit = 12;
        const minLimit = 0;
    
        const updatedGuests = {
            ...guests,
            [type]: increment 
                ? Math.min(guests[type] + 1, maxLimit) 
                : Math.max(guests[type] - 1, minLimit)
        };
    
        setGuests(updatedGuests);
    };

    return(
        <div className="nbrGuests">
            <div className="nbrGuests-block">
                <p>
                    {intl.formatMessage(bookingTranslations.adults)}
                </p>
                <div className="counter">
                    <button 
                        type="button"
                        onClick={(e) => handleNbrGuests(e, 'adult', false)}
                        disabled = { guests.adult === 0 }
                    >
                        <FontAwesomeIcon icon={faMinus}/>
                    </button>
                    <span>{guests.adult}</span>
                    <button 
                        type="button"
                        onClick={(e) => handleNbrGuests(e, 'adult', true)}
                        disabled = { disableMax }
                    >
                        <FontAwesomeIcon icon={faPlus}/>
                    </button>
                </div>
            </div>
            <div className="nbrGuests-block">
                <p> 
                    {intl.formatMessage(bookingTranslations.kids)}
                    <span>
                        {intl.formatMessage(bookingTranslations.kids_age_range)}
                    </span>
                </p>
                <div className="counter">
                    <button 
                        type="button" 
                        onClick={(e) => handleNbrGuests(e, 'child', false)}
                        disabled = { guests.child === 0 }
                    >
                        <FontAwesomeIcon icon={faMinus}/>
                    </button>
                    <span>{guests.child}</span>
                    <button 
                        type="button"
                        onClick={(e) => handleNbrGuests(e, 'child', true)}
                        disabled = { disableMax }
                    >
                        <FontAwesomeIcon icon={faPlus}/>
                    </button>
                </div>
            </div>
            <div className="nbrGuests-block">
                <p>
                    {intl.formatMessage(bookingTranslations.exempts)}
                    <span>
                        {intl.formatMessage(bookingTranslations.exempts_age_range)}
                    </span>
                </p>
                <div className="counter">
                    <button
                        type="button"
                        onClick={(e) => handleNbrGuests(e, 'exempt', false)}
                        disabled = { guests.exempt === 0 }
                    >
                        <FontAwesomeIcon icon={faMinus}/>
                    </button>
                    <span>{guests.exempt}</span>
                    <button
                        type="button"
                        onClick={(e) => handleNbrGuests(e, 'exempt', true)}
                        disabled = { guests.exempt === 12 }
                    >
                        <FontAwesomeIcon icon={faPlus}/>    
                    </button>
                </div>
            </div>
        </div>
    );
};


export default Guests;