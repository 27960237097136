import { gql } from "@apollo/client";

export const EMAIL_MUTATION = gql`
    mutation sendEmail(
    $agencyId: ID!,
    $firstName: String!,
    $lastName: String!,
    $email: EmailAddress!,
    $phone: String!,
    $subject: String,
    $body: String!,
    $articleId: ID!,
    $emailAddress: EmailAddress
    ){
        mailRelatedContact(
            merchantId: $agencyId,
            request: {
                article: { articleId: $articleId }
                body: $body
                contact: { 
                    email: $email,
                    firstName: $firstName,
                    lastName: $lastName,
                    phone: $phone 
                }
                recipient: $emailAddress
                subject: $subject
            }
        )
    }
`;