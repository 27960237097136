import React, { useContext, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import gsap from "gsap"
import { useSearchParams } from "react-router-dom";
import { SearchAnyArticleEdge, SearchAnyArticleResult, SearchPaginationLink } from "../../models/search-param";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faChevronLeft,
  faCircleDot,
  faDog,
  faEllipsis,
  faLadderWater,
  faMap,
  faSquareParking,
  faWifi
} from "@fortawesome/free-solid-svg-icons";
import { LocaleLink } from "@ct-react/locale";
import { classNames } from "@ct-react/core";
import { determineSearchConfig, GET_ARTICLES } from "./search-articles-config";
import { MapAsyncPopupMarker, MapContainer } from "../../bundles/map-implement";
import { CardArticle, CardArticleSkeleton } from "../../components/cards/card-articles";
import { useDisplayDesktop } from "../../utils/breakpoints";
import SeoHelmet from "../../components/seo-helmet/seo-helmet";
import { AccommodationMarker, AccommodationType } from "@shared/models/article";
import { useSearchContext } from "../../contexts/search-module";
import { generateSearchArticleParamData } from "../../layout/search-module/search-modules-config";

import { annual_seo, main, rent_seo, sale_seo, seasonal_seo } from "./translations";
import { featureTranslations } from "../../i18n/sharable-defs";

import "./search-articles.scss";

import SkiProximity from "../../../assets/svg/icone-ski.svg";
import { CmsContext } from "../../contexts/cms";

type ArticlesPaginationProps = {
    pageInfo:any,
};

type SearchArticlesProps = {
    type: AccommodationType,
};

const SearchArticles = ({ type = AccommodationType.RENTAL }: SearchArticlesProps) => {

    const intl = useIntl();
    const isSmartphone = useDisplayDesktop();

    const { navigation: cmsNavigation } = useContext(CmsContext);
    const { searchCriteria, setFeatures, setArticleType } = useSearchContext();

    const [searchParams, setSearchParams] = useSearchParams();
    const [showMap,setShowMap] = useState<boolean>(false);
    const [ cmsPage, setCmsPage ] = useState<{ path: string } | undefined>(undefined);
    const { request, pageTitle } = determineSearchConfig(type);

    const gsapMediaAnimations = gsap.matchMedia();
    const container = useRef(null);
    const mapRef = useRef(null);

    useEffect(() => {
      const search = cmsNavigation.services?.find(np => np.id === (AccommodationType.SALE ? "e2d81526-cdb2-46b1-b18b-f8e7812f2230" : "55add8e6-4242-4c69-8874-c52c22db1bed"));
      !!search && setCmsPage(search);
    }, [ cmsNavigation ]);

    const { data, error, loading, refetch } = useQuery<SearchAnyArticleResult>(GET_ARTICLES, {
      variables: {
        type: request,
        ...(searchParams.get("dir") === "before") && {
          last: 8,
          ...(searchParams.has("cursor") && { before: searchParams.get("cursor") })
        },
        ...(!searchParams.has("dir") || searchParams.get("dir") === "after") && {
          first: 8,
          ...(searchParams.has("cursor") && { after: searchParams.get("cursor") })
        }
      },
      ssr: true,
      fetchPolicy: "cache-first",
      notifyOnNetworkStatusChange: true,
    });

    const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        let nextArray;
        const checked = e.target.checked;
        const value = e.target.value;
        if(checked){//add
            nextArray = [...searchCriteria.features, value];
        }else{//remove
            nextArray = [...searchCriteria.features.filter( (f:string) => f !== value )]
        }
        setFeatures(nextArray);
    };

    useEffect(() => {
        if(!isSmartphone) return;
        setSearchParams(generateSearchArticleParamData(searchCriteria));
    },[searchCriteria.features])

    useEffect( () => { // Refetch datas as soon as query change
      refetch();
    },[searchParams]);

    //UI
    const handleShowMap = () => {
        setShowMap(!showMap)
    };

    useEffect(() => {
        if(!!container){
            gsapMediaAnimations.add("(min-width: 1400px)", () => {
                showMap && gsap.to(
                    container.current,
                    {
                        gridTemplateColumns:"minmax(705px, 50%) auto",
                        duration:1,
                        ease:"power1"
                    }
                );

                !showMap && gsap.to(
                    container.current,
                    {
                        gridTemplateColumns:"100% 50%",
                        duration:1,
                        ease:"power1"
                    }
                );
            });
            gsapMediaAnimations.add("(max-width: 1400px)", () => {
                showMap && gsap.to(
                    container.current,
                    {
                        gridTemplateColumns:"minmax(655px, 50%) auto",
                        duration:1,
                        ease:"power1"
                    }
                );

                !showMap && gsap.to(
                    container.current,
                    {
                        gridTemplateColumns:"100% 50%",
                        duration:1,
                        ease:"power1"
                    }
                );
            });
        }
    },[showMap]);

    return(
        <>
            {type === AccommodationType.RENTAL &&
                <SeoHelmet
                    title={intl.formatMessage(rent_seo.title)}
                    description={intl.formatMessage(rent_seo.description)}
                    canonical="/location/vacances"
                    keywords={["location appartement", "location chalet", "crans montana", "appartement à louer", "chalet à louer crans montana", "à louer crans montana", "appartement crans montana", "location chalet crans montana", "bien à louer crans montana"]}
                />
            }
            {type === AccommodationType.SEASONAL &&
                <SeoHelmet
                    title={intl.formatMessage(seasonal_seo.title)}
                    description={intl.formatMessage(seasonal_seo.description)}
                    canonical="/location/saison"
                    keywords={["location appartement", "location chalet", "crans montana", "appartement à louer", "chalet à louer crans montana", "à louer crans montana", "appartement crans montana", "location chalet crans montana", "bien à louer crans montana"]}
                />
            }
            {type === AccommodationType.ANNUAL &&
                <SeoHelmet
                    title={intl.formatMessage(annual_seo.title)}
                    description={intl.formatMessage(annual_seo.description)}
                    canonical="/location/annee"
                    keywords={["location appartement", "location chalet", "crans montana", "appartement à louer", "chalet à louer crans montana", "à louer crans montana", "appartement crans montana", "location chalet crans montana", "bien à louer crans montana"]}
                />
            }
            {type === AccommodationType.SALE &&
                <SeoHelmet
                    title={intl.formatMessage(sale_seo.title)}
                    description={intl.formatMessage(sale_seo.description)}
                    canonical="/vente"
                    keywords={["achat appartement", "achat chalet", "crans montana", "appartement à acheter", "chalet à acheter crans montana", "à vendre crans montana", "appartement crans montana", "achat chalet crans montana", "bien à vendre crans montana"]}
                />
            }

            <div className="articles-header">
                <div>
                    <h1>
                      {`${intl.formatMessage(main.search_articles_title)} `}
                      <span>{pageTitle}</span>
                    </h1>
                </div>
            </div>

            <div className="articles-settings">
                {type === AccommodationType.RENTAL ?
                <div className="filters">
                                <label htmlFor="parking" data-gloss={intl.formatMessage(featureTranslations.parking)}>
                                    <input
                                        type="checkbox"
                                        value="parking"
                                        id="parking"
                                        name="parking"
                                        onChange={handleFilter}
                                        defaultChecked={searchCriteria.features.includes("parking")}
                                        />
                                    <FontAwesomeIcon icon={faSquareParking}/>
                                </label>
                                <label htmlFor="wifi" data-gloss={intl.formatMessage(featureTranslations.wifi)}>
                                    <input
                                        type="checkbox"
                                        value="wifi"
                                        id="wifi"
                                        name="wifi"
                                        onChange={handleFilter}
                                        defaultChecked={searchCriteria.features.includes("wifi")}
                                        />
                                    <FontAwesomeIcon icon={faWifi}/>
                                </label>
                                <label htmlFor="pet" data-gloss={intl.formatMessage(featureTranslations.pet)}>
                                    <input
                                        type="checkbox"
                                        value="pet"
                                        id="pet"
                                        name="pet"
                                        onChange={handleFilter}
                                        defaultChecked={searchCriteria.features.includes("pet")}
                                        />
                                    <FontAwesomeIcon icon={faDog}/>
                                </label>
                                <label htmlFor="pool" data-gloss={intl.formatMessage(featureTranslations.pool)}>
                                    <input
                                        type="checkbox"
                                        value="pool"
                                        id="pool"
                                        name="pool"
                                        onChange={handleFilter}
                                        defaultChecked={searchCriteria.features.includes("pool")}
                                        />
                                    <FontAwesomeIcon icon={faLadderWater}/>
                                </label>
                                <label htmlFor="cityCenter" data-gloss={intl.formatMessage(featureTranslations.cityCenter)}>
                                    <input
                                        type="checkbox"
                                        value="cityCenter"
                                        id="cityCenter"
                                        name="cityCenter"
                                        onChange={handleFilter}
                                        defaultChecked={searchCriteria.features.includes("cityCenter")}
                                        />
                                    <FontAwesomeIcon icon={faCircleDot}/>
                                </label>
                                <label htmlFor="skiArea" data-gloss={intl.formatMessage(featureTranslations.skiArea)}>
                                    <input
                                        type="checkbox"
                                        value="skiArea"
                                        id="skiArea"
                                        name="skiArea"
                                        onChange={handleFilter}
                                        defaultChecked={searchCriteria.features.includes("skiArea")}
                                        />
                                    <SkiProximity/>
                                </label>
                </div>
                :
                <div></div>
                }
                <div className="toggle-map">
                    {intl.formatMessage(main.search_articles_map)}
                    <label className="switch">
                        <input className="toggle-btn" type="checkbox" onChange={handleShowMap} checked={showMap}/>
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>

            <div className={classNames("articles-container",{
                empty: (!loading && !!error),
                withMap: showMap
            })}
            ref={container}
            >
                {/* Loading */}
                {loading &&
                <div className="articles">
                    <CardArticleSkeleton/>
                    <CardArticleSkeleton/>
                    <CardArticleSkeleton/>
                    <CardArticleSkeleton/>
                    <CardArticleSkeleton/>
                    <CardArticleSkeleton/>
                    <CardArticleSkeleton/>
                    <CardArticleSkeleton/>
                </div>
                }

                {/* Loading ended and error */}
                {(!loading && error) &&
                <div className="empty-articles">
                    <h3>{intl.formatMessage(main.issue_occured)}</h3>
                    <p></p>
                </div>
                }

                {/* Loading ended and no article to show */}
                {(!loading && !!data && data?.search.totalCount === 0) &&
                <div className="empty-articles">
                    <h3>{intl.formatMessage(main.no_accomodations)}</h3>
                    <p>{intl.formatMessage(main.modify_your_filters)}</p>
                </div>
                }

                {/* Loading ended and article to show */}
                {(!loading && !!data) &&
                <>
                    <ul
                    itemScope
                    itemType="https://schema.org/OfferCatalog"
                    className={classNames("articles",{
                        autoGrid: data.search.edges.length > 3,
                        thirdGrid: data.search.edges.length <= 3
                    })}>
                        {!loading && !!data &&
                        data.search.edges.map((article:SearchAnyArticleEdge, index:number)=>{
                            return(
                                <li key={index}>
                                    <CardArticle
                                    key={article.node.id}
                                    index={index+1}
                                    data={article.node}
                                    type={type}
                                    bookingSuggestion={
                                        article.accommodationBookingSuggestion! ?
                                            article.accommodationBookingSuggestion
                                            :
                                            null
                                    }
                                    />
                                </li>
                            )
                        })}
                    </ul>
                    <div className={classNames("articles-map",{showMap:showMap})}>
                        <button className="btn-red" onClick={handleShowMap}>
                            <FontAwesomeIcon icon={faChevronLeft}/>
                            {intl.formatMessage({id:"articles-see-list", defaultMessage: "Voir la liste"})}
                        </button>
                        <MapContainer
                            className="overloaded-map"
                            center={[46.31215250514946, 7.482330731143153]}
                            zoom={15}
                            clusterize={true}
                            ref={mapRef}
                            >
                            { data.search.accommodationMapMarkers.map((marker:AccommodationMarker) => {
                                return (
                                <MapAsyncPopupMarker
                                    marker={marker}
                                    type={type}
                                    key={marker.id}
                                    mapRef={mapRef}
                                />
                            );
                            })}
                        </MapContainer>
                    </div>
                </>
                }

                <div className="articles-btnMap" >
                    <button className="btn-red" onClick={handleShowMap}>
                        <FontAwesomeIcon icon={faMap}/>
                        {intl.formatMessage({id:"articles-see-map", defaultMessage: "Voir la carte"})}
                    </button>
                </div>
            </div>

            {(!loading && !!data) &&
                <SearchArticlesPagination
                    pageInfo = {data.search.paginationInfo}
                />
            }

            <section className="cta">
                <h2>
                    {type === AccommodationType.SALE ?
                        intl.formatMessage(main.cta_title_sale, {s: (chunks:React.ReactNode) => <span>{chunks}</span>})
                        :
                        intl.formatMessage(main.cta_title_rent, {s: (chunks:React.ReactNode) => <span>{chunks}</span>})
                    }
                </h2>
                <p>
                    {type === AccommodationType.SALE ?
                        intl.formatMessage(main.cta_text_sale)
                        :
                        intl.formatMessage(main.cta_text_rent)
                    }
                </p>
                {!!cmsPage &&
                  <LocaleLink className="btn-red" to={cmsPage.path}>
                    {intl.formatMessage(type === AccommodationType.SALE ? main.cta_button_sale : main.cta_button_rent)}
                  </LocaleLink>
                }
            </section>

        </>
    );
};

const SearchArticlesPagination = ({ pageInfo }: ArticlesPaginationProps) => {

    const [ _, setSearchParams ] = useSearchParams();

    useEffect(() =>  window.scrollTo({ top: 0, left: 0, behavior: "smooth"}) ,[]);

    const onChangePage = (dir?: "before" | "after", cursor?: string) => setSearchParams(prev => {
      const { dir: _oldDir, cursor: _oldCursor, ...others } = Object.fromEntries(prev);
      return new URLSearchParams({
        ...others,
        ...(!!dir) && { dir },
        ...(!!cursor) && { cursor }
      } as Record<string, string>);
    });

    return(
        <div className="articles-pagination">
            <div className="pagination-container">

                {/* Previous page button */}
                {pageInfo.pageCount > 1 &&
                    <button disabled={!pageInfo.hasPreviousPage}
                            className="pagination"
                            onClick={() => onChangePage("before", pageInfo.previousPageCursor)}
                            type="button">
                    <FontAwesomeIcon icon={faAngleLeft} />
                    </button>
                }

                {/* First page button */}
                <button disabled={pageInfo.currentPage === 1}
                        className={classNames("pagination",{currentPage: pageInfo.currentPage === 1})}
                        onClick={()=> onChangePage()}
                        type="button">1</button>

                {/* Ellipsis on left */}
                {(pageInfo.pageCount > 3 && pageInfo.currentPage > 3) &&
                    <FontAwesomeIcon icon={faEllipsis}/>
                }

                {/* 3 middle page button */}
                {!!pageInfo.linkPageCursors && pageInfo.linkPageCursors.map((link: SearchPaginationLink, index: number) => {
                    return(
                        <button key={index}
                                className={classNames("pagination",{currentPage: link.page === pageInfo.currentPage})}
                                onClick={() => onChangePage("after", link.cursor)}
                                type="button"
                        >{link.page}</button>);
                })}

                {/* Ellipsis on right */}
                {(pageInfo.pageCount > 3 && pageInfo.currentPage < pageInfo.pageCount - 4) &&
                    <FontAwesomeIcon icon={faEllipsis}/>
                }

                {/* Last page button */}
                {pageInfo.pageCount > 1 &&
                    <button disabled={pageInfo.currentPage === pageInfo.pageSize}
                            className={classNames("pagination",{currentPage: pageInfo.pageCount === pageInfo.currentPage})}
                            onClick={()=> onChangePage("after", pageInfo.lastPageCursor)}
                            type="button">{pageInfo.pageCount}</button>
                }

                {/* Next page button */}
                {pageInfo.pageCount > 1 &&
                    <button disabled={!pageInfo.hasNextPage}
                            className="pagination"
                            onClick={() => onChangePage("after", pageInfo.nextPageCursor)}
                            type="button">
                    <FontAwesomeIcon icon={faAngleRight}/>
                    </button>
                }

            </div>
        </div>
    );
};

export default SearchArticles;
