import React from "react";
import { useIntl } from "react-intl";
import { cart_item_translations } from "./translations";
import { LocaleLink } from "@ct-react/locale";

import { buttonsTranslations } from "../../i18n/sharable-defs";
import "./empty-cart.scss";

const EmptyCart = () => {
    const intl = useIntl();

    return(
        <div className="empty-cart">
            <h3>{intl.formatMessage(cart_item_translations.empty_card_nothing_found)}</h3>
            <p>{intl.formatMessage(cart_item_translations.empty_card_explore)}</p>
            <LocaleLink to={"/location/vacances"} className="btn-red">
                {intl.formatMessage(buttonsTranslations.explore)}
            </LocaleLink>
        </div>
    );
};

export default EmptyCart;
