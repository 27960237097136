import { useVisitor } from "@ct-react/visitor";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { globalTranslations } from "../../i18n/sharable-defs";
import { LocaleLink } from "@ct-react/locale";

const UserMenu = (
    {
    }) => {
    const intl = useIntl();
    const { isLoggedIn } = useVisitor();
    const location = useLocation();

    const target = useMemo(() => encodeURIComponent(location.pathname + location.search), [ location ]);

    if (isLoggedIn)
      return (
        <div className={"userMenu"}>
            <ul>
              {/* disabled for release as long as the profile is not done
                <li>
                    <LocaleLink to="/me/profile">
                        {intl.formatMessage(globalTranslations.profile)}
                    </LocaleLink>
                </li>
               */}
                <li>
                    <a href={`/logout/?target=${target}`}>
                        {intl.formatMessage(globalTranslations.logout)}
                    </a>
                </li>
            </ul>
      </div>
      );

    return (
        <div className={"userMenu"}>
            <ul>
            <li>
                <a href={`/signup/?target=${target}`}>
                {intl.formatMessage(globalTranslations.signup)}
                </a>
            </li>
            <li>
                <a href={`/login/?target=${target}`}>
                {intl.formatMessage(globalTranslations.login)}
                </a>
            </li>
            </ul>
        </div>
    );

};

export default UserMenu;
