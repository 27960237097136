import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCross, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "react-intl";
import { classNames } from "@ct-react/core";
import "./payment-callback.scss";
import { useNavigate } from "react-router";
import { buttonsTranslations } from "../../i18n/sharable-defs";
import { cart_translations } from "./translations";
import { LocaleLink } from "@ct-react/locale";

type CartPaymentCallbackProps = { succeeded: boolean };

const CartPaymentCallback= ({
    succeeded
}: CartPaymentCallbackProps) => {
    const intl = useIntl();
    const navigate = useNavigate();

    return(
        <div className="payment-result">
            <div className={classNames({succeeded : succeeded})}>
                <FontAwesomeIcon icon={succeeded? faCheck : faXmark}/>
            </div>
            <h3>
                {succeeded?
                intl.formatMessage(cart_translations.payment_callback_succeeded_1)
                :
                intl.formatMessage(cart_translations.payment_callback_failed_1)
                }
            </h3>
            <p>
                {succeeded?
                intl.formatMessage(cart_translations.payment_callback_succeeded_2)
                :
                intl.formatMessage(cart_translations.payment_callback_failed_2)
                }
            </p>
            {succeeded ?
            <LocaleLink className="btn-red" to={"/"}>
                {intl.formatMessage(buttonsTranslations.back_home)}
            </LocaleLink>
            :
            <LocaleLink className="btn-red" to={"location/vacances"}>
                {intl.formatMessage(buttonsTranslations.retry)}
            </LocaleLink>
            }
        </div>
    );
};

export default CartPaymentCallback;
