import {defineMessages} from "react-intl";

export const main = defineMessages({
    search_articles_title: {id: "search-articles-title", defaultMessage: "Logements"},
    search_articles_title_rental: {id: "search-articles-title-rental", defaultMessage: "à louer à la semaine"},
    search_articles_title_seasonal: {id: "search-articles-title-seasonal", defaultMessage: "à louer à la saison"},
    search_articles_title_annual: {id: "search-articles-title-annual", defaultMessage: "à louer à l'année"},
    search_articles_title_sale: {id: "search-articles-title-sale", defaultMessage: "à vendre"},
    search_articles_map: {id: "search-articles-map", defaultMessage: "Carte"},
    issue_occured: {id: "issue-occured", defaultMessage: "Un problème est survenu"},
    no_accomodations: {id:"no-accomodations", defaultMessage: "Aucun bien ne correspond aux filtres de recherche."},
    modify_your_filters: {id:"modify-your-filters", defaultMessage: "Nous n'avons pas de bien immobilier à vous présenter dans cette catégorie. Merci de nous contacter pour toute demandes particulières."},
    cta_title_sale: {id:"cta-title-sale", defaultMessage: "Vous souhaitez <s>vendre votre bien ?</s>"},
    cta_text_sale: {id:"cta-text-sale", defaultMessage: "Nous vous offrons notre expertise pour évaluer votre bien sur le marché immobilier. Nous vous informons des pratiques de courtage sur la station de Crans-Montana, ainsi que de toutes les démarches et frais qui incombent à la vente de votre bien. Préparez au mieux ce projet de vie important en contactant notre équipe dévouée."},
    cta_button_sale: {id:"cta-button-sale", defaultMessage: "Je veux vendre mon bien"},
    cta_title_rent: {id:"cta-title-rent", defaultMessage: "Vous souhaitez <s>louer votre bien ?</s>"},
    cta_text_rent: {id:"cta-text-rent", defaultMessage: "Nous mettons notre expertise à votre service pour évaluer votre bien sur le marché locatif. Déterminez si une location annuelle, saisonnière ou de courte durée correspond le mieux à vos attentes. Notre équipe est là pour vous conseiller et vous guider dans vos projets de vie."},
    cta_button_rent: {id:"cta-button-rent", defaultMessage: "Je veux louer mon bien"},
});

export const rent_seo = defineMessages({
    title: {id: "search-rent-seo-title", defaultMessage: "Appartements et Chalets de vacances à louer à Crans-Montana, Valais"},
    description: {id: "search-rent-seo-description", defaultMessage: "L'agence immobilière Montan’Agence vous propose une sélection d'appartements et de chalets à louer à Crans-Montana pour vos vacances."},
});

export const seasonal_seo = defineMessages({
    title: {id: "search-seasonal-seo-title", defaultMessage: "Appartements et Chalets à louer à la saison à Crans-Montana, Valais"},
    description: {id: "search-seasonal-seo-description", defaultMessage: "L'agence immobilière Montan’Agence vous propose une sélection d'appartements et de chalets à louer à la saison à Crans-Montana."},
});

export const annual_seo = defineMessages({
    title: {id: "search-annual-seo-title", defaultMessage: "Appartements et Chalets à louer à l'année à Crans-Montana, Valais"},
    description: {id: "search-annual-seo-description", defaultMessage: "L'agence immobilière Montan’Agence vous propose une sélection d'appartements et de chalets à louer à l'année à Crans-Montana."},
});

export const sale_seo = defineMessages({
    title: {id: "search-sale-seo-title", defaultMessage: "Appartements et Chalets à vendre à Crans-Montana, Valais"},
    description: {id: "search-sale-seo-description", defaultMessage: "L'agence immobilière Montan’Agence vous propose une sélection d'appartements et de chalets à vendre à Crans-Montana."},
});
