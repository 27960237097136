import gql from "graphql-tag";


export const GET_CART_INFO = gql`
query GET_CART($sessionId: String, $userId: String){
    cart(sessionId: $sessionId, userId: $userId ) {
        cartId
        itemsCount
        fullPrice {
        amount
        discount
        }
        items {
        ... on RentalAccommodationCartItem {
                description
                expirationDate
                image
                itemId
                period
                title
                price {
                    amount
                    bare
                    discount
                    options {
                        amount
                        type
                    }
                }
                downPayment { rate providerCharge }
            }
        }
    }
}
`;

export const REMOVE_CART_ITEM = gql`
    mutation REMOVE_ITEM($sessionId: String, $userId: String, $item: UUID!){
        cartRemoveItem(sessionId: $sessionId, userId: $userId, item: $item) {
            cartId
        }
    }
`;

export const CART_CHECK_OUT = gql`
    mutation CHECK_OUT(
        $sessionId: String,
        $userId: String,
        $customer: CustomerInput!,
        $hasFullPayment: Boolean!,
        $redirect: CheckoutAfterPaymentRedirectInput!
        ){
            cartCheckout(
                sessionId: $sessionId
                userId: $userId
                checkout: {
                    customer: $customer
                    hasFullPayment: $hasFullPayment
                    redirect: $redirect
                }
            ) {
                cartId
                paymentUrl
            }
        }
`;
