import {defineMessages} from "react-intl";

export const main = defineMessages({
    text_1: {id: "legal-mention-1", defaultMessage: "Éditeur du site"},
    text_2: {id: "legal-mention-2", defaultMessage: "Montan'agence Société enregistrée sous le numéro [CHE 115.088.530 TVA ]"},
    text_3: {id: "legal-mention-3", defaultMessage: "Adresse : [Avenue de la gare 19, 3963 Crans-Montana]"},
    text_4: {id: "legal-mention-4", defaultMessage: "Téléphone : [0041 27 841 43 43]"},
    text_5: {id: "legal-mention-5", defaultMessage: "Directeur de la publication : [Berclaz Paul]"},
    text_6: {id: "legal-mention-6", defaultMessage: "Hébergement du site"},
    text_7: {id: "legal-mention-7", defaultMessage: "Adresse : Rue des vorziers 8"},
    text_8: {id: "legal-mention-8", defaultMessage: "Téléphone : +41 27 721 78 81"},
    text_9: {id: "legal-mention-9", defaultMessage: "Propriété intellectuelle"},
    text_10: {id: "legal-mention-10", defaultMessage: "Tous les contenus présents sur le site www.montanagence.ch (textes, images, graphiques, logos, vidéos, etc.) sont protégés par les lois en vigueur sur la propriété intellectuelle. Toute reproduction, distribution, modification ou utilisation de ces contenus sans autorisation écrite préalable est strictement interdite."},
    text_11: {id: "legal-mention-11", defaultMessage: "Responsabilité"},
    text_12: {id: "legal-mention-12", defaultMessage: "Montan'agence s’efforce de fournir des informations exactes et à jour sur ce site. Cependant, des erreurs ou omissions peuvent survenir. Montan'agence décline toute responsabilité quant aux éventuels dommages directs ou indirects résultant de l’utilisation de ce site."},
    text_13: {id: "legal-mention-13", defaultMessage: "Pour toute question ou réclamation, veuillez nous contacter à l’adresse [gerance@montanagence.ch]."},
});
