import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { LocaleLink, useLocaleContext } from "@ct-react/locale";
import { buttonsTranslations, globalTranslations } from "../../i18n/sharable-defs";

import "./cart-connexion.scss";
import { cart_translations } from "./translations";

const CartAccount = () => {
    const intl = useIntl();
    const { locale } = useLocaleContext();

    const accountTarget = useMemo(() => `/${locale.basename}/checkout/information`, [ locale ]);

    return(
        <div className="connexion-choice">
            <div>
                <h3>{intl.formatMessage(cart_translations.identify_myself)}</h3>
                <dl>
                    <dt>{intl.formatMessage(cart_translations.already_subscribe)}</dt>
                    <dd>
                        <button className="btn-red">
                            <a href={`/login/?target=${accountTarget}`}>{intl.formatMessage(globalTranslations.login)}</a>
                        </button>
                    </dd>
                    <dt>{intl.formatMessage(cart_translations.first_time_booking)}</dt>
                    <dd>
                        <button className="btn-red">
                            <a href={`/signup/?target=${accountTarget}`}>{intl.formatMessage(globalTranslations.signup)}</a>
                        </button>
                    </dd>
                </dl>
            </div>
            <div>
                <h3>{intl.formatMessage(cart_translations.continue_without_signin)}</h3>
                <dl>
                    <dt>{intl.formatMessage(cart_translations.connexion_visitor)}</dt>
                    <dd>
                        <LocaleLink to="/checkout/information" className="btn-red">
                            {intl.formatMessage(globalTranslations.continue)}
                        </LocaleLink>
                    </dd>
                </dl>
            </div>
        </div>
    )
};

export default CartAccount;
