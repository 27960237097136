import React, { Dispatch, SetStateAction, useEffect, useReducer, useState, } from "react";
import { ApolloError, useQuery } from "@apollo/client";
import { useVisitor } from "@ct-react/visitor";
import CheckOutStepper from "../../components/cart/checkout-stepper";
import { Outlet, useMatch, useNavigate, useOutletContext } from "react-router-dom";
import { GET_CART_INFO } from "./cart-config";
import { Cart, CartForm } from "../../models/cart";
import CartFormReducer from "./cartForm.reducer";

import "./cart.scss";
import { useLocaleContext } from "@ct-react/locale";

type CheckoutContext = {
    cartData: Cart,
    setCartData: Dispatch<SetStateAction<Cart>>,
    loading: boolean,
    error: ApolloError | undefined,
    refetch: () => void,
    sessionId: string,
    userId: string,
    customer: CartForm,
    setCustomer: React.Dispatch<Action>,
};

type Action={
    type: string;
    value: any;
};

const initialFormData:CartForm = {
    name: "",
    nameValid: false,
    surname: "",
    surnameValid: false,
    email: "",
    emailValid: false,
    tel: "",
    telValid: false,
    street: "",
    streetValid: false,
    streetNbr: "",
    complementaryAdress: "",
    npa: "",
    npaValid: false,
    city: "",
    cityValid: false,
    country: "",
    countryValid: ""
};

const Checkout = () => {
    const { sessionId, userId } = useVisitor();
    const [cartData, setCartData] = useState<Cart | null>(null);
    const [customer, setCustomer] = useReducer<(formData: CartForm, action: Action) => any>(CartFormReducer, initialFormData);
    const { locale } = useLocaleContext();
    const isOnPaymentCallback = useMatch(`/${locale.basename}/checkout/paiement/valide`) || useMatch(`/${locale.basename}/checkout/paiement/invalide`);
    const navigate = useNavigate();

    const { loading, error, refetch } = useQuery<{cart: Cart}>(GET_CART_INFO, {
        variables: {
            sessionId : sessionId,
            userId: userId
        },
        onCompleted: (data) => {
            if(!!data.cart){
                setCartData(data.cart);
            }else if(isOnPaymentCallback){
                return;
            }else{
                navigate(`/${locale.basename}/checkout/panier`);
            }
        }
    });

    const contextValue = {
        cartData,
        setCartData,
        loading,
        error,
        refetch,
        sessionId,
        userId,
        customer,
        setCustomer
    };

    return (
        <div className="cart">
            <CheckOutStepper/>
            <Outlet context={contextValue}/>
        </div>
    );
};

export function useCartData() {
    return useOutletContext<CheckoutContext>();
}

export default Checkout;
