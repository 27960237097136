import {defineMessages} from "react-intl";

export const footerTranslations = defineMessages({
  intranet: { id: "footer-intranet", defaultMessage: "Intranet propriétaire" },
  terms: { id: "footer-terms-label", defaultMessage: "dispositions contractuelles générales" },
  mentions: { id: "footer-mentions-label", defaultMessage: "mentions légales" },
  policy: { id: "footer-policy-label", defaultMessage: "politique de confidentialité" },
  legal: { id: "footer-legals", defaultMessage: "L'utilisation de ce site constitue l'acceptions des {terms}, des {mentions} et de la {policy} du site." },
  copyright: { id: "footer-copyright", defaultMessage: "© {year} Montan'Agence SA - développé par {ct} & UX/UI design par {ux}" }
});
