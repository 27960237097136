import React from "react";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import { AnyCartItem, OptionPrice, OptionType } from "../../models/cart";
import { bookingTranslations, buttonsTranslations } from "../../i18n/sharable-defs";

import "./cart-item.scss";
import { cart_item_translations } from "./translations";
import { useLocaleFormatter } from "@ct-react/locale";

type CartItemProps = {
    item: AnyCartItem,
    onRemove : (id: string) => void,
};

const CartItem = ({
    item,
    onRemove
}: CartItemProps)=>{

    const intl = useIntl();
    const { print } = useLocaleFormatter();

    return(
        <article key={item.itemId} className="cart-item">
            <div className="item-title">
                <h2>{item.title}</h2>
            </div>
            <div className="item-img">
                <img src={item.image}/>
            </div>
            <div className="item-price">
                <h3>{intl.formatMessage(cart_item_translations.summary)}</h3>
                <div>
                    <p>{intl.formatMessage(cart_item_translations.price_amount)}</p>
                    <span>{print.price(item.price.bare)}</span>
                </div>

                {item.price.options.map((option: OptionPrice) => {
                        if(option.type === OptionType.BENEFIT){
                            return (
                                <div key={option.type}>
                                    <p>{intl.formatMessage(bookingTranslations.benefits)}</p>
                                    <span>{print.price(option.amount)}</span>
                                </div>
                            );
                        }
                        if(option.type === OptionType.TOURIST_TAX){
                            return (
                                <div key={option.type}>
                                    <p>{intl.formatMessage(bookingTranslations.tourist_tax)}</p>
                                    <span>{print.price(option.amount)}</span>
                                </div>
                            );
                        }
                    })
                }

                {!!item.price.discount &&
                    <div>
                        <p>{intl.formatMessage(bookingTranslations.reduction)}</p>
                        <span className="discount">{print.price(item.price.discount)}</span>
                    </div>
                }

                <div className="total">
                    <p>{intl.formatMessage(bookingTranslations.total)}</p>
                    <span>{print.price(item.price.amount)}</span>
                </div>

                {/*item.price.amount != item.price.downPayment &&
                    <div className="cart-price-account">
                        <p>{intl.formatMessage(cart_item_translations.price_immediate_account)}</p>
                        <span>{print.price(item.price.downPayment)}</span>
                    </div>
                */}
            </div>
            <div className="item-details">
                <p>
                  <FontAwesomeIcon icon={faCalendar}/>
                  {print.period(item.period, true)}
                </p>
                <button className="btn-red" onClick={() => onRemove(item.itemId)}>
                    {intl.formatMessage(buttonsTranslations.delete)}
                </button>
            </div>
        </article>
    );
};

export const Skeletonitem = () => {
    return(
        <article className="cart-item skeleton">
            <div className="item-title">
            <Skeleton count={1} width={"50%"} height={"2rem"}/>
            </div>
            <Skeleton className="item-img-skeleton"/>
            <div className="item-price">
                <Skeleton count={3} className="skeleton-card-title"/>
            </div>
            <div className="item-details">
                <p>
                    <Skeleton count={1} width={"5rem"}/>
                    <Skeleton count={1} width={"10rem"}/>
                </p>
                <Skeleton className="skeleton-card-btn"/>
            </div>
        </article>
    );
};

export default CartItem;
