import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useIntl } from "react-intl";
import { IconCheck, IconLoader3, IconX } from "@tabler/icons-react";
import { classNames } from "@ct-react/core";
import { UpdateUser, User } from "@shared/models/user";
import { formTranslations, globalTranslations } from "../../i18n/sharable-defs";
import { useCountriesList } from "../../hooks/countries";
import { CartForm } from "../../models/cart";
import "./forms.scss";
import ProfileFormReducer, { Action } from "./forms.reducer";

type UserProfileFormProps = {
  user?: User;
  update: (val: UpdateUser) => Promise<User>;
};

type FormEvent = React.FormEvent<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>;

const initialFormData:CartForm = {
  name: "",
  nameValid: false,
  surname: "",
  surnameValid: false,
  email: "",
  emailValid: false,
  tel: "",
  telValid: false,
  street: "",
  streetValid: false,
  streetNbr: "",
  complementaryAdress: "",
  npa: "",
  npaValid: false,
  city: "",
  cityValid: false,
  country: "",
  countryValid: ""
};

const UserProfileForm = ({ user, update }: UserProfileFormProps) => {

  const intl = useIntl();
  const countries = useCountriesList();
  const [ isDisabled,setIsDisabled] = useState<boolean>(true);
  const [ onSaving, setOnSaving ] = useState<boolean>(false);
  const [ profile, setProfile] = useReducer<(formData: CartForm, action: Action) => any>(ProfileFormReducer, initialFormData);
  const [ formCallback, setFormCallback ] = useState<boolean | undefined>(undefined);
  const [ isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if(user || !!user){
      setProfile({
        type: "initialize",
        value: {...user},
      });
    }
  },[user])

    useEffect(() => {
        if(Object.values(profile).includes(false)){
            setIsSubmitted(false);
            setIsDisabled(true);
        }else{
            setIsDisabled(false);
        }
    },[profile]);

    useEffect(() => {
        if(!isSubmitted) return;
        if(isSubmitted && Object.values(profile).includes(false)){
            setIsSubmitted(false);
            setIsDisabled(true);
        }
    },[isSubmitted]);
  
  const handleFormData = (e:FormEvent) => {
    setProfile({
        type: e.currentTarget.name,
        value: e.currentTarget.value,
    });
  };

const handleOnsubmit = () => {
    setIsSubmitted(true);
};

  // dom interactions

  // rendering

  return (
    <form className="profile-form">
      <div>
        <h3>{intl.formatMessage(globalTranslations.profile_title)}</h3>
        <div className="user-part">

            <div className="input-block surname">
                <label htmlFor="surname">{`${intl.formatMessage(formTranslations.firstNameLabel)}*`}</label>
                <input
                    id="surname"
                    type="text"
                    name="surname"
                    value={profile.surname}
                    placeholder={intl.formatMessage(formTranslations.firstNamePlaceholder)}
                    required
                    onInput={handleFormData}
                />
                <span>{profile.surname === "" || !profile.surnameValid && intl.formatMessage(formTranslations.emptyError)}</span>
            </div>

            <div className="input-block name">
                <label htmlFor="name">{`${intl.formatMessage(formTranslations.lastNameLabel)}*`}</label>
                <input
                    id="name"
                    type="text"
                    name="name"
                    value={profile.name}
                    placeholder={intl.formatMessage(formTranslations.lastNamePlaceholder)}
                    required
                    onInput={handleFormData}
                />
                <span>{profile.name === "" || !profile.nameValid && intl.formatMessage(formTranslations.emptyError)}</span>
            </div>

            <div className="input-block email">
                <label htmlFor="email">{`${intl.formatMessage(formTranslations.emailLabel)}*`}</label>
                <input
                    id="email"
                    type="email"
                    name="email"
                    value={profile.email}
                    placeholder={intl.formatMessage(formTranslations.emailPlaceholder)}
                    required
                    onInput={handleFormData}
                />
                <span>{profile.email === "" || !profile.emailValid && intl.formatMessage(formTranslations.mailError)}</span>
            </div>

            <div className="input-block tel">
                <label htmlFor="tel">{`${intl.formatMessage(formTranslations.phoneLabel)}*`}</label>
                <input
                    id="tel"
                    type="tel"
                    name="tel"
                    value={profile.tel}
                    placeholder={intl.formatMessage(formTranslations.phonePlaceholder)}
                    onInput={handleFormData}
                />
                <span>{profile.tel === "" || !profile.telValid && intl.formatMessage(formTranslations.phoneError)}</span>
            </div>
        </div>
        <div className="address-part">
            
            <div className="input-block street">
                <label htmlFor="street">{`${intl.formatMessage(formTranslations.street)}*`}</label>
                <input
                    id="street"
                    type="text"
                    name="street"
                    value={profile.street}
                    placeholder={intl.formatMessage(formTranslations.streetPlaceholder)}
                    onInput={handleFormData}
                />
                <span>{profile.streetValid === "" || !profile.streetValid && intl.formatMessage(formTranslations.emptyError)}</span>
            </div>

            <div className="input-block streetNbr">
                <label htmlFor="street">N°</label>
                <input
                    id="street-number"
                    type="text"
                    name="street-number"
                    value={profile.streetNbr}
                    placeholder={intl.formatMessage(formTranslations.streetNumberPlaceholder)}
                    onInput={handleFormData}
                />
            </div>

            <div className="input-block complementaryAdress">
                <label htmlFor="complementaryAdress">{`${intl.formatMessage(formTranslations.streetComplement)}*`}</label>
                <input
                    id="complementaryAdress"
                    type="text"
                    name="complementaryAdress"
                    value={profile.complementaryAdress}
                    placeholder={intl.formatMessage(formTranslations.streetComplementPlaceholder)}
                    onInput={handleFormData}
                />
            </div>

            <div className="input-block npa">
                <label htmlFor="street">NPA</label>
                <input
                    id="npa"
                    type="text"
                    name="npa"
                    value={profile.npa}
                    placeholder={intl.formatMessage(formTranslations.zipPlaceholder)}
                    onInput={handleFormData}
                />
                <span>{profile.npa === "" || !profile.npaValid && intl.formatMessage(formTranslations.emptyError)}</span>
            </div>

            <div className="input-block city">
                <label htmlFor="street">{`${intl.formatMessage(formTranslations.city)}*`}</label>
                <input
                    id="city"
                    type="text"
                    name="city"
                    value={profile.city}
                    placeholder={intl.formatMessage(formTranslations.cityPlaceholder)}
                    onInput={handleFormData}
                />
                <span>{profile.city === "" || !profile.cityValid && intl.formatMessage(formTranslations.emptyError)}</span>
            </div>

            <div className="input-block country">
                <label htmlFor="country">{`${intl.formatMessage(formTranslations.country)}*`}</label>
                <select name="country"
                        id="country"
                        required>
                <option value="">{intl.formatMessage(formTranslations.countryPlaceholder)}</option>
                {Object.entries(countries).map(([ val, label ], i) =>
                    <option key={i} value={val} selected={profile.country === val}>{label}</option>
                )}
                </select>
                {profile.country ==="" || profile.country === null &&
                  <span>{intl.formatMessage(formTranslations.emptyError)}</span>
                }
            </div>
        </div>

        <div className="connexion-form-buttons">
          <button className="btn-red" type="button" onClick={handleOnsubmit} disabled={isDisabled}>
              {intl.formatMessage(globalTranslations.save)}
          </button>
        </div>
      </div>
    </form>
  );

}

export default UserProfileForm;
