import {defineMessages} from "react-intl";

export const card_translations = defineMessages({
    equipment_spaces: {id: "equipment-space", defaultMessage: "{count, plural, =0 {Pas de places} one {# place} other {# places}}",},
    equipment_rooms: {id: "equipment-room", defaultMessage: "{count, plural, =0 {Pas de données} one {# Pièce} other {# Pièces}}",},
    equipment_beds: {id: "equipment-beds", defaultMessage: "{count, plural, =0 {Pas de données} one {# lit} other {# lits}}",},
    equipment_wifi: {id: "equipment-wifi", defaultMessage: "Wifi disponible",},
    recommended: {id: "recommended", defaultMessage: "Coup de coeur",},
    novelty: {id: "novelty", defaultMessage: "Nouveauté",},
    promoted: {id: "promoted", defaultMessage: "Exclusivité",}
});