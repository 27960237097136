import React, { useContext, useState } from "react";
import { LangLink, useLocaleContext } from "@ct-react/locale";
import { allLocales } from "@shared/locales";
import { MenuContext } from "../../contexts/menu";

const Languages = (
    {
    }
    )=>{
        const { locale,setLocale } = useLocaleContext();
        const { isDown,setIsDown } = useContext(MenuContext);
        const [dropdownDown,setDropdownDown] = useState<boolean>(false);

        const handleOnMouseEnter = ()=>{
            setDropdownDown(true)
        };

        const handleOnMouseLeave = ()=>{
            setDropdownDown(false)
        };

        return(
            <>
                <div className="current-language" onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
                    <span>{locale.basename}</span>
                    <svg className="navigation-arrow"
                        style={{transform:dropdownDown?"rotate(180deg)":"rotate(0deg)"}}
                        xmlns="http://www.w3.org/2000/svg" 
                        width="0.561rem" 
                        viewBox="0 0 10.773 5.38">
                                <path d="M10.377,14.377a.768.768,0,0,1-.492-.177L5.276,10.359a.769.769,0,0,1,.983-1.183l4.117,3.441L14.494,9.3a.788.788,0,1,1,.976,1.237l-4.609,3.71a.768.768,0,0,1-.484.131Z" transform="translate(-4.999 -8.999)"/>
                    </svg>
                    {dropdownDown&&
                    <ul className={"language-list"}>
                    {allLocales.map(l =>
                        <li key={l.code} className="language whiteLine" onClick={()=>setLocale(l)}>
                            <LangLink locale={l} onClick={()=>setIsDown(false)}>
                                {l.basename}
                            </LangLink>
                        </li>
                    )}
                    </ul>
                    }
                </div>

            </>
        );
};

export default Languages;
