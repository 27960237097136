import {defineMessages} from "react-intl";

export const search_module = defineMessages({
    search_module_validate: {id: "search-module-validate", defaultMessage: "Valider"},
    search_module_type: {id: "search-module-type", defaultMessage: "Types"},
    search_module_type_quantity: {id: "search-module-type-quantity", defaultMessage: "{types, plural, =1 {# type} =0 {} other {# types}}"},
    search_module_equipment_quantity: {id: "search-module-equipment-quantity", defaultMessage: "{value, plural, =1 {# filtre} =0 {} other {# filtres}}"},
    search_module_range_price: {id: "search-module-range-price", defaultMessage: "Échelle de prix"},
    search_module_object_type: {id: "search-module-object-type", defaultMessage: "Types d'objet"},
    search_module_minRoom: {id:"search-module-choose-minRoom",defaultMessage: "Combien de pièces ?"},
    search_module_nbr_of_room: {id:"search-module-nbr-of-room",defaultMessage: "Nombre de pièces"},
    search_module_choose_surface: {id:"search-module-choose-surface", defaultMessage: "Combien de m² minimum ?"},
    search_module_choose_guests: {id:"search-module-choose-guests", defaultMessage: "Nombre de personne(s)"},
    search_module_choose_object_type: {id:"search-module-choose-object-type", defaultMessage: "Quel(s) type(s) de bien ?"},
    search_module_choose_object_equipment: {id:"search-module-choose-equipment", defaultMessage: "Choissisez vos options ?"},
    search_module_search_rental_text: {id:"search-module-search-rental-text", defaultMessage: "N'importe quand • Ajouter des personnes"},
    search_module_search_seasonal_text: {id:"search-module-search-seasonak-text", defaultMessage: "N'importe quel prix • Ajouter des personnes"},
    search_module_search_annual_text: {id:"search-module-search-annual-text", defaultMessage: "N'importe quel prix • Ajouter une surface"},
    search_module_search_sale_text: {id:"search-module-search-sale-text", defaultMessage: "N'importe quel prix • Ajouter des pièce"},
});
