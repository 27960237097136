import { gql } from "@apollo/client";

export const GET_ARTICLE = gql`
    query GET_ARTICLE(
        $articleId: ID!
    ){
    articleDetail(
        articleId : $articleId
    ) {
        ... on RentalAccommodationArticle {
            coordinates
            id
            novelty
            promoted
            slug
            objectType {
                name
            }
            address {
                city
                country
                resume
                state
                street
                streetComplement
                streetNumber
                zip
            }
            description {
                matchLang
                value
            }
            discounts {
                fixedValue
                logic
                percentageValue
                period
                type
            }
            features {
                bathrooms
                bedrooms
                isDisabledPersonCompliant
                isPetCompliant
                isSmokerCompliant
                livingSpace
                maxGuests
                orientation
                parkings
                restrooms
                rooms
                totalSpace
                withBalcony
                withCarbonAlarm
                withDishwasher
                withFireplace
                withGarage
                withLift
                withOutdoorSpace
                withParking
                withPool
                withSauna
                withSmokeAlarm
                withTerrace
                withTV
                withWashingMachine
                withWIFI
                onCityCenter
                onShoppingArea
                nearSkiArea
                withClearView
            }
            images {
                aspectRatio
                main
                alternativeText {
                    matchLang
                    value
                }
                assets {
                    breakpoint
                    height
                    url
                    width
                }
            }
            lowestPrice {
                onDemand
                type
                value
            }
            seo {
                image
                keywords
                description {
                matchLang
                value
                }
                title {
                matchLang
                value
                }
                translatableKeywords {
                key
                value
                }
            }
            title {
                matchLang
                value
            }
            touristTax {
                optionId
                grid {
                    ageCategory
                    ageRange
                    price
                }
            }
        }
        ... on SeasonalAccommodationArticle {
            coordinates
            id
            novelty
            slug
            objectType {
                name
            }
            address {
                city
                country
                resume
                state
                street
                streetComplement
                streetNumber
                zip
            }
            description {
                matchLang
                value
            }
            features {
                bathrooms
                bedrooms
                livingSpace
                orientation
                parkings
                restrooms
                rooms
                totalSpace
                withBalcony
                withDishwasher
                withFireplace
                withGarage
                withLift
                withOutdoorSpace
                withParking
                withPool
                withSauna
                withTerrace
                withTV
                withWashingMachine
                withWIFI
                withClearView
            }
            images {
                aspectRatio
                main
                alternativeText {
                matchLang
                value
                }
                title {
                matchLang
                value
                }
                assets {
                breakpoint
                height
                url
                width
                }
            }
            price {
                isMonthly
                onDemand
                period
                value
            }
            seo {
                image
                keywords
                description {
                matchLang
                value
                }
                title {
                matchLang
                value
                }
                translatableKeywords {
                key
                value
                }
            }
            title {
                matchLang
                value
            }
        }
        ... on AnnualAccommodationArticle {
            coordinates
            id
            slug
            objectType {
                name
            }
            address {
                city
                country
                resume
                state
                street
                streetComplement
                streetNumber
                zip
            }
            description {
                matchLang
                value
            }
            features {
                bathrooms
                bedrooms
                isFurnished
                isNew
                livingSpace
                orientation
                parkings
                restrooms
                rooms
                totalSpace
                withBalcony
                withDishwasher
                withFireplace
                withGarage
                withLift
                withOutdoorSpace
                withParking
                withPool
                withSauna
                withTerrace
                withWashingMachine
                withClearView
            }
            images {
                aspectRatio
                main
                alternativeText {
                matchLang
                value
                }
                assets {
                breakpoint
                height
                url
                width
                }
            }
            novelty
            price {
                availableAt
                onDemand
                value
            }
            seo {
                image
                keywords
                description {
                matchLang
                value
                }
                title {
                matchLang
                value
                }
                translatableKeywords {
                key
                value
                }
            }
            title {
                matchLang
                value
            }
        }
        ... on SaleAccommodationArticle {
            coordinates
            id
            novelty
            slug
            objectType {
                name
            }
            address {
                city
                country
                resume
                state
                street
                streetComplement
                streetNumber
                zip
            }
            description {
                matchLang
                value
            }
            features {
                bathrooms
                bedrooms
                charges
                isFurnished
                isNew
                isSellableToForeigners
                livingSpace
                orientation
                parkings
                renovationFund
                restrooms
                rooms
                totalSpace
                withBalcony
                withDishwasher
                withFireplace
                withGarage
                withLift
                withOutdoorSpace
                withParking
                withPool
                withSauna
                withTerrace
                withWashingMachine
                workingCapitalFund
                nearSkiArea
                onCityCenter
                onShoppingArea
                constructionYear
                withClearView
                floor {
                    matchLang
                    value
                }
            }
            images {
                aspectRatio
                main
                alternativeText {
                    matchLang
                    value
                }
                assets {
                    breakpoint
                    height
                    url
                    width
                }
            }
            plans {
                aspectRatio
                main
            }
            price {
                onDemand
                value
            }
            seo {
                image
                keywords
                description {
                matchLang
                value
                }
                title {
                matchLang
                value
                }
                translatableKeywords {
                key
                value
                }
            }
            title {
                matchLang
                value
            }
        }
    }
}
`;
