import React, { FunctionComponent, } from "react";

import "./loaders.scss";

export const Loader = () => {

    return(
        <div className="loader">
            <svg viewBox="25 25 50 50">
                <circle r="20" cy="50" cx="50"></circle>
            </svg>
        </div>
    )
};

