import { gql } from "@apollo/client";
import NbrGuests from "../../models/guests";

export const POLL_BOOKING_CONFIG_GQL_DATA = gql`
    query GET_BOOKING_ACCOMODATION(
        $articleId: ID!
    ){
        bookingAccommodationConfig(articleId: $articleId) {
            blocks
            period
            starters {
                dayOffset
                startingAt
                enders {
                    bookable
                    endingAt
                    price
                }
            }
        }
    }
`;

export const BOOKING_ACCOMODATION_PRICE = gql`
    query GET_BOOKING_ACCOMODATION_PRICE(
        $articleId: ID!,
        $period: DatePeriod!
    ){
        bookingAccommodationPriceDetail(
            articleId: $articleId,
            period: $period
            ){
            bookable
            period
            priceOnDemand
            price {
                amount
                detailed {
                    bare
                    discount
                    providerCharge {
                        adaptive
                        amount
                    }
                }
            }
        }
    }
`;

export const ADD_CART_ITEM = gql`
    mutation ADD_CART_ITEM(
        $sessionId: String
        $userId: String
        $articleId: UUID!
        $period: DatePeriod!
        $price: DetailedPriceInput!
        $participantsCount: [TaxableAgeCategoryInput!]!
        $optionId: UUID!
        $taxPrice: Price!
    ){
        cartAddItem(
            sessionId: $sessionId
            userId: $userId
            item: {
                rentalAccommodation: {
                    articleId: $articleId
                    period: $period
                    price: $price
                }}
            options: {
                touristTax: {
                    optionId: $optionId
                    price: $taxPrice
                    participantsCount: $participantsCount
                }
            }
        ) {
            cartId
            fullPrice {
                amount
                discount
            }
            item {
                ... on RentalAccommodationCartItem {
                    description
                    expirationDate
                    image
                    itemId
                    period
                    title
                    price {
                        amount
                        bare
                        discount
                        options {
                            amount
                            type
                        }
                    }
                    downPayment { rate providerCharge }
                }
            }
        }
    }
`;

export const toGQLGuests = (guests: NbrGuests): [TaxableAgeCategoryInput] =>  {
    let arr: any = [];
    Object.entries(guests).map((item: any) => {
        arr.push({
            ageCategory: item[0].toUpperCase(),
            number: item[1]
        });
    });
    return arr;
};

type TaxableAgeCategoryInput = {
    ageCategory: AgeCategory,
    number: number
};

enum AgeCategory {
    ADULT = "ADULT",
    CHILD = "CHILD",
    EXEMPT = "EXEMPT",
    BABY = "BABY",
    SENIOR = "SENIOR",
};
