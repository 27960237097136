import { AnyPeriod, AnyPrice } from "@ct-react/core";

export type Cart = {
    cartId: string;
    items: AnyCartItem[];
    fullPrice: CartPrice;
};

type RentalAccommodationCartItem = {
    period: AnyPeriod;
    itemId: string;
    title: string;
    description: string;
    image: string;
    expirationDate: string;
    price: CartItemPrice
    downPayment: { rate: number; providerCharge?: AnyPrice }
};

export type AnyCartItem = RentalAccommodationCartItem;

type CartPrice = {
    amount: AnyPrice;
    discount: AnyPrice;
};

type CartItemPrice = {
    bare: AnyPrice;
    discount?: AnyPrice;
    options: OptionPrice[];
    amount: AnyPrice;
};

export type OptionPrice = {
    type: string;
    amount: AnyPrice;
};

export enum OptionType {
    BENEFIT = "BENEFIT",
    TOURIST_TAX = "TOURIST_TAX"
}

export type CartForm = {
    name:string,
    nameValid:boolean,
    surname:string,
    surnameValid:boolean,
    email:string,
    emailValid:boolean,
    tel:string,
    telValid:boolean,
    street:string,
    streetValid:boolean,
    streetNbr:string,
    complementaryAdress:string,
    npa:string,
    npaValid:boolean,
    city:string,
    cityValid:boolean,
    country:string,
    countryValid:string,
};
