import { ImageAsset } from "@shared/models/article";

type Field = {
  type: string;
  slug: string;
}

type TranslatableText = {
  languageCode: string;
  description: string;
}

export type CmsGroupField = Omit<Field, "type"> & {
  type: "Group";
  fields: AnyField[][];
}

export type CmsTextField = Omit<Field, "type"> & {
  type: "Text";
  text: TranslatableText[];
};

export type CmsHtmlField = Omit<Field, "type"> & {
  typer: "Html";
  html: TranslatableText[];
}

export type CmsImageField = Omit<Field, "type"> & {
  type: "Image";
  originalUrl: string;
  alternativeText?: TranslatableText[];
  assets: [ImageAsset];
  aspectRatio: string
}

export type AnyField = CmsTextField | CmsHtmlField | CmsImageField;
type AnyMainField = CmsGroupField | AnyField;

export type Page = {
  id: string,
  publishedDate: string;
  updatedDate?: string;
  data: AnyMainField[];
}

export const isCmsGroupField = (field?: AnyMainField): field is CmsGroupField => !!field && "fields" in field;

export const cmsField = <T extends AnyMainField>(data: AnyMainField[] = [], slug: string): T | undefined => {
  const search = data.find(field => field.slug === slug);
  return !!search ? search as T : undefined;
}

export const cmsTranslatableValue = (val: TranslatableText[] = [], lang: string) =>
  val?.find((val: TranslatableText) => val.languageCode.toLowerCase() === lang)?.description || "";
