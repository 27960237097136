
export default class NbrGuests {
    adult: number;
    child: number;
    exempt: number;
    constructor(
    ){
        this.adult = 0;
        this.child = 0;
        this.exempt = 0;
    }
};