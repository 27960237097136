import React, { MouseEvent, ReactNode, useRef } from "react";
import gsap from "gsap";
import { classNames } from "@ct-react/core";
import { useIntl } from "react-intl";
import { search_module } from "./translations";
import { Button } from "../../components/buttons/buttons";

import { buttonsTranslations } from "../../i18n/sharable-defs";
import "./subInput.scss";

type SubInputProps={
    children: ReactNode,
    title: string | ReactNode,
    type: string,
    onReset: (id:string) => void,
    modulePosition: "down"|"up",
    className?: string,
    inFilterModule?: boolean
};


const SubInput = ({
    type,
    title,
    onReset,
    children,
    modulePosition,
    className,
    inFilterModule = false
}: SubInputProps)=>{

    const intl = useIntl();
    const inputRef = useRef(null);
    
    const reset = (e:React.FormEvent<any>) => {
        onReset(e.currentTarget.id);
    };

    const hide = () => {
        if(inputRef){
            gsap.to(inputRef.current,{autoAlpha:0,y:"-10%",duration:0,});
            gsap.to(inputRef.current,{autoAlpha:0,y:"0%",duration:0,delay:1,clearProps: "y,visibility,opacity"});
        }
    };
    const subInputPosition = modulePosition === "down"? { bottom: "126%", top: "inherit" } : { top: "126%", bottom: "inherit" };
    const arrowPosition = modulePosition === "down"? { bottom: "-0.6vw", top: "inherit" } : { top: "-0.6vw", bottom: "inherit" };
    const hideArrowPosition = modulePosition === "down"? { bottom: "0vw", top: "inherit" } : { top: "0vw", bottom: "inherit" };

    return(
        <div    className={classNames("subInput",className)}
                style={inFilterModule? undefined : subInputPosition}
                ref={inputRef}
        >
            <div className="subInput-arrow" style={inFilterModule? undefined : arrowPosition}></div>
            <div className="subInput-hideArrow" style={inFilterModule? undefined : hideArrowPosition}></div>
            <h4 className="subInput-title">{title}</h4>
            <div className="subInput-inputs">
                {children}
            </div>
            <div className="subInput-validation">
                <Button id={type+"Reset"} onClick={(e) => reset(e)}>
                    {intl.formatMessage(buttonsTranslations.reset)}
                </Button>
                <button className="btn-red" onClick={hide} >{intl.formatMessage(search_module.search_module_validate)}</button>
            </div>
        </div>
    );
}

export default SubInput;
