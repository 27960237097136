import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import { castPrice, classNames, useAppConfig } from "@ct-react/core";
import { LocaleLink, useLocaleFormatter, useLocaleNavigate, useLocaleUrl } from "@ct-react/locale";
import { calculateCartPaymentAmount, CartPaymentAmount, CartPaymentMode } from "@ct-react/calendar";
import { useCartData } from "./cart";
import { cart_translations } from "./translations";
import { CART_CHECK_OUT } from "./cart-config";
import { Loader } from "../../components/loaders/loaders";
import { bookingTranslations } from "../../i18n/sharable-defs";
import "./cart-payment.scss";

const CartPayment = () => {

    const intl = useIntl();
    const { print } = useLocaleFormatter();
    const { baseUrl } = useAppConfig();
    const navigate = useLocaleNavigate();
    const { buildLocaleHref } = useLocaleUrl();
    const { cartData, sessionId, loading: cartLoading, userId, customer } = useCartData();

    const [ loading, setLoading ] = useState(true);
    const [ fullPayment, setFullPayment ] = useState<CartPaymentAmount>();
    const [ partialPayment, setPartialPayment ] = useState<CartPaymentAmount>();
    const [ paymentMethod, setPaymentMethod ] = useState<CartPaymentMode | undefined>(undefined);
    const [ isSubmitting, setIsSubmitting ] = useState(false);

    const [ cartCheckout, { loading: mutationLoading } ] = useMutation(CART_CHECK_OUT);

    // handle redirection regarding data
    useEffect(() => {
      (!!cartData && cartData.items.length === 0) && navigate("/checkout/panier")
      Object.values(customer).includes(false) && navigate("/checkout/connexion");
    },[ customer, cartData ]);

    // calculate all payment details options
    useEffect(() => {
      if (!cartData || cartData.items.length < 1) return;
      if (!paymentMethod) setPaymentMethod("full");
      setFullPayment(calculateCartPaymentAmount(cartData.items, "full"));
      setPartialPayment(calculateCartPaymentAmount(cartData.items, "partial"));
    }, [ cartData ]);

    // handle loading regarding needed data
    useEffect(() => {
      setLoading(cartLoading || !fullPayment || !partialPayment || !paymentMethod);
    }, [ cartLoading, fullPayment, partialPayment, paymentMethod ]);


    const paymentDetail = useMemo(() => paymentMethod === "full" ? fullPayment : partialPayment, [ paymentMethod ]);

    const partialDiff = useMemo(() => {
      if (!fullPayment || !partialPayment) return undefined;
      const castedFull = castPrice(fullPayment!.total);
      const castedPartial = castPrice(partialPayment!.total);
      return {
        amount: castedFull.amount - castedPartial.amount,
        currency: castedPartial.currency
      };
    }, [ fullPayment, partialPayment ]);

    const onPay = (mode: CartPaymentMode) => { //Avoid to press several times the button and do multiple mutations
        setIsSubmitting(true);
        try {
            cartCheckout({
                variables: {
                    sessionId : sessionId,
                    userId : userId,
                    customer: {
                        address: {
                        city: customer.city,
                        country: customer.country,
                        state: null,
                        street: customer.street,
                        streetNumber: customer.streetNbr,
                        zip: customer.npa,
                        streetComplement: customer.complementaryAdress,
                        },
                        email: customer.email,
                        firstName: customer.surname,
                        lastName: customer.name,
                        phone: customer.tel
                    },
                    hasFullPayment: mode === "full",
                    redirect: {
                        baseUrl,
                        successPath: buildLocaleHref("/checkout/paiement/valide"),
                        cancelPath: buildLocaleHref("/checkout/paiement/invalide")
                    }
                }
            })
            .then(({ data: { cartCheckout } }) => !cartCheckout
            ? navigate("/cart/payment/valide")
            : window.location.href = cartCheckout.paymentUrl);
        } catch (error) {
            console.warn(error);
        }
    }

    if(loading)
        return(
          <div className="payment">
            <div>
                <Skeleton height="30px"/>
                <Skeleton height="82px" className="skeleton"/>
            </div>
            <div>
                <Skeleton height="30px"/>
                <Skeleton height="82px" className="skeleton"/>
            </div>
            <div className="payment-buttons">
                <Skeleton className="skeleton-card-btn"/>
                <Skeleton className="skeleton-card-btn"/>
            </div>
          </div>);

    return (
      <div className="payment">

        {mutationLoading && <Loader />}

        <div>
          <h3>{intl.formatMessage(cart_translations.payment_model_payment)}</h3>
          <div className={classNames({ "selected": paymentMethod === "full" })}
               onClick={() => setPaymentMethod("full")}>
            <div>
              <p>{intl.formatMessage(cart_translations.payment_pay_total)}</p>
              <span>{print.price(fullPayment!.total)}</span>
              <span className="radio"></span>
            </div>
            <p>{intl.formatMessage(cart_translations.payment_pay_now)}</p>
          </div>
          {(fullPayment!.total.amount !== partialPayment?.total.amount) &&
            <div className={classNames({ "selected": paymentMethod === "partial" })}
                 onClick={() => setPaymentMethod("partial")}>
              <div>
                <p>{intl.formatMessage(cart_translations.payment_pay_account_now)}</p>
                <span>{print.price(partialPayment!.total)}</span>
                <span className="radio"></span>
              </div>
              <p>{intl.formatMessage(cart_translations.payment_pay_account_now_2, {
                account: print.price(partialPayment!.total),
                downPayment: print.price(partialDiff!)
              })}</p>
            </div>
          }
        </div>

        <div>
          <h3>{intl.formatMessage(cart_translations.summary)}</h3>
          <div>
            <p>{intl.formatMessage({ id: "amount", defaultMessage: "Montant" })}</p>
            <span>{cartData.fullPrice && print.price(cartData.fullPrice.amount)}</span>
          </div>
          <h3>{intl.formatMessage(cart_translations.payment_amount_title)}</h3>
          <div>
            <p>{intl.formatMessage(paymentMethod === "full" ? bookingTranslations.total : bookingTranslations.downPayment)}</p>
            <span>{print.price(paymentDetail!.detailed.booking)}</span>
            {!!paymentDetail?.detailed.providerCharge &&
              <>
                <p>{intl.formatMessage(bookingTranslations.customerCharge)}</p>
                <span>{intl.formatMessage(bookingTranslations.customerChargeAmount, {
                  adaptive: false,
                  amount: print.price(paymentDetail!.detailed.providerCharge)
                })}</span>
              </>
            }
            <p>{intl.formatMessage(bookingTranslations.amount)}</p>
            <span>{print.price(paymentDetail!.total)}</span>
          </div>
        </div>

        <div className="payment-buttons">
          <LocaleLink to="/checkout/information" className="btn-red inverted">
            {intl.formatMessage(cart_translations.change_informations)}
          </LocaleLink>
          <button className="btn-red" onClick={() => onPay(paymentMethod!)} disabled={isSubmitting}>
            {intl.formatMessage(cart_translations.pay_now)}
          </button>
        </div>

      </div>);

  /*
  }else if(!loading && !!cartData){
      return(
          <div className="payment">
              {mutationLoading && <Loader/>}
              <div>
                  <h3>{intl.formatMessage(cart_translations.payment_model_payment)}</h3>

                  <div className={classNames({"selected" : paymentMethod })}
                      onClick={()=>setPaymentMethod(true)}
                      data-type="total"
                  >
                      <div>
                          <p>{intl.formatMessage(cart_translations.payment_pay_total)}</p>
                          <span>
                          {cartData.fullPrice && cartData.fullPrice.amount}
                          </span>
                          <span className="radio"></span>
                      </div>
                      <p>{intl.formatMessage(cart_translations.payment_pay_now)}</p>
                  </div>

                  {cartData.fullPrice!.amount != cartData.fullPrice!.downPayment &&
                      <div className={classNames({"selected" : !paymentMethod })}
                          onClick={()=>setPaymentMethod(false)}
                          data-type="downPayment"
                      >
                          <div>
                              <p>{intl.formatMessage(cart_translations.payment_pay_account_now)}</p>
                              <span>
                                  {cartData.fullPrice && cartData.fullPrice.downPayment}
                              </span>
                              <span className="radio"></span>
                          </div>
                          <p>
                              {!!cartData.items&& intl.formatMessage(
                                  {   id:"payment-pay-account-now-2",
                                      defaultMessage : "Payer {account} maintenant et le solde ({downPayment}) vous sera prélevé 1 mois avant votre check-in. Aucun frais supplémentaires."
                                  },
                                  {
                                      account : cartData.fullPrice!.downPayment,
                                      downPayment : (castPrice(cartData.fullPrice!.amount).amount - castPrice(cartData.fullPrice!.downPayment).amount).toFixed(2) + castPrice(cartData.fullPrice!.amount).currency
                                  }
                              )}
                          </p>
                      </div>
                  }
              </div>

              <div>
                  <h3>{intl.formatMessage({id:"price-detail",defaultMessage : "Détails du prix"})}</h3>
                  <div>
                      <p>{intl.formatMessage({id:"amount",defaultMessage : "Montant"})}</p>
                      <span>
                          {cartData.fullPrice && print.price(cartData.fullPrice.amount)}
                      </span>
                  </div>
                  {
                      cartData.fullPrice!.amount != cartData.fullPrice!.downPayment &&
                      <div>
                          <p>{intl.formatMessage({id:"immediate-account",defaultMessage : "Acompte immédiat"})}</p>
                          <span>
                              {cartData.fullPrice && print.price(cartData.fullPrice.downPayment)}
                          </span>
                      </div>
                  }
              </div>

              <div className="payment-buttons">
                  <LocaleLink to="/checkout/information" className="btn-red inverted">
                      {intl.formatMessage(cart_translations.change_informations)}
                  </LocaleLink>
                  {renderSubmitButton}
              </div>
          </div>
      );
  }*/

};

export default CartPayment;
