import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useAppConfig } from "@ct-react/core";
import { useIntl } from "react-intl";
import { useMutation } from "@apollo/client";
import { DefaultContactFormData, DefaultNewsletterFormData } from "../../models/forms.data";
import { contactFormReducer } from "./forms.reducer";
import { RedButton } from "../buttons/buttons";
import { LocaleLink } from "@ct-react/locale";
import { Loader } from "../loaders/loaders";
import PopUp from "../pop-up/pop-up";
import { buttonsTranslations, formTranslations, genericTermTranslations } from "../../i18n/sharable-defs";
import { AccommodationType } from "@shared/models/article";
import { EMAIL_MUTATION } from "./forms.config";

import "./forms.scss";

import Chevron_down from "../../../assets/svg/chevron-down-solid.svg";
import { footerTranslations } from "../../layout/footer/translations";

const ContactFormInitialData = new DefaultContactFormData();
const NewsletterFormInitialData = new DefaultNewsletterFormData();
type FormEvent = React.FormEvent<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>;
type ContacFormProps = {
    articleId?: string | null,
    type?: AccommodationType,
    email?:string
};

export const ContactForm = ({
    articleId = undefined,
    type,
    email
}: ContacFormProps) => {

    const intl = useIntl();
    const { options: { agencyId } } = useAppConfig();
    const [formData, dispatchFormData] = useReducer<(formData: DefaultContactFormData, action: any) => DefaultContactFormData>(contactFormReducer, ContactFormInitialData);
    const [isDisabled,setIsDisabled] = useState<boolean>(true);
    const [succeeded, setSucceeded] = useState<boolean | null>(null);

    const [ sendEmail, { loading: actionLoading } ] = useMutation(EMAIL_MUTATION);

    const handleFormData = (e: FormEvent) => {
        dispatchFormData({
            type: e.currentTarget.name,
            value: e.currentTarget.value,
        });
        if(Object.values(formData).includes(false)){
            setIsDisabled(true);
        }else{
            setIsDisabled(false);
        }
    };

    //FIXME: remove my email
    const handleRecipient = () => {
        const subject = type ? type : formData.subject;
        switch (subject) {
            case ("sale"):
            case ("Acheter"):
            case ("Buy"):
            case ("Mettre en vente"):
            case ("Put on sale"):
                return "vente@montanagence.ch";
            break;

            case ("weekly"):
            case ("annual"):
            case ("seasonal"):
            case ("Louer"):
            case ("Rent"):
            case ("Mettre en location"):
            case ("Put on rent"):
                return "location@montanagence.ch";
            break;
            default:
                return "location@montanagence.ch";
            break;
        }
    };

    const handleSubject = () => {
        type === AccommodationType.SALE && intl.formatMessage(genericTermTranslations.sale);
        type === AccommodationType.RENTAL && intl.formatMessage(genericTermTranslations.locationHolidays);
        type === AccommodationType.SEASONAL && intl.formatMessage(genericTermTranslations.locationSeasonal);
        type === AccommodationType.ANNUAL && intl.formatMessage(genericTermTranslations.locationYearly);
    };

    useEffect(() => {
        if(Object.values(formData).includes(false)){
            setIsDisabled(true)
        }else{
            setIsDisabled(false)
        }
    },[formData]);

    const formCallback = useMemo(() => {

        if(succeeded != null) {
            return(
                <PopUp succeeded={succeeded} setState={() => setSucceeded(null)}>
                    <h3>
                        {succeeded?
                        intl.formatMessage(formTranslations.sent)
                        :
                        intl.formatMessage(formTranslations.failed)
                        }
                    </h3>
                    <p>
                        {succeeded?
                        intl.formatMessage(formTranslations.sent_text)
                        :
                        intl.formatMessage(formTranslations.failed_text)
                        }
                    </p>
                    <div className="form-result-buttons">
                        <button className="btn-red inverted" onClick={() => setSucceeded(null)}>
                            {intl.formatMessage(buttonsTranslations.close)}
                        </button>
                        {succeeded ?
                        <LocaleLink className="btn-red" to={"/"}>
                            {intl.formatMessage(buttonsTranslations.back_home)}
                        </LocaleLink>
                        :
                        <LocaleLink className="btn-red" to={"contact"}>
                            {intl.formatMessage(buttonsTranslations.retry)}
                        </LocaleLink>
                        }
                    </div>
                </PopUp>
            );
        }
        return undefined;
    },[succeeded])

    const handleSubmit = (e:FormEvent) => {
        dispatchFormData({
            type: "reset",
        });

        sendEmail({
            variables: {
              agencyId,
              firstName : formData.surname,
              lastName : formData.name,
              email : formData.email,
              phone : formData.tel,
              subject : formData.subject != "" ? formData.subject : handleSubject(),
              body : formData.message,
              articleId,
              emailAddress : !!email ? email : handleRecipient()
            }
        })
        .then(() => setSucceeded(true))
        .catch(() => setSucceeded(false));

    };

    return(
        <>
            {actionLoading&& <Loader/>}
            <form id="contact-form" className="contact-form">

                <div className="input-block surname">
                    <label htmlFor="surname">{`${intl.formatMessage(formTranslations.firstNameLabel)}*`}</label>
                    <input
                        id="surname"
                        type="text"
                        name="surname"
                        value={formData.surname}
                        placeholder={intl.formatMessage(formTranslations.firstNamePlaceholder)}
                        required
                        onInput={handleFormData}
                    />
                    <span>{formData.surname === "" || !formData.surnameValid && intl.formatMessage(formTranslations.emptyError)}</span>
                </div>

                <div className="input-block name">
                    <label htmlFor="name">{`${intl.formatMessage(formTranslations.lastNameLabel)}*`}</label>
                    <input
                        id="name"
                        type="text"
                        name="name"
                        value={formData.name}
                        placeholder={intl.formatMessage(formTranslations.lastNamePlaceholder)}
                        required
                        onInput={handleFormData}
                    />
                    <span>{formData.name === "" || !formData.nameValid && intl.formatMessage(formTranslations.emptyError)}</span>
                </div>

                <div className="input-block email">
                    <label htmlFor="email">{`${intl.formatMessage(formTranslations.emailLabel)}*`}</label>
                    <input
                        id="email"
                        type="email"
                        name="email"
                        value={formData.email}
                        placeholder={intl.formatMessage(formTranslations.emailPlaceholder)}
                        required
                        onInput={handleFormData}
                    />
                    <span>{formData.email === "" || !formData.emailValid && intl.formatMessage(formTranslations.mailError)}</span>
                </div>

                <div className="input-block tel">
                    <label htmlFor="tel">{`${intl.formatMessage(formTranslations.phoneLabel)}*`}</label>
                    <input
                        id="tel"
                        type="tel"
                        name="tel"
                        value={formData.tel}
                        placeholder={intl.formatMessage(formTranslations.phonePlaceholder)}
                        onInput={handleFormData}
                    />
                    <span>{formData.tel === "" || !formData.telValid && intl.formatMessage(formTranslations.phoneError)}</span>
                </div>

                {articleId === undefined &&
                <div className="input-block subject">
                    <label htmlFor="subject">{`${intl.formatMessage(formTranslations.subjectLabel)}*`}</label>
                    <select id="subject" name="subject" required onInput={handleFormData} defaultValue={""}>
                        <option value="" disabled>{intl.formatMessage(genericTermTranslations.choose_option)}</option>
                        <option value={intl.formatMessage(genericTermTranslations.other)}>{intl.formatMessage(genericTermTranslations.other)}</option>
                        <option value={intl.formatMessage(genericTermTranslations.buy)}>{intl.formatMessage(genericTermTranslations.buy)}</option>
                        <option value={intl.formatMessage(genericTermTranslations.put_on_sale)}>{intl.formatMessage(genericTermTranslations.put_on_sale)}</option>
                        <option value={intl.formatMessage(genericTermTranslations.location)}>{intl.formatMessage(genericTermTranslations.location)}</option>
                        <option value={intl.formatMessage(genericTermTranslations.put_on_rent)}>{intl.formatMessage(genericTermTranslations.put_on_rent)}</option>
                        <option value={intl.formatMessage(genericTermTranslations.promotion)}>{intl.formatMessage(genericTermTranslations.promotion)}</option>
                        <option value={intl.formatMessage(genericTermTranslations.domiciliation)}>{intl.formatMessage(genericTermTranslations.domiciliation)}</option>
                        <option value={intl.formatMessage(genericTermTranslations.managment_cooproprety)}>{intl.formatMessage(genericTermTranslations.managment_cooproprety)}</option>
                    </select>
                    <Chevron_down/>
                </div>
                }

                <div className="input-block message">
                    <label htmlFor="message">{`${intl.formatMessage(formTranslations.messageLabel)}*`}</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        placeholder={intl.formatMessage(formTranslations.messagePlaceholder)}
                        required
                        onInput={handleFormData}
                    >
                    </textarea>
                    <span>{formData.message === "" || !formData.messageValid && intl.formatMessage(formTranslations.emptyError)}</span>
                </div>

                <RedButton uppercase={true} type="button" disabled={isDisabled} onClick={handleSubmit}>
                    {intl.formatMessage(buttonsTranslations.send)}
                </RedButton>

                {!!articleId &&
                    <div className="legalMention">
                        <LocaleLink to={"mentions-legales"}>{intl.formatMessage(footerTranslations.mentions)}</LocaleLink>
                        <LocaleLink to={"politique-de-confidentialite"}>{intl.formatMessage(footerTranslations.policy)}</LocaleLink>
                    </div>
                }
            </form>
            {formCallback}
        </>
    )
};

export const NewsletterForm = () => {
    const intl = useIntl();
    const [email, setEmail] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setEmail(value);
        setIsDisabled(value.trim() === "");
    };

    return (
        <form
            //FIXME: Mettre le bon URL
            action="https://usX.list-manage.com/subscribe/post?u=VOTRE_ID_MAILCHIMP&id=VOTRE_LIST_ID"
            method="post"
            target="_blank"
        >
            <div className="input-block name">
                <label htmlFor="name">{`${intl.formatMessage(formTranslations.lastNameLabel)}*`}</label>
                <input
                    id="name"
                    type="text"
                    name="FNAME"
                    placeholder={intl.formatMessage(formTranslations.lastNamePlaceholder)}
                    required
                />
            </div>

            <div className="input-block email">
                <label htmlFor="email">{`${intl.formatMessage(formTranslations.emailLabel)}*`}</label>
                <input
                    id="email"
                    type="email"
                    name="EMAIL"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder={intl.formatMessage(formTranslations.emailPlaceholder)}
                    required
                />
            </div>

            <RedButton
                uppercase={false}
                type="submit"
                disabled={isDisabled}
                onClick={() => {}}
            >
                {intl.formatMessage(buttonsTranslations.subscribe)}
            </RedButton>
        </form>
    );
};

