import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import gsap from "gsap";
import { FormattedMessage, useIntl } from "react-intl";
import moment, { Moment } from "moment";
import {makeReference, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faChevronDown, faTriangleExclamation, faUsers, faXmark, faInfoCircle, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import {
  castBookingConfig, castBookingOption,
  FormattedBookingConfig, FormattedBookingOption, isBookingPrice, isBookingPriceWithCharge, isDiscountedPrice,
  RangePickerController,
  toGQLBookingPrice,
  useDefaultCalendarBlock
} from "@ct-react/calendar";
import { booking_box } from "./translations";
import { agencyTranslations, bookingTranslations, buttonsTranslations, globalTranslations } from "../../i18n/sharable-defs";
import { AnyPeriod, AnyPrice, castPeriod, castPrice, classNames, isValidPeriod, Period, Price, toGQLPeriod, toGQLPrice } from "@ct-react/core";
import { useDisplayUntilDesktop } from "../../utils/breakpoints";
import Guests from "../inputs/guests";
import { ADD_CART_ITEM, BOOKING_ACCOMODATION_PRICE, POLL_BOOKING_CONFIG_GQL_DATA, toGQLGuests } from "./booking-box.config";
import { AccommodationBookingOption } from "@shared/models/rental";
import { TouristTaxCartOption } from "@shared/models/article";
import { useSearchContext } from "../../contexts/search-module";
import NbrGuests from "../../models/guests";
import { Loader } from "../loaders/loaders";
import PopUp from "../pop-up/pop-up";

import "./booking-box.scss";
import { useVisitor } from "@ct-react/visitor";
import { LocaleLink, useLocaleFormatter } from "@ct-react/locale";
import { usePickerLabelize } from "../../hooks/calendar";

type BookingBoxProps = {
    articleId: string,
    presetPeriod?: AnyPeriod,
    presetGuests?: NbrGuests,
    touristTax?: TouristTaxCartOption,
    lowestPrice: AnyPrice,
    maxGuests: number,
    changeForm: () => void,
};

const BookingBox = ({
    articleId,
    presetPeriod: initedBookingChoice,
    presetGuests: initedGuestsChoice,
    touristTax,
    lowestPrice,
    maxGuests = 12,
    changeForm
}: BookingBoxProps) => {

    const intl = useIntl();
    const { searchCriteria } = useSearchContext();
    const { sessionId, userId } = useVisitor();
    const { print } = useLocaleFormatter();
    const calendarLabelize = usePickerLabelize();

    //Calendar config
    const initedPickerRange = !!initedBookingChoice ? castPeriod(initedBookingChoice) : undefined;
    const initedPickerFocus = !!initedBookingChoice ? undefined : "start";
    const {
        config: [ bookingConfig, setBookingConfig ],
        range: [ pickerRange, setPickerRange ],
        focus: [ pickerFocus, setPickerFocus ],
        isDayBlocked
    } = useDefaultCalendarBlock(initedPickerRange, initedPickerFocus);

    //Calendar render
    const [calendarOpen,setCalendarOpen] = useState<boolean>(false);
    const [nbrOfNights, setNbrOfNights] = useState<number>(0);
    const [bookableOnDemand, setBookableOnDemand] = useState<boolean>(false);

    //Number of Guests
    const initial_guest: NbrGuests = !!initedGuestsChoice ? initedGuestsChoice : new NbrGuests();
    const [guestsWindowOpen,setGuestsWindowOpen] = useState<boolean>(false);
    const [guests, setGuests] = useState<NbrGuests>(initial_guest);
    const [totalGuests, setTotalGuests] = useState<number>(0);
    const [maxGuestsReached, setMaxGuestsReached] = useState<boolean>(false);

    //Tourist Taxes
    const [taxCost, setTaxCost] = useState<Price>({amount:0, currency:"CHF"});

    //Price
    const [bookingPrice, setBookingPrice] = useState<FormattedBookingOption | undefined>(undefined);
    const [showPrice, setShowPrice] = useState<boolean>(false);
    const [showChargeInfo, setShowChargeInfo] = useState<boolean>(false);

    //Form
    const [formReady,setFormReady] = useState<boolean>(false);
    const [succeeded, setSucceeded] = useState<boolean | null>(null);

    //Smartphone
    const [smartphoneModuleVisible, setSmartphoneModuleVisible] = useState<boolean>(false);

    const title = () => { //Set title of calendar
        if(!pickerRange.start && !pickerRange.end){
            return intl.formatMessage(booking_box.select_arrival);
        }else if(!!pickerRange.start && !pickerRange.end){
            return intl.formatMessage(booking_box.select_departure);
        }else if(!!pickerRange.start && !!pickerRange.end){
            const nightsCount = pickerRange.end.diff(pickerRange.start,"days");
            return intl.formatMessage(bookingTranslations.nightsPeriod, {nightsCount});
        }
    };

    //REQUESTS
    //________________________________________________________

    //Request booking configuration
    const { stopPolling : stopPollingBooking, loading: optionsLoading, refetch  } = useQuery<{bookingAccommodationConfig: FormattedBookingConfig}>(POLL_BOOKING_CONFIG_GQL_DATA, {
        variables: { articleId: articleId },
        ssr: false,
        fetchPolicy: "network-only",
        pollInterval: 30 * 1000,
        onCompleted: ({bookingAccommodationConfig}) => {
            setBookingConfig(castBookingConfig(bookingAccommodationConfig))
        }
    });

    //Request booking price
    const [ getAccommodationPrice, { loading: priceLoading } ] = useLazyQuery<{bookingAccommodationPriceDetail: AccommodationBookingOption }>(BOOKING_ACCOMODATION_PRICE,{
        onCompleted:(data) => {
            if(data.bookingAccommodationPriceDetail.priceOnDemand || !data.bookingAccommodationPriceDetail.bookable ){
                setBookableOnDemand(true);
                setShowPrice(false);
                setSmartphoneModuleVisible(false);
            }else{
                setBookingPrice(castBookingOption(data.bookingAccommodationPriceDetail));
            }
        }
    });

    //Fetch booking price asynchrone
    const fetchPrice = useCallback(async (period: AnyPeriod) => await getAccommodationPrice({
        variables: {
          articleId,
          period: toGQLPeriod(period)
        }
    }), [ pickerRange ]);

    //Launch async fetching price on picker focus change and set nbrOfNights
    useEffect(() => {
        if (pickerFocus === "end") return;
        if (isValidPeriod(pickerRange) && pickerFocus === null) {
            fetchPrice(pickerRange as Period<Moment>).then();
            const nightsCount = pickerRange.end.diff(pickerRange.start,"days");
            setNbrOfNights(nightsCount);
        }
    }, [ pickerFocus ]);

    //MUTATION
    //________________________________________________________

    const [ mutateItemToCart,  { loading: mutationLoading } ] = useMutation(ADD_CART_ITEM, {
      update: (cache, { data: { cartAddItem } }) => cache.modify({
        id: cache.identify(makeReference(`Cart:${cartAddItem.cartId}`)),
        fields: {
          items: () => [ cartAddItem.item ],
          itemsCount: () => 1,
          fullPrice: () => cartAddItem.fullPrice
        }
      }),
      refetchQueries: [ "CartBadge" ],
    });

    //CALENDAR
    //________________________________________________________

    const deleteRange = () => {
        setPickerRange({});
        setPickerFocus("start");
        setBookingPrice(undefined);
        setNbrOfNights(0);
        resetForm();
    };

    //GUESTS
    //________________________________________________________

    useEffect(() => { // Set Total Guests
        setTotalGuests(calculateTotalGuests(guests));
    },[guests]);

    useEffect(() => { // Set Maximum guests reached
        if(totalGuests >= maxGuests) setMaxGuestsReached(true);
        if(totalGuests < maxGuests && maxGuestsReached) setMaxGuestsReached(false);
    },[totalGuests]);

    const calculateTotalGuests = (guests) => {
        let total = 0;
        Object.entries(guests).map((item: any) => {
            total+=item[1];
        });
        return total;
    };

    const nbrGuestsTextValue = useMemo(() => { //Set text on number of guests module
        const message:any = [];
        if(totalGuests === 0){
            message.push(intl.formatMessage(booking_box.add_guests));
        }else{
            Object.entries(guests).map((item:any)=>{
                if(item[1] === 0) return message.push("");
                item[0] === "adult" && message.push(intl.formatMessage(booking_box.nbr_adult, {adult: item[1]}));
                item[0] === "child" && message.push(intl.formatMessage(booking_box.nbr_child, {child: item[1]}));
                item[0] === "exempt" && message.push(intl.formatMessage(booking_box.nbr_exempt, {exempt: item[1]}));
            });
        }
        return message;
    },[totalGuests]);

    const resetGuests = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setGuests(new NbrGuests());
        resetForm();
    };

    const handleGuestsValidClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setGuestsWindowOpen(false);
    };

    //TOURIST TAX
    //________________________________________________________

    useEffect(() => { // Set Tourist Tax cost
        let tax = {
            amount: 0,
            currency: "CHF"
        };
        if(touristTax && !!touristTax.grid){
            Object.entries(guests).map(item => {
                //Retrieve tax grid that name correspond + quantity of guests over 0
                //If undefined return and add 0 otherwise add nbr of current guests * tax cost
                const touristGrid = touristTax.grid.find(t => (t.ageCategory.toLowerCase() === item[0] && item[1] > 0));
                let taxeAddition =  touristGrid ? castPrice(touristGrid.price).amount * item[1] : 0;
                tax.amount += taxeAddition * nbrOfNights;
                tax.currency = touristGrid ? castPrice(touristGrid.price).currency : "CHF";
            });
            setTaxCost(tax);
        }
    },[guests]);

    //PRICE RENDERING
    //________________________________________________________

    useEffect(() => { // Set Form ready
        if(pickerFocus === null && !!bookingPrice && totalGuests > 0){
            setFormReady(true);
        }else{
            setFormReady(false);
        }
    },[pickerFocus, bookingPrice, totalGuests])

    const renderBookingPrice = useMemo(() => {
        if(formReady && showPrice && !bookableOnDemand && isBookingPrice(bookingPrice?.price)) {
            const castTotalPrice = castPrice(bookingPrice!.price.amount);
            const totalPrice = {
                amount: castTotalPrice.amount + taxCost.amount,
                currency: castTotalPrice.currency
            };
            return(
                <>
                  {/* BarePrice */}
                  <p>
                    {intl.formatMessage(booking_box.total_price_nights, { night: nbrOfNights })}
                    <span>
                      {isDiscountedPrice(bookingPrice!.price)
                        ? print.price(bookingPrice!.price.amount)
                        : print.price(bookingPrice!.price.detailed.bare)
                      }
                    </span>
                  </p>
                  {/* Taxes */}
                  <p>
                    {intl.formatMessage(booking_box.taxes)}
                    <span>{print.price(taxCost)}</span>
                  </p>
                  {/* Custom charges */}
                  {isBookingPriceWithCharge(bookingPrice?.price) &&
                    <div>
                      <p className="info" onClick={() => setShowChargeInfo(!showChargeInfo)}>
                        {intl.formatMessage(bookingTranslations.customerCharge)}
                        <FontAwesomeIcon icon={faCircleInfo} />
                      </p>
                      <span>
                        {intl.formatMessage(bookingTranslations.customerChargeAmount, {
                          adaptive: bookingPrice!.price.detailed.providerCharge!.adaptive,
                          amount: print.price(bookingPrice!.price.detailed.providerCharge!.amount)
                        })}
                      </span>
                    </div>
                  }
                  {showChargeInfo &&
                    <div className="warning">
                      <p>{intl.formatMessage(bookingTranslations.booking_charge_info)}</p>
                      <div onClick={() => setShowChargeInfo(false)}>
                        <FontAwesomeIcon icon={faXmark}/>
                      </div>
                    </div>
                  }
                  {/* Reduction */}
                  {isDiscountedPrice(bookingPrice!.price) &&
                    <p>
                      {intl.formatMessage(booking_box.reduction)}
                      <span className="discount">{print.price(bookingPrice!.price.detailed.discount!)}</span>
                    </p>
                  }
                  {/* Total */}
                  <p className="total">
                    Total :<span>{print.price(totalPrice)}</span>
                  </p>
                </>
            );
        }else{
            return <></>;
        }
    },[showPrice, formReady, bookableOnDemand, bookingPrice, showChargeInfo]);

    //FORM HANDLING
    //________________________________________________________

    const resetForm = () => {
        setFormReady(false);
        setShowPrice(false);
    };

    const addToCart = () => { // TODO
        if (!bookingPrice || !bookingPrice.bookable || !isBookingPrice(bookingPrice!.price))
          throw new Error("todo: should not be addable to cart");
        mutateItemToCart({
            variables: {
                ...(!userId) && { sessionId },
                ...(!!userId) && { userId },
                articleId : articleId,
                period: toGQLPeriod(pickerRange as AnyPeriod),
                price: toGQLBookingPrice(bookingPrice!.price.detailed),
                optionId: touristTax?.optionId,
                taxPrice: toGQLPrice(taxCost),
                participantsCount: toGQLGuests(guests)
            },
        })
        .then(() => setSucceeded(true))
        .catch((err) => {
            setSucceeded(false);
        })
        .finally(() => { //Clean calendar
            setPickerFocus("start");
            setPickerRange({});
            refetch();
        });
    };

    const handleChangeDate = () => { //Handle when client choose to change date after priceOnDemand result
        setBookableOnDemand(false);
        setShowPrice(false);
        setBookingPrice(undefined);
        setCalendarOpen(true);
        setPickerFocus(null);
        setPickerRange({});
        setFormReady(false);
    };

    useEffect(() => { //Preset Period and Guests
        if(searchCriteria.guests.adult > 0){
            setGuests(searchCriteria.guests);
            setTotalGuests(calculateTotalGuests(searchCriteria.guests));
        }
        if(isValidPeriod(searchCriteria.period)){
            setPickerRange(searchCriteria.period);
            setPickerFocus(null);
            fetchPrice(pickerRange as Period<Moment>).then();
        }
        if(searchCriteria.guests.adult > 0 && isValidPeriod(searchCriteria.period)){
            setFormReady(true);
            setShowPrice(true);
        }
    },[]);

    useEffect(() => { //Reset Form when period or guest change
        if(!isValidPeriod(searchCriteria.period) && totalGuests === 0) resetForm();
        setShowPrice(false);
    },[totalGuests, pickerRange])

    //ANIMATION
    //________________________________________________________

    const calendar = useRef(null);
    const nbrGuestsModule = useRef(null);

    const handleCalendarWindow = (fixed: boolean) => {
        !fixed && setCalendarOpen(false)
    };

    useEffect(() => { //Open or close calendar
        if(!calendar.current){
            return;
        }else if(calendarOpen){
            gsap.to(calendar.current,{autoAlpha:1});
        }else{
            gsap.to(calendar.current,{autoAlpha:0});
        }
    },[calendarOpen]);

    useEffect(() => { //Open or close number of guests
        if(!nbrGuestsModule.current){
            return;
        }else if(guestsWindowOpen){
            gsap.to(nbrGuestsModule.current,{ autoAlpha:1, position: "relative", y: 0});
        }else{
            gsap.timeline()
            .to(nbrGuestsModule.current,{ autoAlpha:0, y: "-100%"},"0")
            .to(nbrGuestsModule.current,{ position: "absolute"},"<0.5");
        }
    },[guestsWindowOpen, guests]);

    //POP-UP VALIDATION

    const renderPopUpAddToCart = useMemo(() => {
        if(succeeded != null){
            return(
                <PopUp succeeded={succeeded} setState={() => setSucceeded(false)}>
                    <h3>
                        {succeeded?
                            intl.formatMessage(booking_box.form_callback_article_add_succeded)
                            :
                            intl.formatMessage(booking_box.form_callback_article_add_failed)
                        }
                    </h3>
                    <div className="form-result-buttons">
                        {succeeded &&
                        <>
                        <button className="btn-red inverted" onClick={() => setSucceeded(null)}>
                            {intl.formatMessage(buttonsTranslations.close)}
                        </button>
                        <LocaleLink to={"checkout/panier"} className="btn-red">
                            {intl.formatMessage(booking_box.form_callback_article_goToCart)}
                        </LocaleLink>
                        </>
                        }
                        {!succeeded &&
                        <>
                        <LocaleLink to={"contact"} className="btn-red">
                            {intl.formatMessage(agencyTranslations.contact)}
                        </LocaleLink>
                        </>
                        }

                    </div>
                </PopUp>
            );
        }
    },[succeeded]);

    //SMARTPHONE
    //________________________________________________________

    const handleSmartphoneShowPrice = () => { //On Check availaibilities button, close bookingBox and show price
        setSmartphoneModuleVisible(false);
        setShowPrice(true);
    };

    const handleChangeDateSmartphone = () => { //On Check availaibilities button, close bookingBox and show price
        setSmartphoneModuleVisible(true);
        handleChangeDate();
    };

    const smartphoneGuestsBox = useRef<null | HTMLDivElement>(null);

    useEffect(() => { //Scroll to Guest inputs when period selected
        if(pickerFocus === null && !!smartphoneGuestsBox.current) smartphoneGuestsBox.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    },[pickerFocus])

    //RENDERING
    //________________________________________________________

    if(useDisplayUntilDesktop()){ //Smartphone
        return(
            <>
                {renderPopUpAddToCart}
                <div className={classNames("bookingBox smartphone", {hide: !smartphoneModuleVisible})}>
                    {optionsLoading || priceLoading  && <Loader/>}
                    <div className="bookingBox-header">
                        <h3>{intl.formatMessage(globalTranslations.booking)}</h3>
                        <FontAwesomeIcon onClick={() => setSmartphoneModuleVisible(false)} icon={faXmark}/>
                    </div>
                    <div className="bookingBox-inputs">
                        <div className="bookingBox-calendar">
                            <div className="bookingBox-title">
                                <h3>{title()}</h3>
                                <h4>{intl.formatMessage(bookingTranslations.choose_date)}</h4>
                            </div>
                            <RangePickerController
                                firstDayOfWeek={1}
                                numberOfMonths={1}
                                labelize={calendarLabelize}
                                minDate={bookingConfig?.period.start}
                                maxDate={bookingConfig?.period.end}
                                selectedRange={pickerRange}
                                onSelectedRangeChange={setPickerRange}
                                pickFocus={pickerFocus}
                                onPickFocusChange={setPickerFocus}
                                isDayBlocked={isDayBlocked}
                            />
                            <span>
                                <FontAwesomeIcon icon={faInfoCircle} className="info-circle"/>
                                {intl.formatMessage(bookingTranslations.module_contact)}
                            </span>
                            <div className="buttons">
                                <button type="button" className="btn" onClick={() => deleteRange()}>
                                    {intl.formatMessage(buttonsTranslations.reset)}
                                </button>
                            </div>
                        </div>
                        <div ref={smartphoneGuestsBox} className="bookingBox-guests">
                            <h3>{intl.formatMessage(booking_box.select_persons)}</h3>
                            <div className="input-block">
                                <div>
                                    <div className="nbrGuests-container">
                                        {maxGuestsReached &&
                                        <p className="warning">
                                            <FontAwesomeIcon icon={faInfoCircle} className="info-circle"/>
                                            {intl.formatMessage(booking_box.maxGuests_message,{max: maxGuests})}
                                        </p>
                                        }
                                        <Guests
                                        guests={guests}
                                        disableMax={maxGuestsReached}
                                        setGuests={setGuests}
                                        />
                                        <div className="buttons">
                                            <button type="button" className="btn" onClick={(e) => resetGuests(e)}>
                                                {intl.formatMessage(buttonsTranslations.reset)}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                    <div className="bookingBox-buttons">
                        <p>
                            {isValidPeriod(pickerRange) &&
                                <>
                                    {intl.formatMessage(bookingTranslations.nightsPeriod,{nightsCount: nbrOfNights})} &nbsp;/&nbsp;
                                </>
                            }
                            {totalGuests > 0 && intl.formatMessage(bookingTranslations.nbrOfGuests,{total: totalGuests})}</p>
                        <button type="button" className="btn-red" onClick={handleSmartphoneShowPrice} disabled={!formReady}>
                            {intl.formatMessage(bookingTranslations.check_availabilities)}
                        </button>
                    </div>
                </div>
                <div className="bottom-fixed-module">
                    {mutationLoading  && <Loader/>}
                    <div className="bookingBox-total">
                        {renderBookingPrice}
                    </div>
                    {(!showPrice && !bookableOnDemand) && //No datas, must fill up form
                    <>
                        {!isValidPeriod(pickerRange) && //Missing period
                            <p>{intl.formatMessage(booking_box.choose_your_dates)}</p>
                        }
                        {(isValidPeriod(pickerRange) && totalGuests === 0) && // Period ok but guests 0
                            <p>{intl.formatMessage(booking_box.add_persons)}</p>
                        }
                        {(isValidPeriod(pickerRange) && totalGuests != 0) && // Empty for UI reason
                            <p></p>
                        }
                        <button type="button" className="btn-red" onClick={() => setSmartphoneModuleVisible(true)}>
                            {intl.formatMessage(buttonsTranslations.check_availability)}
                        </button>
                    </>
                    }
                    {(formReady && showPrice && !bookableOnDemand) && //With data, ready to be booked
                    <>
                        <button type="button" className="btn-red inverted" onClick={() => setSmartphoneModuleVisible(true)}>
                            {intl.formatMessage(buttonsTranslations.change_the_informations)}
                        </button>
                        <button type="button" className="btn-red" onClick={addToCart}>
                            {intl.formatMessage(bookingTranslations.book)}
                        </button>
                    </>
                    }
                    {bookableOnDemand&& //Need contact to be booked to these dates
                    <>
                        <p className="warning">
                            <span>
                                <FontAwesomeIcon icon={faTriangleExclamation}/>
                            </span>
                            Veuillez nous contacter pour réservez ce bien à ces dates
                        </p>
                        <button type="button" className="btn-red inverted" onClick={handleChangeDateSmartphone}>
                            {intl.formatMessage(buttonsTranslations.change_the_dates)}
                        </button>
                        <button type="button" className="btn-red" onClick={changeForm}>
                            {intl.formatMessage(buttonsTranslations.contact_us)}
                        </button>
                    </>
                        }
                </div>
            </>
        );
    }else{ //Desktop
        return(
            <>
                {renderPopUpAddToCart}
                <div className="bookingBox">
                    {optionsLoading || priceLoading || mutationLoading && <Loader/>}

                    <form>
                        <div className="bookingBox-inputDate">
                            <div className="input-block" >
                                <FontAwesomeIcon icon={faCalendarDays}/>
                                <label htmlFor="arrival">{intl.formatMessage(bookingTranslations.checkIn)}</label>
                                <input id="arrival"
                                    type="text"
                                    name="arrival"
                                    placeholder={intl.formatMessage(bookingTranslations.add_date)}
                                    readOnly={true}
                                    value={!!pickerRange?.start ? intl.formatDate(pickerRange!.start.toDate()) : ""}
                                    onClick={ () => setCalendarOpen(true)}
                                    onMouseLeave={(event) => event.currentTarget.blur()}
                                />
                            </div>
                            <div className="input-block">
                                <FontAwesomeIcon icon={faCalendarDays}/>
                                <label htmlFor="departure">{intl.formatMessage(bookingTranslations.checkOut)}</label>
                                <input id="departure"
                                    type="text"
                                    name="departure"
                                    placeholder={intl.formatMessage(bookingTranslations.add_date)}
                                    readOnly={true}
                                    value={!!pickerRange?.end ? intl.formatDate(pickerRange!.end.toDate()) : ""}
                                    onClick={ () => setCalendarOpen(true)}
                                />
                            </div>
                        </div>

                        <div className="bookingBox-calendar"
                            ref={calendar}
                            onMouseLeave={(event) => {event.currentTarget.blur(); setCalendarOpen(false)}}
                        >
                            <div className="bookingBox-title">
                                <h3>{title()}</h3>
                                <h4>{intl.formatMessage(bookingTranslations.choose_date)}</h4>
                            </div>
                            <RangePickerController
                                firstDayOfWeek={1}
                                numberOfMonths={2}
                                labelize={calendarLabelize}
                                minDate={bookingConfig?.period.start}
                                maxDate={bookingConfig?.period.end}
                                selectedRange={pickerRange}
                                onSelectedRangeChange={setPickerRange}
                                pickFocus={pickerFocus}
                                onPickFocusChange={setPickerFocus}
                                isDayBlocked={isDayBlocked}
                            />
                            <span>
                                <FontAwesomeIcon icon={faInfoCircle} className="info-circle"/>
                                {intl.formatMessage(bookingTranslations.module_contact)}
                                </span>
                            <div className="buttons">
                                <button type="button" className="btn" onClick={() => deleteRange()}>
                                    {intl.formatMessage(buttonsTranslations.reset)}
                                </button>
                                <button type="button" className="btn-red" onClick={() => handleCalendarWindow(false)}>
                                    {intl.formatMessage(isValidPeriod(pickerRange) ? buttonsTranslations.next : buttonsTranslations.close)}
                                </button>
                            </div>
                        </div>

                         <div className="bookingBox-guests" onMouseLeave={() => setGuestsWindowOpen(false)}>
                            <div className="input-block">
                                <label htmlFor="nbrGuests">{intl.formatMessage(bookingTranslations.guests)}</label>
                                <div onClick={() => setGuestsWindowOpen(!guestsWindowOpen)}
                                    onMouseLeave={(event) => event.currentTarget.blur()}
                                >
                                    <FontAwesomeIcon className="users" icon={faUsers}/>
                                    <FontAwesomeIcon className="chevron" icon={faChevronDown} style={{transform:guestsWindowOpen?"rotate(180deg)":"rotate(0deg)"}}/>
                                    {nbrGuestsTextValue}
                                    <div ref={nbrGuestsModule} className="nbrGuests-container">
                                        {maxGuestsReached &&
                                        <p className="warning">
                                            <FontAwesomeIcon icon={faInfoCircle} className="info-circle"/>
                                            {intl.formatMessage(booking_box.maxGuests_message,{max: maxGuests})}
                                        </p>
                                        }
                                        <Guests
                                        guests={guests}
                                        disableMax={maxGuestsReached}
                                        setGuests={setGuests}/>
                                        <div className="buttons">
                                            <button type="button" className="btn" onClick={(e) => resetGuests(e)}>
                                                {intl.formatMessage(buttonsTranslations.reset)}
                                            </button>
                                            <button type="button" className="btn-red" onClick={(e) => handleGuestsValidClick(e)}>
                                                {intl.formatMessage(buttonsTranslations.close)}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="bookingBox-total">
                            {renderBookingPrice}
                        </div>

                        <div className="buttons">
                        {(formReady && !showPrice) &&
                        <>
                            <p></p>
                            <button type="button" className="btn-red" onClick={() => setShowPrice(true)} disabled={!formReady}>
                                {intl.formatMessage(bookingTranslations.check_availabilities)}
                            </button>
                        </>
                        }
                        {(formReady && showPrice) &&
                        <>
                            <p></p>
                            <button type="button" className="btn-red" onClick={addToCart}>
                                {intl.formatMessage(bookingTranslations.book)}
                            </button>
                        </>
                        }
                        {bookableOnDemand&&
                        <>
                            <p className="warning">
                                <span>
                                    <FontAwesomeIcon icon={faTriangleExclamation}/>
                                </span>
                                Veuillez nous contacter pour réservez ce bien à ces dates
                            </p>
                            <button type="button" className="btn-red inverted" onClick={handleChangeDate}>
                                {intl.formatMessage(buttonsTranslations.change_the_dates)}
                            </button>
                            <button type="button" className="btn-red" onClick={changeForm}>
                                {intl.formatMessage(buttonsTranslations.contact_us)}
                            </button>
                        </>
                        }

                        </div>
                    </form>
                </div>
            </>
        );
    }
}

export default BookingBox;
