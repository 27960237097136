import React from "react";
import { RouteObject, useRoutes, } from "react-router";
import { allLocales } from "@shared/locales";
import { AccommodationType } from "@shared/models/article";
import Home from "./pages/home/home";
import SearchArticles from "./pages/search-articles/search-articles";
import Contact from "./pages/contact/contact";
import Article from "./pages/article/article";
import Checkout from "./pages/cart/cart";
import CartItems from "./pages/cart/cart-items";
import CartAccount from "./pages/cart/cart-connexion";
import CartInformation from "./pages/cart/cart-information";
import CartPayment from "./pages/cart/cart-payment";
import CartPaymentCallback from "./pages/cart/payment-callback";
import PrivacyPolicy from "./pages/privacy-policy/privacy-policy";
import LegalMention from "./pages/legal-mention/legal-mention";
import NotFound from "./pages/notFound/notFound";
import CmsTemplate from "./pages/cms-template/cms-template";
import FiscalSituation from "./pages/fiscal-situation/fiscal-situation";
import { ProtectedRoute } from "./bundles/client-only-ui";
import Profile from "./pages/me/profile";

const routes: RouteObject[] = [
  {
    path: "",
    element: <Home />,
    index: true,
  },
  {
    path: "location/vacances",
    element: <SearchArticles type={AccommodationType.RENTAL} key={AccommodationType.RENTAL} />,
  },
  {
    path: "location/vacances/:id",
    element: <Article type={AccommodationType.RENTAL} />,
  },
  {
    path: "location/saison",
    element: <SearchArticles type={AccommodationType.SEASONAL} key={AccommodationType.SEASONAL} />,
  },
  {
    path: "location/saison/:id",
    element: <Article type={AccommodationType.SEASONAL} key={AccommodationType.SEASONAL} />,
  },
  {
    path: "location/annee",
    element: <SearchArticles type={AccommodationType.ANNUAL} key={AccommodationType.ANNUAL} />,
  },
  {
    path: "location/annee/:id",
    element: <Article type={AccommodationType.ANNUAL} key={AccommodationType.ANNUAL} />,
  },
  {
    path: "vente",
    element: <SearchArticles type={AccommodationType.SALE} key={AccommodationType.SALE} />,
  },
  {
    path: "vente/:id",
    element: <Article type={AccommodationType.SALE} key={AccommodationType.SALE} />,
  },
  {
    path: "services/:slug",
    element: <CmsTemplate rubric="services" />
  },
  {
    path: "agency/:slug",
    element: <CmsTemplate rubric="agency" />
  },
  {
    path: "situation-fiscale-en-valais",
    element: <FiscalSituation />
  },
  {
    path: "contact",
    element: <Contact />,
  },
  {
    path: "mentions-legales",
    element: <LegalMention />
  },
  {
    path: "politique-de-confidentialite",
    element: <PrivacyPolicy />
  },
  {
    path: "checkout",
    element: <Checkout />,
    children: [
      {
        path: "panier",
        element: <CartItems />
      },
      {
        path: "connexion",
        element: <CartAccount />
      },
      {
        path: "information",
        element: <CartInformation />
      },
      {
        path: "paiement",
        children: [
          {
            path: "",
            index: true,
            element: <CartPayment />
          },
          {
            path: "valide",
            element: <CartPaymentCallback succeeded={true} />
          },
          {
            path: "invalide",
            element: <CartPaymentCallback succeeded={false} />
          }
        ]
      }
    ]
  },
  {
    path: "me",
    children: [
      {
        path: "profile",
        element: <ProtectedRoute><Profile/></ProtectedRoute>
      }
    ]
  },
  {
    path: "*",
    element: <NotFound />
  }
];

const LocaleRoutes = () => useRoutes(allLocales.map(l => ({
  path: l.basename,
  children: routes
} as unknown as RouteObject)));

export default LocaleRoutes;
