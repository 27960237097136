import {defineMessages} from "react-intl";

export const section_annonces = defineMessages({
    title: {id: "home-annonces-title", defaultMessage: "Nos annonces immobilières"},
    span: {id: "home-annonces-span", defaultMessage: "à la une à Crans-Montana"},
    text: {id: "home-annonces-text", defaultMessage: "Découvrez notre sélection exclusive de biens immobiliers à vendre et à louer à Crans-Montana. Montan’Agence propose des logements dans une des plus belles stations des Alpes suisses. Notre agence immobilière vous offre des occasions exceptionnelles, que ce soit pour des appartements, des chalets, des commerces ou des terrains. Ne passez plus à côté des meilleures offres immobilières à Crans-Montana ! Rejoignez la chaine WhatsApp de Montan’Agence pour recevoir en avant-première nos nouvelles offres de ventes et de locations. Cliquez ici pour rejoindre notre communauté. Plus d’informations directement sur la chaine ou contactez-nous."},
});

export const section_vendreOuLouer = defineMessages({
    title: {id: "home-vendre-title", defaultMessage: "Dois-je vendre ou louer"},
    span: {id: "home-vendre-span", defaultMessage: "mon bien à Crans-Montana ?"},
    text: {id: "home-vendre-text", defaultMessage: "Aujourd’hui, dois-je vendre ou louer mon bien à Crans-Montana ? C’est une question qui suscite de nombreuses réflexions et qui est liée non seulement au marché immobilier, mais aussi à la situation personnelle de chacun. Notre équipe est là pour vous accompagner au mieux dans tous vos projets immobiliers. Chaque personne est unique, ce qui se reflète dans ses projets de vie. Expérimentée, notre équipe sait estimer au mieux la valeur des biens sur le marché et vous conseiller en tenant compte de vos projets de vie."},
    button_1: {id: "home-vendre-button-1", defaultMessage: "Je vends mon bien"},
    button_2: {id: "home-vendre-button-2", defaultMessage: "Je loue mon bien"},
});

export const section_expertises = defineMessages({
    title: {id: "home-expertises-title", defaultMessage: "Expert immobilier"},
    span: {id: "home-expertises-span", defaultMessage: "à Crans-Montana depuis 1958"},
    text1: {id: "home-expertises-text-1", defaultMessage: "Fondée en 1958 par Claire Berclaz, l’agence immobilière Montan’Agence est devenue une référence incontournable de l’immobilier à Crans-Montana. En tant que résidents passionnés, nous partageons notre connaissance approfondie de la région avec tous nos clients. Nous voyons <b>Crans-Montana</b> comme bien plus qu’une simple destination : c’est un village où chaque client est traité comme un futur voisin."},
    text2: {id: "home-expertises-text-2", defaultMessage: "Avec plus de 60 ans d’expérience, Montan’Agence se distingue par son service client personnalisé. Nous offrons un soutien complet aux propriétaires dans toutes leurs démarches immobilières, en mettant l’accent sur l’intégrité, la fidélité et la recherche de compromis constructifs pour obtenir des résultats concrets. Notre expertise authentique et notre service personnalisé répondent à tous vos besoins immobiliers à Crans-Montana.",},
    text3: {id: "home-expertises-text-3", defaultMessage: "En tant qu’entreprise familiale développée sur trois générations, notre héritage est au cœur de notre approche professionnelle. Claire, René et Paul Berclaz ont chacun contribué à enrichir notre savoir-faire. Notre réseau solide est le fruit de leurs connaissances et relations. Cela nous permet de vous offrir des conseils avisés et un service de qualité supérieure."},
    text4: {id: "home-expertises-text-4", defaultMessage: "Notre équipe joue un rôle crucial dans le succès de Montan’Agence, en alliant une connaissance approfondie des besoins des clients à un accueil chaleureux, afin de rendre chaque moment agréable et efficace."},
});

export const section_services = defineMessages({
    title: {id: "home-services-title", defaultMessage: "Nos services immobiliers"},
    span: {id: "home-services-span", defaultMessage: "à Crans-Montana"},
    text: {id: "home-services-text", defaultMessage: "Montan’Agence propose différents services immobiliers à Crans-Montana. Ces services s’adressent à tous : acheteurs, vendeurs, propriétaires et locataires."},
    serviceTitle_1: {id: "home-services-title-1", defaultMessage: "Courtage et estimations immobilières"},
    serviceText_1: {id: "home-services-text-1", defaultMessage: "Montan’Agence évalue la valeur de votre bien immobilier. Elle améliore sa présentation pour la vente. De plus, notre agence immobilière assure une grande visibilité sur plusieurs plateformes de vente. Nous aidons chacun à atteindre ses objectifs et offrons un soutien dans toutes les démarches administratives jusqu’à la prise de possession."},
    serviceTitle_2: {id: "home-services-title-2", defaultMessage: "Location d’appartements de vacances"},
    serviceText_2: {id: "home-services-text-2", defaultMessage: "Nous estimons la valeur de votre propriété à Crans et Montana. Nous la préparons pour la location, gérons les réservations et accueillons les locataires. Nous leur offrons une assistance pendant leur séjour. Montan’Agence inclut également la gestion des services de maintenance entre les locations. Un bon compromis entre l’usage personnel de votre bien et l’amortissement des charges annuelles."},
    serviceTitle_3: {id: "home-services-title-3", defaultMessage: "Location d’appartements annuelle"},
    serviceText_3: {id: "home-services-text-3", defaultMessage: "Montan’Agence se charge de la gestion locative annuelle de votre bien immobilier à Crans-Montana. Nous évaluons les loyers, gérons les contrats, facilitons la communication entre propriétaires et locataires. Cela permet d’assurer un revenu locatif stable et sans problèmes. Nous nous occupons de tout pour les locations. Nous récupérons les loyers et envoyons des rappels si besoin. Nous protégeons les intérêts des propriétaires et des locataires. Nous vous permettons de toucher les rendements de votre bien sans effort."},
    serviceTitle_4: {id: "home-services-title-4", defaultMessage: "Promotion Immobilière"},
    serviceText_4: {id: "home-services-text-4", defaultMessage: "Montan’Agence promeut des projets immobiliers neufs à Crans-Montana à travers Berkal SA. Nous construisons des résidences qui répondent aux besoins du marché local, tout en garantissant des standards de qualité élevés. Nous avons construit des bâtiments emblématiques de la station de Crans-Montana et continuons à façonner notre station valaisanne."},
    serviceTitle_5: {id: "home-services-title-5", defaultMessage: "Location d’appartements saisonnière"},
    serviceText_5: {id: "home-services-text-5", defaultMessage: "Optimisez la rentabilité de votre bien immobilier avec nos services de location saisonnière à Crans-Montana. Montan’Agence s’occupe de la location, en évaluant la valeur, en accueillant les locataires et en gérant les réservations et l’entretien."},
    serviceTitle_6: {id: "home-services-title-6", defaultMessage: "Domiciliation"},
    serviceText_6: {id: "home-services-text-6", defaultMessage: "Simplifiez la gestion de votre bien immobilier avec nos services de domiciliation. Montan’Agence s’occupe de votre courrier, de vos factures et vous donne un accès en ligne à votre compte dédié. Notre agence immobilière vous représente en tant qu’administrateur de sociétés immobilières pour une gestion efficace."},
    serviceTitle_7: {id: "home-services-title-7", defaultMessage: "Gestion de copropriété/PPE"},
    serviceText_7: {id: "home-services-text-7", defaultMessage: "Montan’Agence gère les copropriétés à Crans-Montana, garantissant la protection de la valeur de votre immeuble sur le long terme. Nous gérons techniquement et administrativement des bâtiments et collaborons avec de nombreuses entreprises locales pour satisfaire tous les besoins. Certains clients nous suivent depuis plus de 50 ans, gage de la qualité de nos services."},
});

export const section_valeurs = defineMessages({
    title: {id: "home-valeurs-title", defaultMessage: "Nos <s>valeurs</s>"},
    text1: {id: "home-valeurs-text-1", defaultMessage: "À Montan’Agence, nous nous engageons à offrir une expérience immobilière exceptionnelle en plaçant l’humain au cœur de notre démarche. Nous valorisons l’écoute attentive des besoins de chaque client et nous nous efforçons de fournir des solutions personnalisées."},
    text2: {id: "home-valeurs-text-2", defaultMessage: "Notre philosophie repose sur l’idée que chaque projet immobilier est unique et mérite une approche sur mesure. Nous croyons fermement en la collaboration et en la création de partenariats durables basés sur la confiance mutuelle."},
    text3: {id: "home-valeurs-text-3", defaultMessage: "L’innovation est également au cœur de notre stratégie. Nous cherchons constamment à améliorer nos services et à adopter les meilleures pratiques du secteur."},
});

export const section_newsletter = defineMessages({
    title: {id: "home-newsletter-title", defaultMessage: "Inscrivez-vous"},
    title_span: {id: "home-newsletter-title_span", defaultMessage: "à notre newsletter"},
    text: {id: "home-newsletter-text", defaultMessage: "Découvrez nos biens exclusifs en vous inscrivant à notre newsletter et soyez les premiers informés des nouvelles opportunités sur le marché !"},
});

export const seo = defineMessages({
    title: {id: "home-seo-title", defaultMessage: "Montan’Agence | Agence Immobilière Crans-Montana - Annonces & Services Immobiliers"},
    description: {id: "home-seo-description", defaultMessage: "Montan’Agence propose des annonces immobilières exclusives à Crans-Montana. Experts en immobilier en Valais, notre agence immobilière offre des services de courtage, location, gestion de copropriété, et promotion."},
});
