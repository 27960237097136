import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useLocaleContext } from "@ct-react/locale";
import { CmsContext } from "../../contexts/cms";
import {
  cmsField,
  CmsGroupField,
  CmsHtmlField,
  CmsImageField,
  CmsTextField,
  cmsTranslatableValue,
  isCmsGroupField,
  Page
} from "../../models/cms-page";
import NotFound from "../notFound/notFound";
import SeoHelmet from "../../components/seo-helmet/seo-helmet";
import TwoColumn from "../../components/sections/twoColumn";
import "./cms-template.scss";

type LayoutGroupItem = { columns: number, revert: boolean, content: CmsHtmlField, image?: CmsImageField };

const CmsTemplate = ({ rubric }: { rubric: "services" | "agency" }) => {

  const { locale: { basename: lang } } = useLocaleContext();
  const { pages } = useContext(CmsContext);
  const [ searchParams ] = useSearchParams();

  const [ loading, setLoading ] = useState(true);
  const [ failure, setFailure ] = useState(false);
  const [ page, setPage ] = useState<Page | undefined>(undefined);

  useEffect(() => {
    if (pages.length === 0) return;
    !searchParams.has("pageId") && setFailure(true);
    const search = pages.find(p => p.id === searchParams.get("pageId") && p.associationRef === rubric);
    !search && setFailure(true);
    setPage(search);
    setLoading(false);
  }, [ searchParams, pages ]);

  const seo = useMemo(() => cmsField<CmsGroupField>(page?.data || [], "seo-data"), [ page, lang ]);
  const header = useMemo(() => cmsField<CmsHtmlField>(page?.data || [], "head"), [ page, lang ]);
  const content = useMemo(() => cmsField<CmsGroupField>(page?.data || [], "layout-two-col"), [ page, lang ]);
  const footer = useMemo(() => cmsField<CmsHtmlField>(page?.data || [], "foot"), [ page, lang ]);

  if (failure)
    return <NotFound />;

  if (loading || !page)
    return (<section><Skeleton height="65vh" /></section>);

  return (
    <>
      {isCmsGroupField(seo) &&
        <SeoHelmet title={cmsTranslatableValue(cmsField<CmsTextField>(seo.fields[0], "seo-title")?.text, lang)}
                   description={cmsTranslatableValue(cmsField<CmsTextField>(seo.fields[0], "seo-description")?.text, lang)}
                   keywords={cmsTranslatableValue(cmsField<CmsTextField>(seo.fields[0], "seo-keywords")?.text, lang)} />
      }
      {!!header &&
        <hgroup className="page-title"
                dangerouslySetInnerHTML={{ __html: cmsTranslatableValue(header.html, lang) }} />
      }
      {!!content && content.fields
        .reduce<LayoutGroupItem[]>((acc, groupItem) => {
          const content = cmsField<CmsHtmlField>(groupItem, "two-col-content-item")!;
          const image = cmsField<CmsImageField>(groupItem, "two-col-image-item");
          const previousRevertState = [ ...acc.filter(sa => sa.columns > 1) ].reverse();
          const revert = !!image && previousRevertState.length !== 0 && !previousRevertState[0].revert;
          return [ ...acc, { columns: !!image ? 2 : 1, revert, content, image } ];
        }, [])
        .map(({ columns, revert, content, image }, i) => {
          const __html = cmsTranslatableValue(content.html, lang);
          return (columns === 1 || !image)
            ? <section key={i} className="one-column" dangerouslySetInnerHTML={{ __html }} />
            : <TwoColumn key={i}
                         revert={revert}
                         image={image}
                         alt={cmsTranslatableValue(image.alternativeText, lang)}><div dangerouslySetInnerHTML={{ __html }} /></TwoColumn>
          })
      }
      {!!footer &&
        <section className="page-footer cta"
             dangerouslySetInnerHTML={{ __html: cmsTranslatableValue(footer.html, lang) }} />
      }
    </>);
};

export default CmsTemplate;
