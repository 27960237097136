import { AccommodationType, ArticleType } from "./article";

export const buildArticleUrl = (slug: string, id: string, type: ArticleType | AccommodationType): string => {
  switch(type) {
    case ArticleType.RENTAL:
    case AccommodationType.RENTAL:
      return `/location/vacances/${slug}?refId=${id}`;
    case ArticleType.SEASONAL:
    case AccommodationType.SEASONAL:
      return `/location/saison/${slug}?refId=${id}`;
    case ArticleType.ANNUAL:
    case AccommodationType.ANNUAL:
      return `/location/annee/${slug}?refId=${id}`;
    case ArticleType.SALE:
    case AccommodationType.SALE:
      return `/vente/${slug}?refId=${id}`;
  }
}
